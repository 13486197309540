<template>
  <v-badge avatar bordered overlap bottom
           :value="participants.length > 1"
           :content="participants.length >= 2 ? '+'+participants.length : '' "
  >
    <template v-slot:badge>
      <user-avatar v-if="participants.length > 1" :user="subAvatarUser" />
    </template>
    <user-avatar v-if="mainAvatarUser" :size="size" :user="mainAvatarUser" />
  </v-badge>
</template>

<script>
  import UserAvatar from '@/modules/core/layout/UserAvatar'
  import {Person} from '@/models'

  export default {
    name: 'MessengerConversationAvatar',
    components: {UserAvatar},
    props: {
      participants: {
        type: Array
      },
      size: {
        type: Number,
        default: 40
      }
    },
    computed: {
      mainAvatarUser() {
        return this.participants && this.participants[0] ? Person.get(this.participants[0]) : null
      },
      subAvatarUser() {
        return this.participants.length > 1 ? Person.get(this.participants[1]) : null
      }
    }
  }
</script>

<style scoped>

</style>
