import {Model, Person, Establishment, Team, Patient, Professional, Space} from '@/models'

export class FeedListOwner extends Model {
  static types() {
    return {
      user: Person,
      'user:patient': Patient,
      'user:professional': Professional,
      establishment: Establishment,
      space: Space,
      team: Team
    }
  }
}
