/**
 * https://medium.com/@therealmaarten/using-normalizr-great-dont-use-denormalize-though-a80857710b54
 * Remember that you don’t have to normalise every sub-entity in your entities! Only the ones who are reused by other entities.
 * denormalization breaks the memoization of your selectors, causing all of your objects to re-render
 * The solution then is to simply not use denormalize, rewriting your components to pass id’s to child components that each connect to the store to fetch their entity
 */

import {removeItem} from '@/utils/array'
import {empty} from '@/utils/variables'

export class Model {
  // Will be ignored if the model is a child of another and will be determined by discriminator
  static schemaName
  static primaryKey = 'uid'
  static type

  static eventTypes = ['update', 'delete']

  /**
   * https://github.com/paularmstrong/normalizr/blob/master/docs/api.md#schema
   */
  static schemaDefinition() {
    return {}
  }

  /**
   * Must return an object with all child models to support polymorphism.
   * Property 'type" will be used as discriminator
   * Format is
   * { typeValue: childModel, ... }
   *
   * @returns {{}}
   */
  static types() {
    return {}
  }

  static _store
  static _namespace
  static _schema
  static _eventListeners = []

  static on(eventType, listener) {
    this._eventListeners.push([this, eventType, listener])
  }

  static emit(eventType, key, payload) {
    for (let listener of this._eventListeners) {
      if ((listener[0] === this || Object.prototype.isPrototypeOf.call(listener[0], this)) && listener[1] === eventType) {
        listener[2](key, payload)
      }
    }
  }

  static off(eventType, offListener = undefined) {
    this._eventListeners = this._eventListeners.filter(listener => {
      if ((listener[0] === this || Object.prototype.isPrototypeOf.call(listener[0], this)) && listener[1] === eventType) {
        if (listener[1] === eventType && (typeof offListener === 'undefined' || listener[2] === offListener)) {
          removeItem(this._eventListeners, listener)
        }
      }
    })
  }

  /**
   *
   * @param {eventTypes} eventType
   * @param eventListener
   */
  $on(eventType, eventListener) {
    let listenerWrapper = (key, payload) => {
      if (key === this[this.constructor.primaryKey]) {
        eventListener(payload)
      }
    }
    this.constructor.on(eventType, listenerWrapper)
    return listenerWrapper
  }

  $emit(eventType, payload) {
    this.constructor.emit(eventType, this[this.constructor.primaryKey], payload)
  }

  static get(key) {
    if(empty(key)) {
      return null
    }
    let types = this.types()

    // Support polymorphic by using types() as discriminator
    if (typeof key === 'object' && typeof key['schema'] !== 'undefined') {
      if (!Object.keys(types).length) {
        throw new Error('Cannot get model instance ' + key['id'] + ' of type ' + key['schema'] + ': No types defined in model ' + this.name)
      }

      if(typeof types[key['schema']] === 'undefined') {
        throw new Error('Cannot get model instance ' + key['id'] + ' of type ' + key['schema'] + ': Type is not defined in model ' + this.name)
      }

      return types[key['schema']].get(key['id'])
    }

    // Permet de récupérer l'instance enfant du model courant si celui ci n'est pas le model persisté (schemaName défini)
    if (typeof this.schemaName === 'undefined' && Object.keys(types).length) {
      for (let type in types) {
        let value = types[type].get(key)
        if (value) {
          return value
        }
      }
      return undefined
    }

    return this.state()[this.schemaName][key]
  }

  // Trigger an update event
  static save(data) {
    if (!Array.isArray(data)) {
      data = [data]
    }

    // for (let item of data) {
    //   this.emit('update', item[this.primaryKey], {old: this.get(item[this.primaryKey]), new: data})
    // }

    this.commit('update', {
      data,
      model: this,
      schema: [this._schema]
    })

    for (let value of data) {
      if (typeof value[this.primaryKey] !== 'undefined') {
        this.emit('update', value[this.primaryKey], data)
      }
    }
  }

  static mutate(callback) {
    this.commit('apply', {
      schemaName: this.schemaName,
      callback
    })
  }

  // Trigger a delete event
  static delete(key) {
    this.emit('delete', key)
    this.commit('delete', {
      schemaName: this.schemaName,
      key
    })

  }

  static state() {
    return this._store.state[this._namespace]
  }

  static commit(mutation, payload) {
    return this._store.commit(this._namespace + '/' + mutation, payload)
  }

  $store() {
    return this.constructor._store
  }

  $mutate(callback) {
    this.constructor.commit('apply', {
      schemaName: this.constructor.schemaName,
      callback,
      key: this[this.constructor.primaryKey]
    })
  }

  $delete() {
    this.constructor.delete(this[this.constructor.primaryKey])
  }
}
