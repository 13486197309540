<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-badge :content="totalUnreadMessages" :value="totalUnreadMessages" color="white grey--text" top offset-y="20px"
               offset-x="20px"
      >
        <v-btn icon depressed dark small class="mx-1 pa-5" v-on="on"
               @click="$store.commit('messenger/layout/toggleSideBar')"
        >
          <font-awesome-icon :icon="['fal', 'comment-dots']" size="2x" />
        </v-btn>
      </v-badge>
    </template>
    <span>Ma messagerie</span>
  </v-tooltip>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'MessengerToggle',
    data() {
      return {
        total: null
      }
    },
    computed: {
      ...mapState('messenger', ['totalUnreadMessages'])
    },
    created() {
      this.$store.dispatch('messenger/fetchTotalUnreadMessages')
    }
  }
</script>
