import { getInstance as getNodeInstance } from '@/plugins/node'
import { getInstance as getAuthInstance } from '@/plugins/auth'
import { Conversation, Message } from '@/modules/messenger/models'
import store from '@/store/index'

export function sendMessage({conversation, message}) {
  getNodeInstance().socket.emit('messenger:send_message', {conversation, message})
}

export function emitMessagesRead(messages) {
  getNodeInstance().socket.emit('messenger:read_messages', messages)
}

export function init(socket) {
  socket.on('message', message => {
    let userUid = getAuthInstance().user ? getAuthInstance().user.sub : null
    if(userUid !== message.author.uid) {
      Conversation.get(message.conv_uid).$mutate(conversation => {
        Message.save(message)
        if(message.message !== null && message.message !== '') {
          conversation.last_message = message.id
        }

        if(!store.state.messenger.layout.currentlyDisplayed.includes(message.conv_uid)) {
          store.commit('messenger/setTotalUnreadMessages', store.state.messenger.totalUnreadMessages + 1)
          ++conversation.total_unread_messages
        }
      })
    }
  })
}
