export default [
  {
    path: '/establishment',
    name: 'establishment',
    component: () => import('@/modules/establishments/views/Establishment.vue')
  }
  // {
  //   path: '/establishment/invite',
  //   name: 'establishment_invite',
  //   component: () => import('@/modules/establishments/views/EstablishmentInvite.vue')
  // }
]
