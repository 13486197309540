<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-card tile flat class="fill-height">
    <v-card class="primary elevation-x" height="180px">
      <div class="d-flex pa-3 mb-5">
        <v-img src="@/assets/img/applogo/mspace-logo-light.png" max-width="200px" contain />
      </div>

      <div class="text-h5 white--text text-center font-alt">Mes espaces de travail</div>
      <div class="text-subtitle-2 font-weight-light white--text text-center font-alt">
        Choisir mon espace de travail
      </div>
    </v-card>

    <transition mode="out-in" name="fade">
      <v-row v-if="loading" key="loader" class="mx-0 mt-11">
        <v-col class="px-3 text-center text-h4 font-weight-light font-alt op-20">
          Chargement de vos espaces en cours
        </v-col>
      </v-row>
      <v-row v-else key="selector" class="white ma-0">
        <v-col v-if="$auth.granted('role_professionals')" cols="12" md="5" lg="4" xl="3">
          <div class="py-5 elevation-x c-pointer space grey lighten-5 fill-height d-flex flex-column align-center"
               @click.stop="createSpace"
          >
            <font-awesome-icon :icon="['fad', 'plus-circle']" size="2x" class="primary--text" />
            <div class="text-center title font-alt primary--text">Créer un nouvel espace</div>
          </div>
        </v-col>
        <v-col v-for="space in spaces" :key="space" cols="12" md="5" lg="4" xl="3" class="px-3">
          <space-selector-dialog-item :uid="space" @decline-invitation="removeItem(space)" />
        </v-col>
      </v-row>
    </transition>
  </v-card>
</template>

<script>
  import {mapMutations, mapState} from 'vuex'
  import SpaceSelectorDialogItem from '@/modules/spaces/components/SpaceSelectorDialogItem'

  export default {
    name: 'SpaceSelectorMobile',
    components: {SpaceSelectorDialogItem},
    data() {
      return {
        loading: true
      }
    },
    computed: {
      ...mapState('layout', ['spaceSelector']),
      spaces() {
        return this.$store.state.spaces.items
      }
    },
    created() {
      this.$store.dispatch('spaces/fetchItems').finally(() => {
        this.loading = false
      })
    },
    methods: {
      ...mapMutations('spaces', ['removeItem']),
      createSpace() {
        this.$store.commit('layout/toggleSpaceSelector')
        this.$router.push({name:'space_create'})
      }
    }
  }
</script>
