/*
 * Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

export default {
    namespaced: true,

    state: {
        component: null,
        props: null,
        isVisible: false
    },

    getters: {},

    mutations: {
        open(state, {component = null, props = null} = {}) {
            state.isVisible = true
            state.component = component
            state.props = props
        },
        close(state) {
            state.isVisible = false
            state.component = null
            state.props = null
        }
    },

    actions: {}
}
