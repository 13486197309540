<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <page-loader v-if="loading" class="loader-page-modal" />
  <v-container v-else>
    <form-category title="Thèmes prédéfinis" subtitle="Thèmes prédéfinis de la plateforme" />
    <v-row>
      <template v-for="(theme, index) in themes">
        <v-col :key="index" cols="12" md="6" lg="2">
          <v-card class="elevation-x">
            <v-row dense>
              <v-col cols="6">
                <v-row no-gutters>
                  <template v-for="(lightColor, colorLight) in theme.data.light">
                    <v-col :key="colorLight" cols="4" :style="'background-color: '+lightColor+'; height:40px;'" />
                  </template>
                </v-row>
              </v-col>

              <v-col cols="6">
                <v-row no-gutters>
                  <template v-for="(darkColor, colorDark) in theme.data.dark">
                    <v-col :key="colorDark" cols="4" :style="'background-color: '+darkColor+'; height:40px;'" />
                  </template>
                </v-row>
              </v-col>
            </v-row>
            <v-card-title class="py-0">
              {{ theme.name }}
            </v-card-title>
            <v-card-actions>
              <v-spacer />
              <v-btn text x-small color="primary" @click="applyTheme(theme)">
                Selectionner ce thème
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </template>
    </v-row>
    <form-category title="Thèmes personnalisés" subtitle="Thèmes personnalisés de la plateforme" class="mt-10" />
    <v-row>
      <template v-for="(theme, index) in mythemes">
        <v-col :key="index" cols="12" md="6" lg="2">
          <v-card class="elevation-x">
            <v-row dense>
              <v-col cols="6">
                <v-row no-gutters>
                  <template v-for="(lightColor, colorLight) in theme.data.light">
                    <v-col :key="colorLight" cols="4" :style="'background-color: '+lightColor+'; height:40px;margin-top: 1px;'" />
                  </template>
                </v-row>
              </v-col>

              <v-col cols="6">
                <v-row no-gutters>
                  <template v-for="(darkColor, colorDark) in theme.data.dark">
                    <v-col :key="colorDark" cols="4" :style="'background-color: '+darkColor+'; height:40px;margin-top: 1px;'" />
                  </template>
                </v-row>
              </v-col>
            </v-row>
            <v-card-title class="py-0">
              {{ theme.name }}
            </v-card-title>
            <v-card-actions>
              <v-spacer />
              <v-btn text x-small color="error" :loading="loadingSubmit" @click="deleteTheme(theme)">
                Supprimer
              </v-btn>
              <v-btn text x-small color="primary" class="font-main" @click="applyTheme(theme)">
                Selectionner ce thème
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </template>
    </v-row>
    <theme-form :space-uid="space.uid" @add-theme="addTheme" />
  </v-container>
</template>

<script>
  import PageLoader from '@/modules/core/layout/PageLoader'
  import FormCategory from '@/modules/core/layout/FormCategory'
  import ThemeForm from './ThemeForm'
  import * as Theme from '@/modules/spaces/api/themes'
  import {Space} from '@/models'
  import {removeItem} from '@/utils/array'


  export default {
    name: 'ThemeSettings',
    components: {FormCategory, ThemeForm, PageLoader},
    data() {
      return {
        themes: [],
        mythemes: [],
        loadingSubmit: false,
        loading: true
      }
    },
    computed: {
      space() {
        return Space.get(this.$store.state.core.space)
      }
    },
    async created() {
      await this.loadThemes()
      await this.loadMyThemes()
      this.loading = false
    },
    methods: {
      addTheme(data) {
        if(data) {
          this.mythemes.push(data)
        }
      },
      async loadThemes() {
        this.themes = (await Theme.list()).data.filter(theme => theme.name === 'Standard')
      },
      async loadMyThemes() {
        this.mythemes = (await Theme.mylist(this.space.uid)).data
      },
      goBack() {
        this.$emit('goBack')
      },
      async applyTheme(theme) {
        let newTheme = theme
        this.$vuetify.theme.themes.light = {...this.$vuetify.theme.themes.light, ...newTheme.data.light}
        this.$vuetify.theme.themes.dark = {...this.$vuetify.theme.themes.dark, ...newTheme.data.dark}
        await Theme.active(this.space.uid, theme.uid)
      },
      async deleteTheme(theme){
        this.loadingSubmit = true
        try {
          await Theme.remove(theme.uid)
          removeItem(this.mythemes, theme)
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'success',
            title: 'Thème supprimé',
            subtitle: 'Thème supprimé avec succès.'
          })

        } catch {
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue',
            subtitle: 'Une erreur est survenue veuillez réessayer.'
          })
        } finally {
          this.loadingSubmit = false
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  .loader-page-modal {
    height: calc(100vh - 64px);
  }
</style>
