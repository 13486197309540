/*
 * <!--Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential-->
 */

import {empty} from '@/utils/variables'

export const mimeTypes = [
  'image/*, audio/*, video/*',
  'application/pdf', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-powerpoint',
  'application/mp4', 'application/vnd.openofficeorg.extension',
  'application/vnd.oasis.opendocument.spreadsheet-template', 'application/vnd.oasis.opendocument.text', 'text/plain',
  'text/html', 'text/plain', 'application/postscript'
]

export function getSize(size, decimals) {
  if (parseInt(size) === 0 || empty(size)) {
    return '0 Octets'
  }

  let k = 1000,
    dm = decimals || 0,
    sizes = ['Octets', 'Ko', 'Mo', 'Go', 'To'],
    i = Math.floor(Math.log(size) / Math.log(k))

  return parseFloat((size / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export function isImg(mimeType) {
  return mimeType.includes('image/')
}

export function isTxt(mimeType) {
  return mimeType.includes('text/')
}

export function isVideo(mimeType) {
  return mimeType.includes('video/') || 'application/mp4' === mimeType
}
