/*
 * Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import {Person} from '@/models'
import * as contacts from '@/modules/network/api'
import pushContactRequest from '@/modules/network/websockets'
import {column, merge, removeItem} from '@/utils/array'

const LIMIT = 10

// Utiliser uniquement pour la page des contacts.
// Ne pas utiliser ce store pour un composant de recherche par exemple
export default {
  namespaced: true,
  state: {
      total: null,
      page: 0,
      items: [],
    requests: {
      loaded: false,
      pending: [],
      sent: []
    }
  },
  mutations: {
    pushItems(state, {items, total, page}) {
      state.items = merge(state.items, items) // Deduplique les items concaténés
      state.total = total
      state.page = page
    },

    removeItem(state, item) {
      removeItem(state.items, item)
      --state.total
    },

    setPendingRequests(state, requests) {
      state.requests.pending = requests
    },

    setSentRequests(state, requests) {
      state.requests.sent = requests
    },

    setRequestsLoaded(state, value) {
      state.requests.loaded = value
    },

    removeRequest(state, {type, contact}) {
      if (!state.requests.loaded) return

      // eslint-disable-next-line no-prototype-builtins
      if (!state.requests.hasOwnProperty(type)) {
        throw new Error('"Type" parameter must be either "pending" or "sent".')
      }

      let index = state.requests[type].findIndex(val => contact === val.uid)

      if (-1 === index) {
        throw new Error('Request not found.')
      }

      state.requests[type].splice(index, 1)
    },

    addRequest(state, {type, contact}) {
      if (!state.requests.loaded) return

      // eslint-disable-next-line no-prototype-builtins
      if (!state.requests.hasOwnProperty(type)) {
        throw new Error('"Type" parameter must be either "pending" or "sent".')
      }

      state.requests[type].unshift(contact)
    }
  },

  actions: {
    async fetchPage({commit}, page) {
      let {list, total} = (await contacts.search(page, LIMIT)).data
      Person.save(list)
      commit('pushItems', {items: column(list, 'uid'), total, page})
      return list.length < LIMIT
    },

    async removeContact({commit}, uid) {
      await contacts.remove(uid)
        .then(() => {
          commit('removeItem', uid)
        })
    },

    async fetchPendingRequests({commit}) {
      let [err, data] = await contacts.getPendingRequests()
        .then(response => {
          let data = response.data.list
          Person.save(data)
          commit('setPendingRequests', column(data, 'uid'))
          return [null, response.data.list]
        })
        .catch(error => {
          return [error, null]
        })

      return [err, data]
    },

    async fetchSentRequests({commit}) {
      let [err, data] = await contacts.getSendingRequests()
        .then(response => {
          let data = response.data.list
          Person.save(data)
          commit('setSentRequests', column(data, 'uid'))
          return [null, response.data.list]
        })
        .catch(error => {
          return [error, null]
        })

      return [err, data]
    },

    async handleRequest({commit}, {contact, accept}) {
      let [err, data] = await contacts.handleRequest(contact, accept)
        .then(response => {
          commit('removeRequest', {type: 'pending', contact: contact})

          let type = accept ? 'accepted' : 'declined'

          pushContactRequest({type, target: contact})
          if (accept) {
            commit('addItem', contact)
          }

          return [null, response.data]
        }).catch(error => {
          return [error, null]
        })

      return [err, data]
    },

    async cancelRequest({commit}, contact) {
      let [err, data] = await contacts.remove(contact)
        .then(response => {
          commit('removeRequest', {type: 'sent', contact})
          pushContactRequest({type: 'canceled', target: contact})
          return [null, response.data]
        }).catch(error => {
          return [error, null]
        })

      return [err, data]
    },

    async sendRequest({commit}, contact) {
      let [err, data] = await contacts.sendRequest(contact.uid)
        .then(response => {
          commit('addRequest', {type: 'sent', contact})
          pushContactRequest({type: 'emitted', target: contact.uid})
          return [null, response.data]
        }).catch(error => {
          return [error, null]
        })

      return [err, data]
    }
  }
}
