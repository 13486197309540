/*
 * <!--Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential-->
 */

import lists from '@/modules/user/store/lists'

export default {
  namespaced: true,
  modules: {
    lists
  }
}
