<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-col v-if="files && files.length" class="uploading-files-wrapper">
    <v-chip
      v-for="file in files" :key="file.clientData.id"
      label close
      class="mr-3"
      @click:close="$store.commit('files/upload/remove', file.clientData.id)"
    >
      {{ file.file.name }}
    </v-chip>
  </v-col>
</template>

<script>
  export default {
    name: 'MessagesUploadingFiles',
    props: {
      conversation: {
        type: Object,
        required: true
      }
    },
    computed:{
      files(){
        if(!this.conversation.file_list){
          return null
        }
        return this.$store.getters['files/upload/cget'](this.conversation.file_list)
      }
    }
  }
</script>

<style lang="scss">
  .uploading-files-wrapper{
    background-color: rgb(240, 242, 245);
    border-color: rgb(240, 242, 245);
    display: flex;
    padding-top: 8px!important;
    position: absolute;
    top: -47px;
    width: 100%;
    z-index: 2;
    left: 0;
    padding-bottom: 7px;
  }
</style>
