<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <div ref="container" class="notifications-wrapper" :class="fullPage ? 'full-page' : ''">
    <empty-list v-if="!loadingOld && items.length === 0" class="empty-notification"
                title="Aucun résultat"
                subtitle="Vous n'avez aucune notification dans votre liste" :class="!fullPage ? 'empty-height-auto pa-7' : ''"
    />

    <v-list :dense="!fullPage" class="pa-0 is-scroll-target notifications-listing">
      <div v-if="loadingNew" class="text-center mt-5 mb-5">
        <skeleton-list small />
      </div>

      <q-infinite-scroll v-if="active" ref="infiniteScroll"
                         :disable="error || endReached || loadingOld"
                         scroll-target=".is-scroll-target"
                         @load="load"
      >
        <notifications-list-item v-for="item in items" :key="item.id"
                                 :notification="item"
        />
      </q-infinite-scroll>

      <div v-if="loadingOld" class="text-center mt-3">
        <skeleton-list small />
      </div>

      <div v-if="error" class="text-center mt-3">
        <v-btn color="white" outlined small @click="error = false">Réessayer</v-btn>
      </div>
    </v-list>
  </div>
</template>

<script>
  import {QInfiniteScroll} from 'quasar'
  import NotificationsListItem from '@/modules/notifications/components/NotificationsListItem'
  import EmptyList from '@/modules/core/layout/EmptyList'
  import {mapActions, mapState, mapMutations} from 'vuex'
  import * as Notifications from '@/api/notifications'
  import SkeletonList from '../../core/layout/SkeletonList'

  export default {
    name: 'NotificationsList',
    components: {SkeletonList, NotificationsListItem, QInfiniteScroll, EmptyList},
    props: {
      active: Boolean,
      fullPage: Boolean
    },
    data() {
      return {
        error: false,
        loadingNew: false,
        loadingOld: false,
        endReached: false
      }
    },
    computed: {
      ...mapState('notifications', ['items', 'limit']),
      ...mapState('layout', ['isMobile'])
    },
    watch: {
      async active(value) {
        if (value) {
          // Si la liste contient déjà des notifs
          if (this.items.length === 0 || this.loadingNew) {
            return
          }

          // Charger les notifs qui auraient pu arriver pendant que le panneau était fermé,
          // on évite d'append lors de l'event envoyé par le serveur node pour surcharger le dom pour rien
          // si la page reste ouverte en arriere plan pendant plusieurs heures, la mémoire va être saturé

          try {
            this.loadingNew = true

            let newItems
            let previousItemsLength

            do {
              previousItemsLength = this.items.length
              newItems = (await Notifications.cget(1, this.limit)).data

              this.prependItems(newItems)
            } while (newItems.length + previousItemsLength === this.items.length) // on continue tant qu'on a pas de duplicat

          } finally {
            this.loadingNew = false
          }

        } else {
          this.setItemsRead()
        }
      }
    },
    methods: {
      ...mapMutations('notifications', ['prependItems', 'setItemsRead', 'setUnreadTotal']),
      ...mapActions('notifications', ['loadNextPage']),
      async load(index, done) {
        this.loadingOld = true
        try {
          this.endReached = await this.loadNextPage()
          done(this.endReached)
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e)
          this.error = true
          done(false)
        }
        this.loadingOld = false
      }
    }
  }
</script>

<style lang="scss" scoped>
    .notifications-listing {
      max-height: 300px;
      overflow: auto;
    }
</style>
