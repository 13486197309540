import { getInstance as getNodeInstance } from '@/plugins/node'
import store from '@/store/index'

const _handleEmittedRequest = async from => {
  //TODO get contact return any pro, contact or not
  store.commit('contacts/addRequest', {type: 'pending', from})
}

const _handleAcceptedRequest = async from => {
  store.commit('contacts/addItem', from)
  store.commit('contacts/removeRequest', {type: 'sent', contact: from})
}

const _handleDeclinedRequest = from => {
  store.commit('contacts/removeRequest', {type: 'sent', contact: from})
}

const _handleCanceledRequest = from => {
  store.commit('contacts/removeRequest', {type: 'pending', contact: from})
}

export default function pushContactRequest({type, target}){
  const socket = getNodeInstance().socket
  if(!socket){
    throw new Error('No messenger socket')
  }

  if(['emitted', 'accepted', 'declined', 'canceled'].indexOf(type) === -1){
    throw new Error('Invalid "type". Valid types are "[\'send\', \'accept\', \'decline\', \'cancel\']"')
  }

  socket.emit('user:contact_request', {type, target})
}

const _handleRequest = ({type, from}) => {
  if(!store.state.contacts.requests.isLoaded){
    return
  }
  switch (type){
    case 'emitted':
      _handleEmittedRequest(from)
      break
    case 'accepted':
      _handleAcceptedRequest(from)
      break
    case 'declined':
      _handleDeclinedRequest(from)
      break
    case 'canceled':
      _handleCanceledRequest(from)
      break
    default:
      throw new Error('Invalid "type". Valid types are "[\'send\', \'accept\', \'decline\', \'cancel\']"')
  }
}

export function listen() {
  getNodeInstance().socket.on('user:contact_request', _handleRequest)
}
