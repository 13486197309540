<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <div class="publication-view-content h-100 overflow-auto">
    <header class="text-center white--text pa-3">
      <h3 class="font-weight-light">Publication</h3>
    </header>
    <div class="close-icon js-close-publication" @click="cancel">
      <i class="mdi-arrow-right" />
    </div>
    <div class="publication-viewver mt-4 white">
      <div class="post-container" :class="{'is-alert': publication.is_alert}">
        <!--                <Publication :post="publication" :shouldLoadCommentSection="true"/>-->
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name:'FeedPublicationViewer',
    props: {
      publication: Object
    },
    data() {
      return {}
    },
    computed: {},
    methods: {
      cancel () {
        this.$store.commit('overlay/close')
      }
    }
  }
</script>

<style lang="scss" scoped>
    .publication-view-content {
        background-color: #f5f5f5;
        header {
            background: linear-gradient(to right, #3690ff, #00c6ff);
        }
    }
    .publication-viewver {
        font-size: 0.95em;
        color: #333333;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06);
    }
</style>
