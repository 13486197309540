/*
 * <!--Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential-->
 */

import * as messages from '@/modules/messenger/api/messages'
import {empty} from '@/utils/variables'

export default {
  namespaced: true,

  state: {
    items: []
  },

  getters: {
    get: state => id => {
      return state.items.find(v => v.id === id) || null
    }
  },

  mutations: {
    add(state, id){
      if(!state.items.find(v => v.id === id)) {
        state.items.push({
          id,
          page: 0,
          total: 0,
          endReached: false,
          messages: []
        })
      }
    },

    addMessage(state, {item, messageId}){
      item.messages.unshift(messageId)
    },

    setMessages(state, {item, messages}){
      item.messages.push(...messages)
    },

    setTotal(state, {item, total}){
      item.total = total
    },

    setPage(state, {item, page}){
      item.page = page
    },

    setEndReached(state, {item, endReached}){
      item.endReached = endReached
    }
  },

  actions: {
    async fetch({commit, getters}, conversationUid){
      let item = getters['get'](conversationUid)

      if(!item){
        commit('add', conversationUid)
      }

      item = getters['get'](conversationUid)
      const page = item.page + 1

      await messages.list(item.id, page).then(response => {

        let messages = []

        if(response.data.list && response.data.list.length) {
          for(let message of response.data.list){
            if(empty(message.author)) {
              continue
            }
            commit('messenger/messages/add', message, {root: true})
            messages.push(message.id)
          }

          commit('setMessages', {item, messages})
        }

        commit('setTotal', {item, total: response.data.total})
        commit('setPage', {item, page: page})
        commit('setEndReached', {item, endReached: !response.data.list || response.data.list.length < 10})
      })
    }
  }
}
