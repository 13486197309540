/*
 * <!--Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential-->
 */

import {Model} from '@/models'

export class FileType extends Model{
  static schemaName = 'document_types'
  static primaryKey = 'name_canonical'

  static model = {
    name: '',
    name_canonical: ''
  }
}
