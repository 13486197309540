/*
 * Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

export default {
  namespaced: true,

  state: {
    searched: false,
    results: [],
    page: 1,
    searching: false,
    displayResults: false,
    filters: {
      terms: null,
      // address: null,
      // jobs: [],
      // specialties: null,
      types: []
    }
  },

  mutations: {
    setFilters(state, data) {
      state.filters.terms = data.terms
      // state.filters.address = data.address
      // state.filters.jobs = data.jobs
      // state.filters.specialties = data.specialties
      state.filters.types = data.types
    },
    setJob(state, data) {
      state.filters.job = data
    },
    setSpecialties(state, data) {
      state.filters.specialties = data
    },
    setResults(state, results) {
      state.results = results
      state.searched = true
    },
    clearResults(state) {
      state.results = []
      state.searched = false
    },
    setPage (state, page) {
      state.page = page
    },
    incrementPage (state) {
      state.page++
    },
    setResultsVisible(state) {
      state.displayResults = true
    },
    setSearchVisible(state) {
      state.displayResults = false
    },
    setSearching(state, value) {
      state.searching = value
    }
  },

  actions: {
    /**
     * Clear data to start a new search
     * @param state
     * @param commit
     * @param data
     */
    submitSearch({commit}, data) {
      commit('setFilters', data.filters)
      commit('setPage', data.page)
      commit('clearResults')
    },
    /**
     * Toggle the search in progress state
     * @param state
     * @param commit
     * @param value
     */
    setSearching({commit}, value) {
      commit('setSearching', value)
    },
    /**
     * Initiated on infinite scroller
     * @param commit
     */
    incrementPage({commit}) {
      commit('incrementPage')
    },
    /**
     * Add results to the existing set of results, if more pages are available
     * @param state
     * @param commit
     * @param results
     */
    appendResults({state, commit}, results) {
      commit('setResults', state.results.concat(results))
    }
  }
}
