/*
 * Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import {empty} from 'esyn-utils'

export const rules = {
  required: value => {
    return !empty(value) || 'Champ requis'
  },
  minLength: (min) => {
    return value => (value || '').length >= min || `La valeur doit contenir un minimum de "${min}" caractères`
  },
  maxLength: (max) => {
    return value => (value || '').length <= max || `La valeur doit contenir au maximum "${max}" caractères`
  },
  email: (value) => {
    if (empty(value)) return true
    // https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
    const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
    return pattern.test(value) || 'Addresse e-mail invalide'
  },
  pattern: (regex, errorMsg) => {
    return value => regex.test(value) || errorMsg
  }
}
