/*
 * Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import {empty} from "../../utils/variables"
import Jobs from '@/api/jobs'

export default {
  namespaced: true,

  state: {
    jobs: null,
    specialties: []
  },
  mutations: {
    setJobsList(state, data) {
      state.jobs = data
    },
    addSpecialty(state, payload) {
      state.specialties.push(payload)
    },
    shiftSpecialties(state) {
      state.specialties.shift()
    }
  },
  actions: {
    /**
     * Store the jobs list
     * @param state
     * @param commit
     * @param data
     */
    storeJobs({state, commit}, data) {
      commit('setJobsList', data)
    },
    /**
     * Store the specialties fetched, and if a soft limit is reached, unshift the state array
     * @param state
     * @param commit
     * @param data
     */
    storeSpecialties({state, commit}, data) {
      if(!state.specialties.some(specialty => specialty.job === data.job)) {
        commit('addSpecialty', data)
        if(state.specialties.length > 10) {
          commit('shiftSpecialties')
        }
      }
    },
    /**
     * Fetch list of jobs from the API and store it for later use
     * @param state
     * @param dispatch
     * @returns {Promise<void>}
     */
    async getJobs({state, dispatch}) {
      if(empty(state.jobs)) {
        let fetchedJobs = await Jobs.getJobs()
        dispatch('storeJobs', fetchedJobs.list)
      }
    },
    /**
     * Retrieve specialties from the API or from the store, if already fetched
     * @param state
     * @param dispatch
     * @param commit
     * @param jobId
     * @returns {Promise<*|[]|null|boolean>}
     */
    async getSpecialties({state, dispatch, commit}, jobId) {
      let specialties = state.specialties.filter(specialty => specialty.job === jobId)
      if(!empty(specialties)) {
        return specialties[0].specialties
      } else {
        let spec = await Jobs.getJobSpecialties(jobId)
        dispatch('storeSpecialties', {job: jobId, specialties: spec.list})
        return spec.list
      }
    }
  }
}
