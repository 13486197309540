<template>
  <v-card tile flat class="fill-height">
    <div class="selector-animation">
      <div />
      <div />
      <div />
      <div />
    </div>
    <v-row class="dialog-space fill-height" no-gutters>
      <v-col cols="12" md="5" class="header-space fill-height d-flex flex-column justify-center primary">
        <img alt="Bienvenue sur Medunion urgences" src="@/assets/img/applogo/mspace-logo-light.png"
             class="mx-auto mb-11 medical-space-logo"
        >
        <div class="display-2 white--text mb-2 text-center font-alt">Mes espaces de travail</div>
        <div class="title font-weight-light white--text text-center font-alt">
          Choisir mon espace de travail pour commencer à travailler
        </div>
      </v-col>

      <v-col cols="12" md="7" class="card-space d-flex align-center">
        <transition mode="out-in" name="fade">
          <v-row v-if="loading" key="loader" class="mx-3">
            <v-col class="px-3 text-center text-h4 font-weight-light font-alt op-20">
              Chargement de vos espaces en cours
            </v-col>
          </v-row>
          <v-row v-else key="selector" class="mx-3">
            <v-col v-if="$auth.granted('role_professionals')" cols="6" md="5" lg="4" xl="3">
              <div class="py-5 elevation-x c-pointer space grey lighten-5 fill-height d-flex flex-column align-center"
                   @click.stop="createSpace"
              >
                <font-awesome-icon :icon="['fad', 'plus-circle']" size="2x" class="primary--text" />
                <div class="text-center title font-alt primary--text">Créer un nouvel espace</div>
              </div>
            </v-col>
            <v-col v-for="space in spaces" :key="space" cols="6" md="5" lg="4" xl="3">
              <space-selector-dialog-item :uid="space" @decline-invitation="removeItem(space)" />
            </v-col>
          </v-row>
        </transition>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import {mapMutations, mapState} from 'vuex'
  import SpaceSelectorDialogItem from '@/modules/spaces/components/SpaceSelectorDialogItem'

  export default {
    name: 'SpaceSelectorDesktop',
    components: {SpaceSelectorDialogItem},
    data() {
      return {
        loading: true
      }
    },
    computed: {
      ...mapState('layout', ['spaceSelector']),
      spaces() {
        return this.$store.state.spaces.items
      }
    },
    created() {
      this.$store.dispatch('spaces/fetchItems').finally(() => {
        this.loading = false
      })
    },
    methods: {
      ...mapMutations('spaces', ['removeItem']),
      createSpace() {
        this.$store.commit('layout/toggleSpaceSelector')
        this.$router.push({name:'space_create'})
      }
    }
  }
</script>

