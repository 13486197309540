<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-dialog ref="SpaceSettingsBox" :value="$store.state.layout.spaceSettingsPanel"
            fullscreen hide-overlay transition="dialog-bottom-transition" persistent no-click-animation
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn v-if="currentFrame === 'main'" icon dark @click="close">
          <v-icon>$close</v-icon>
        </v-btn>
        <v-btn v-else icon dark @click="swapFrame('main')">
          <v-icon>$back</v-icon>
        </v-btn>
        <v-toolbar-title>Paramètres de l'espace</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-list v-if="currentFrame === 'main'" three-line subheader>
        <form-category title="Paramètres de l'espace" subtitle="Modifier les paramètres de l'espace pour tous les utilisateurs" class="pt-5 px-4" />
        <!--        <v-list-item v-if="$auth.granted('role_professionals')" @click="swapFrame('features')">-->
        <!--          <v-list-item-icon class="align-self-center">-->
        <!--            <font-awesome-icon :icon="['fal', 'clipboard-list-check']" size="lg" class="primary&#45;&#45;text" />-->
        <!--          </v-list-item-icon>-->
        <!--          <v-list-item-content class="align-self-center">-->
        <!--            <v-list-item-title class="mb-2">Fonctionnalités</v-list-item-title>-->
        <!--            <v-list-item-subtitle>-->
        <!--              Activer ou retirer des fonctionnalités à l'espace-->
        <!--            </v-list-item-subtitle>-->
        <!--          </v-list-item-content>-->
        <!--        </v-list-item>-->
        <v-list-item @click="swapFrame('themes')">
          <v-list-item-icon class="align-self-center">
            <font-awesome-icon :icon="['fal', 'pencil-paintbrush']" size="lg" class="primary--text" />
          </v-list-item-icon>
          <v-list-item-content class="align-self-center">
            <v-list-item-title class="mb-2">Themes</v-list-item-title>
            <v-list-item-subtitle>
              Modifier l'apparence visuelle de l'application
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <theme-settings v-if="currentFrame === 'themes'" @goBack="swapFrame('main')" />
      <features-settings v-if="currentFrame === 'features'" @goBack="swapFrame('main')" />
    </v-card>
  </v-dialog>
</template>
<script>
  import FormCategory from '@/modules/core/layout/FormCategory'
  import ThemeSettings from '../components/ThemeSettings'
  import FeaturesSettings from '../components/FeaturesSettings'
  export default {
    name: 'SpaceSettings',
    components: {FeaturesSettings, ThemeSettings, FormCategory},
    data() {
      return {
        currentFrame: 'main',
        validFrames: ['main', 'themes', 'features']
      }
    },
    methods: {
      /**
       * Close the space settings panel
       */
      close() {
        this.$store.commit('layout/closeSpaceSettings')
      },

      /**
       * Change between frames / menu items
       * @param type Frame name
       */
      swapFrame(type) {
        if(this.validFrames.includes(type)) {
          this.currentFrame = type
        }
      }
    }
  }
</script>
