/*
 * <!--Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential-->
 */

export default [
  {
    path: '/files',
    component: () => import('./views/FilesSection'),
    children: [
      {
        path: '',
        name: 'my-files',
        component: () => import('./views/UserFileList'),
        meta: {
          mobiletitle: 'Gérer mes documents'
        }
      }
    ]
  },
  {
    name: 'file-sharing',
    path: '/files/:uid/share',
    component: () => import('./views/FileSharing.vue')
  },
  {
    name: 'file-edit',
    path: '/files/:uid/edit',
    component: () => import('./views/FileEdit.vue')
  }
]
