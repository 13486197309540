<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <div class="px-7 pb-10 d-flex align-center flex-column justify-center">
    <v-img src="@/assets/img/illustrations/nodata.svg" contain min-width="300" min-height="300" max-height="300" />
    <div class="text-h4 text-center font-weight-light font-alt">{{ title }}</div>
    <div class="body-2 op-70 font-weight-light font-alt text-center">{{ subtitle }}</div>
    <v-btn v-if="actions" class="mt-4" rounded color="secondary" @click="actionEmpty">
      <font-awesome-icon fixed-width size="lg" :icon="['fad', 'plus-hexagon']" class="mr-1" />
      {{ actions }}
    </v-btn>
  </div>
</template>

<script>
  export default {
    name: 'EmptyList',
    props: {
      title: String,
      subtitle: String,
      actions: String
    },
    methods: {
      actionEmpty() {
        this.$emit('action-empty')
      }
    }
  }
</script>
