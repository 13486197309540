/*
 * Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import Vue from 'vue'
import GoogleMapsApiLoader from 'google-maps-api-loader'
import store from '../store'

/** instance of google, we dont't need the script anymore in the index.html to load google api */
GoogleMapsApiLoader({
  libraries: ['places'],
  apiKey: process.env.VUE_APP_GOOGLE_API_KEY
}).then((google) => {
  Vue.prototype.$google = google
}).catch(() => {
  store.commit('layout/openSnackbar', {
    text: 'Un problème est survenue durant la connexion avec Google, de ce fait la carte lors de la recherche ne sera pas fonctionnelle, veuillez réessayer plus tard',
    color: 'error'
  }, {root: true})
})
