export const teamsProfilRoutes = [
  {
    path: '/groups/:uid',
    component: () => import('./views/TeamProfileSection'),
    props: true,
    children: [
      {
        path: '',
        name: 'team-profile',
        component: () => import('./components/TeamFeed.vue'),
        props: true,
        meta: {
          mobiletitle: 'Fiche de groupe'
        }
      },
      {
        path: 'historic',
        name: 'team-historic',
        component: () => import('./components/TeamHistoric.vue'),
        props: true,
        meta: {
          mobiletitle: 'Fiche de groupe'
        }
      },
      {
        path: 'members',
        name: 'team-members',
        component: () => import('./components/Members/TeamMembersList'),
        props: true,
        meta: {
          mobiletitle: 'Fiche de groupe'
        }
      },
      {
        path: 'invitations',
        name: 'team-invitations',
        component: () => import('./components/Members/TeamInvitationsList'),
        props: true,
        meta: {
          mobiletitle: 'Fiche de groupe'
        }
      }
    ]
  }
]
