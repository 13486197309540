import {Comment, Model} from './index'
import * as Comments from '@/modules/comments/api'
import {removeItem} from '@/utils/array'

export class CommentsSection extends Model {
  static schemaName = 'comments_sections'

  static schemaDefinition() {
    return {
      comments: [Comment],
      total: Number,
      totalDirect: Number
    }
  }

  constructor() {
    super()
    this.comments = []
    this.total = 0
    this.totalDirect = 0
  }

  async reply(message) {
    let comment = await Comments.add(this.uid, message)
    comment.section = this.uid

    Comment.save(comment)
    CommentsSection.mutate(commentsSections => {
      commentsSections[this.uid].comments.unshift(comment.uid)
      ++commentsSections[this.uid].total
      ++commentsSections[this.uid].totalDirect
    })
  }

  removeComment(comment) {
    CommentsSection.mutate(commentsSections => {
      removeItem(commentsSections[this.uid].comments, comment.uid)
      --commentsSections[this.uid].total
      --commentsSections[this.uid].totalDirect
    })
  }

  async loadComments() {
    let last = this.comments[this.comments.length - 1]
    let date = typeof last !== 'undefined' ? Comment.get(last).creationDate : null
    let {total, comments} = await Comments.list(this.uid, date, 'oldest')

    Comment.save(comments.map(comment => {
      return {...comment, section: this.uid}
    }))

    CommentsSection.mutate(commentsSections => {
      for (let comment of comments) {
        if (commentsSections[this.uid].comments.indexOf(comment.uid) === -1) {
          commentsSections[this.uid].comments.push(comment.uid)
        }
      }
    })

    return total - comments.length
  }

  incrementTotal() {
    CommentsSection.mutate(commentsSections => {
      ++commentsSections[this.uid].total
    })
  }

  decrementTotal() {
    CommentsSection.mutate(commentsSections => {
      --commentsSections[this.uid].total
    })
  }

  static async load(uid) {
    if (!CommentsSection.get(uid)) {
      CommentsSection.save(await Comments.getSection(uid))
    }
  }
}
