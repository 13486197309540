<!--
  - Copyright (C)  E-Synaps SAS - 2021 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-app-bar id="tour-topbar" clipped-right app color="primary">
    <router-link to="/">
      <v-img max-width="130px" width="130px" src="@/assets/img/applogo/mspace-logo-light.png" position="center center" to="/" />
    </router-link>
    <v-tabs
      centered
      dark
      icons-and-text
      class="ms-topbar font-alt"
    >
      <v-tab to="/" exact link>
        Accueil
        <font-awesome-icon :icon="['fad', 'home-alt']" size="3x" />
      </v-tab>
      <v-tab to="/network" link exact>
        Mon Réseau
        <font-awesome-icon :icon="['fad', 'chart-network']" size="3x" />
      </v-tab>
      <v-tab to="/files" link exact>
        Mes Documents
        <font-awesome-icon :icon="['fad', 'folder-open']" size="3x" />
      </v-tab>
    </v-tabs>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn icon depressed dark small class="mx-1 pa-5" :to="{ name: 'search'}" v-on="on">
          <font-awesome-icon :icon="['fal', 'search']" size="2x" />
        </v-btn>
      </template>
      <span>Rechercher</span>
    </v-tooltip>
    <messenger-toggle />
<!--    <notifications />-->
    <user-menu />
  </v-app-bar>
</template>

<script>
  import UserMenu from '@/modules/core/layout/header/UserMenu'
  import Notifications from '@/modules/core/layout/header/Notifications'
  import MessengerToggle from '@/modules/core/layout/header/MessengerToggle'
  export default {
    name: 'HeaderBar',
    components: {MessengerToggle, Notifications, UserMenu}
  }
</script>

<style lang="scss" scoped>
.ms-topbar {
  a {
    font-size: 9px;
  }
}
</style>
