import api from '@/api/api'

export function createPrivateInvitationLink(spaceUid) {
  return api.post(`/v3/spaces/${spaceUid}/invitations/private-link/create`)
}

export function inviteUser(spaceUid, userUid) {
  return api.post(`/v3/spaces/${spaceUid}/invitations/${userUid}`)
}

export function listPending(spaceUid) {
  return api.get(`/v3/spaces/${spaceUid}/invitations`, {
    cache: {
      ignoreCache: true
    }
  })
}
