import {Membership, Person, Space} from '@/models'

// eslint-disable-next-line no-unused-vars
export default function (user, can, cannot) {
  can(['transfer_ownership', 'members:update_elevation', 'members:remove', 'members:ban', 'update'], Space, space => {
    let membership = space && space.membership ? Membership.get(space.membership) : null
    return membership instanceof Membership && 'owner' === membership.elevation
  })

  // Seul les professionnels peuvent lister les membres d'un espace et ajouter un patient
  can(['members:list', 'members:add_patient'], Space, space => {
    let membership = space && space.membership ? Membership.get(space.membership) : null
    let person = Person.get(user.sub)

    return membership instanceof Membership && person instanceof Person &&
      person.type === 'user:professional' && 'none' !== membership.elevation
  })

  can(['invitations:list', 'invitations:create'], Space, space => {
    let membership = space && space.membership ? Membership.get(space.membership) : null
    return membership instanceof Membership && ['manager', 'owner'].includes(membership.elevation)
  })
}
