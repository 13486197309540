/*
 * Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import api from './api'


export async function getJobs() {
  return (await api.get('/v2/jobs?limit=-1&with_links=false')).data
}
export async function getJobSpecialties(jobUid) {
  return (await api.get('/v2/job/'+jobUid+'/specialties')).data
}

export default {
  getJobs,
  getJobSpecialties
}
