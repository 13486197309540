<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-card v-if="model" id="c-document-upload" class="pa-4" tile flat>
    <v-form ref="form" v-model="valid" class="px-3 py-4 py-md-0 px-md-0" method="post" @submit.prevent="submit()">
      <form-category class="mb-8"
                     subtitle="Editer les informations générales du document"
                     title="Edition du document"
      />

      <v-text-field v-model="model.name" label="Titre" />
      <file-type-select-field v-model="model.file_type" />
      <v-combobox v-model="model.keywords" multiple chips deletable-chips label="Tags" />
      <v-text-field v-model="model.description" label="Description" />


      <div class="button-form text-center mt-4">
        <v-btn x-small type="submit" class="mr-2" @click="cancel">
          Annuler
        </v-btn>
        <v-btn class="primary" x-small type="submit" :disabled="loading" :loading="loading">
          Enregistrer les modifications
        </v-btn>
      </div>
    </v-form>
  </v-card>
</template>

<script>
  import FormCategory from '@/modules/core/layout/FormCategory'
  import FileTypeSelectField from '@/modules/files/components/Form/FileTypeSelectField'
  import * as Files from '@/modules/files/api'
  import {File} from '@/models'

  export default {
    name: 'FileEdit',

    components: {FormCategory, FileTypeSelectField},
    props: {
      uid:{
        type: String,
        required: true
      }
    },
    data() {
      return {
        editing: false,
        loading: false,
        model: {
          name: '',
          keywords: '',
          description: '',
          file_type: ''
        },
        valid: true
      }
    },
    async mounted() {
      const file = await Files.get(this.uid).then(response => response.data)

      this.model = {
        name: file.name,
        keywords: file.keywords,
        description: file.description,
        file_type: file.file_type ? file.file_type.name_canonical : null
      }
    },

    methods: {
      submit() {
        this.loading = true
        Files.edit(this.uid, this.model).then(response => {
          File.save(response.data)
          this.loading = false
          this.$emit('child-editing', this.editing)
        })
      },
      cancel () {
        this.$emit('child-editing', this.editing)
      }
    }
  }
</script>
