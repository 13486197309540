<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-app id="app" :class="{'blur-body': haveModal}">
    <page-loader v-if="$root.loading || $auth.loading || $auth.error" />
    <template v-else>
      <template v-if="$route.meta.disableLayout">
        <router-view :key="$route.fullPath" />
      </template>
      <template v-else>
        <header-bar-mobile v-if="$vuetify.breakpoint.mdAndDown" />
        <header-bar v-else />
        <sub-header-mobile v-if="$vuetify.breakpoint.mdAndDown" />
        <transition name="fade">
          <v-main>
            <page-background />
            <router-view :key="$route.fullPath" />
          </v-main>
        </transition>
        <user-settings />
        <space-settings v-feature-flipping="'SPACE-MANAGEMENT'" />
        <messenger-container />
        <modals-container />
        <snackbar />
        <file-viewer />
        <bottom-navigation-mobile v-if="$vuetify.breakpoint.mdAndDown" />
      </template>
      <space-selector-dialog />
    </template>

    <v-offline @detected-condition="handleConnectivityChange" />
    <div v-for="snackbar in snackbars" :key="snackbar.id">
      <v-snackbar
        :value="true"
        :timeout="snackbar.timeout"
        :color="snackbar.color || 'info'"
        @input="removeSnackbar(snackbar)"
      >
        {{ snackbar.text }}
        <v-btn dark text @click="removeSnackbar(snackbar)">
          Fermer
        </v-btn>
      </v-snackbar>
    </div>

    <v-snackbar :value="$auth.sessionExpired" color="warning" vertical :timeout="-1">
      Vous vous êtes déconnecté dans un autre onglet ou une autre fenêtre.
      <v-btn small dark text outlined @click="() => $router.go()">Réactualiser votre session</v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
  import SubHeaderMobile from '@/modules/core/layout/header/SubHeaderMobile'
  import BottomNavigationMobile from '@/modules/core/layout/bottom/BottomNavigationMobile'
  import HeaderBarMobile from '@/modules/core/layout/header/HeaderBarMobile'
  import UserSettings from './modules/user/views/UserSettings'
  import { mapActions, mapMutations, mapState } from 'vuex'
  import { empty } from 'esyn-utils/src/utils/variables'
  import { setEnabledFeatures } from 'vue-feature-flipping'
  import SpaceSettings from './modules/spaces/views/SpaceSettings'
  import { retrieveStoredFeatures } from './mixins/features/features'
  import { getRulesFor } from '@/services/ability'
  import HeaderBar from '@/modules/core/layout/header/HeaderBar'
  import PageLoader from '@/modules/core/layout/PageLoader'
  import PageBackground from '@/modules/core/layout/PageBackground'
  import ModalsContainer from '@/modules/core/modals/ModalsContainer'
  import Snackbar from '@/modules/core/layout/snackbars/Snackbar'
  import MessengerContainer from '@/modules/messenger/components/MessengerContainer'
  import SpaceSelectorDialog from '@/modules/spaces/views/SpaceSelectorDialog'
  import FileViewer from '@/modules/files/components/viewer/FileViewer'
  import { Person, Space } from '@/models'

  export default {
    name: 'App',
    components: {
      SubHeaderMobile,
      BottomNavigationMobile,
      Snackbar,
      FileViewer,
      SpaceSelectorDialog,
      MessengerContainer,
      ModalsContainer,
      PageBackground,
      PageLoader,
      HeaderBar,
      HeaderBarMobile,
      SpaceSettings,
      UserSettings
    },
    data() {
      return {
        isInitialLoad: true
      }
    },
    computed: {
      ...mapState('layout', ['snackbars', 'haveModal', 'confirmSnackbar']),
      ...mapState('feeds', ['pendingPublications']),
      space() {
        return Space.get(this.$store.state.core.space)
      },
      features() {
        if (!this.space || !Object.prototype.hasOwnProperty.call(this.space, 'features')) {
          return null
        }
        return this.space.features
      }
    },

    watch: {
      ['$auth.loading'](value) {
        if (!value && this.$auth.authenticated) {
          this.$ability.update(getRulesFor(this.$auth.user))
          let type = 'user'
          if (this.$auth.granted('role_professionals')) {
            type = 'user:professional'
          } else if (this.$auth.granted('role_patients')) {
            type = 'user:patient'
          }
          Person.save({
            uid: this.$auth.user.sub,
            type
          })
        }
      },

      async space() {
        if (this.isInitialLoad && !empty(this.space)) {
          this.isInitialLoad = false
          this.onSpaceLoad()
        }

        if (this.space) {
          await this.space.loadFeatures()
        }
      }
    },
    beforeCreate() {
      document.querySelector('meta[name="theme-color"]').setAttribute('content', this.$vuetify.theme.currentTheme.primary)
    },
    created() {
      setEnabledFeatures(retrieveStoredFeatures())
    },
    mounted() {
      this.processPendingPublications(this.pendingPublications.length)
    },

    methods: {
      ...mapMutations('layout', ['removeSnackbar']),
      ...mapActions('feeds', ['processPendingPublications']),

      handleConnectivityChange(status) {
        if (status) {
          this.processPendingPublications(this.pendingPublications.length)
        }

        if (this.isInitialLoad && !status || !this.isInitialLoad) {
          this.$root.$emit('snackbar:open', {
            snackbar: () => import('@/modules/core/layout/snackbars/Snackbar'),
            type: status ? 'success' : 'error',
            title: status ? 'Mode en ligne' : 'Mode hors-ligne',
            subtitle: status ? 'Connecté à internet' : 'Bascule en mode hors-ligne'
          })
        }
      },
      applyTheme() {
        this.$vuetify.theme.dark = this.$store.state.userSettings.darkMode !== '0'
      },

      onSpaceLoad() {
        this.applyTheme()
        this.$appName = this.space.name
      }
    }
  }
</script>
