import {Person, Model} from '../index'

export class Membership extends Model {
  static schemaName = 'memberships'

  static schemaDefinition() {
    return {
      user: Person
    }
  }
}
