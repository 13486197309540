<template>
  <v-banner v-if="!isSearch" single-line sticky class="sub-header-mobile elevation-x" :class="$vuetify.theme.dark ? 'sub-header-dark' :'sub-header-light'">
    <div class="font-weight-light font-alt" :class="$vuetify.theme.dark ? 'white--text' :'primary--text'">{{ $route.meta.mobiletitle }}</div>
    <v-spacer />
    <v-btn fab x-small class="elevation-0" @click="toggleDrawerMobile">
      <font-awesome-icon :icon="['fad', 'bars']" size="2x" :class="$vuetify.theme.dark ? 'white--text' :'primary--text'" />
    </v-btn>
  </v-banner>
</template>

<script>
  export default {
    name: 'SubHeaderMobile',
    data() {
      return{
        title: String
      }
    },
    computed: {
      isSearch() {
        return this.$route.name === 'search'
      }
    },
    methods: {
      toggleDrawerMobile() {
        this.$store.commit('layout/setDrawerMobile', !this.$store.state.layout.menuDrawerMobile)
      }
    }
  }
</script>

<style lang="scss">
  .v-banner__text {
    display: flex;
    align-items: center;
  }
</style>
