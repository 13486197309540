/*
 * <!--Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential-->
 */

import * as Spaces from '@/modules/spaces/api'
import Vue from 'vue'
import members from './members'
import {Space} from '@/models'
import {column, removeItem} from '@/utils/array'
import {COLLECTION_PROJECTIONS} from '@/models/Gang/Space'

export const SPACE_ELEVATIONS = {
  member: 'member',
  manager: 'manager',
  owner: 'owner'
}

export default {
  namespaced: true,

  modules: {
    members // TODO use generic one
  },
  state: {
    items: null,
    memberships: {}
  },

  getters: {
  },

  mutations: {
    setItems(state, items) {
     state.items = items
    },

    setFeatures(state, {space, features}) {
      const index = state.spaces.findIndex(val => val.uid === space)

      if (-1 !== index) {
        const item = {...state.spaces[index], features}
        Vue.set(state.spaces, index, item)
      }
    },
    removeItem(state, item) {
      removeItem(state.items, item)
    }
  },

  actions: {
    async fetchItems({state, commit}) {
      if(state.items !== null) {
        return
      }
      commit('setItems', [])
      let spaces = (await Spaces.cget(COLLECTION_PROJECTIONS)).data
      Space.save(spaces)
      commit('setItems', column(spaces, 'uid'))
    },

    async fetchFeatures({commit}, space) {
      return await Spaces.fetchFeatures(space)
        .then(response => {
          commit('setFeatures', {space, features: response.data})

          return [null, response.data]
        })
        .catch(error => [error.response, null])
    }
  }
}
