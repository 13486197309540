/*
 * Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import Vue from 'vue'
import Vuex, {Store} from 'vuex'
import vuetify from '@/plugins/vuetify'
import Confirm from '@/plugins/confirm'

import vuexPersist from './storage'
import dbPlugin from './db-plugin'

import layout from '@/store/modules/layout'
import overlay from '@/store/modules/overlay'
import patients from '@/store/modules/patients'
import comments from '@/store/modules/comments'
import contacts from '@/store/modules/contacts'
import establishments from '@/modules/establishments/store'
import searchBar from '@/store/modules/searchBar'
import advancedSearch from '@/modules/search/store/advancedSearch'
import notifications from '@/store/modules/notifications'
import userSettings from '@/store/modules/userSettings'
import jobs from '@/store/modules/jobs'

import core from '@/modules/core/store'
import spaces from '@/modules/spaces/store'
import teams from '@/modules/teams/store'
import feeds from '@/modules/feeds/store'
import files from '@/modules/files/store'
import messenger from '@/modules/messenger/store'
import user from '@/modules/user/store'

import * as models from  '@/models'

Vue.use(Vuex)
Vue.use(Confirm, {vuetify})

export default new Store({
  /**
   * Do not enable strict mode when deploying for production!
   * Strict mode runs a synchronous deep watcher on the state tree for detecting inappropriate mutations,
   * and it can be quite expensive when you make large amount of mutations to the state.
   * Make sure to turn it off in production to avoid the performance cost.
   */
  strict: process.env.NODE_ENV !== 'production', // https://vuex.vuejs.org/guide/strict.html

  state: {},

  getters: {
    isOnline() {

    }
  },

  modules: {
    core,
    layout,
    overlay,
    teams,
    patients,
    comments,
    messenger,
    establishments,
    contacts,
    searchBar,
    advancedSearch,
    notifications,
    userSettings,
    jobs,
    spaces,
    feeds,
    files,
    user
  },

  mutations: {
    RESTORE_MUTATION: vuexPersist.RESTORE_MUTATION // this mutation **MUST** be named "RESTORE_MUTATION"
  },

  plugins: [
    vuexPersist.plugin,
    dbPlugin.create(Object.values(models))
  ]
})
