import {FeedList, Model, Patient, Professional} from '../index'
import * as Users from '@/modules/user/api'
import {promiseMemoize} from '@/utils/promise'
import {arrayContainsArray, merge} from '@/utils/array'

export class Person extends Model {
  static schemaName = 'persons'

  static schemaDefinition() {
    return {
      feedList: FeedList
    }
  }

  static types() {
    return {
      'user:patient': Patient,
      'user:professional': Professional
    }
  }

  constructor() {
    super()
    this.fetchFeedList = promiseMemoize(this.fetchFeedList)
    this.projections = []
  }

  async fetchFeedList() {
    if (!this.feedList) {
      if (typeof this.constructor.type === 'undefined') {
        throw new Error('This method must be called from model Professional or Patient')
      }

      let feedList = (await Users.getFeedList(this.$store().state.core.space, this.uid)).data
      FeedList.save({...feedList, owner: this})
      Person.save({
        uid: this.uid,
        type: this.constructor.type,
        feedList
      })
    }
    return FeedList.get(this.feedList)
  }

  static async fetchOne(uid, projections = []) {
    let person = Person.get(uid)
    if (!person || !arrayContainsArray(person.projections, projections)) {
      Person.save({
        ...(await Users.get(uid, projections)).data,
        projections: person ? merge(person.projections, projections) : projections
      })
    }

    return Person.get(uid)
  }

}
