/*
 * <!--Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential-->
 */

import api from '@/api/api'

export function getFeedList(uid) {
  return api.get('/v3/spaces/' + uid + '/feed-list')
}

export function leave(uid) {
  return api.delete('/v3/users/me/spaces/' + uid)
}

export function cget(projections) {
  return api.get('/v3/users/me/spaces', {
    params: {
      projections
    }
  })
}

export function getBySlug(slug, projections) {
  return api.get(`/v3/spaces/slug/${slug}`, {
    params: {projections}
  })
}

export function get(uid) {
  return api.get(`/v3/spaces/${uid}`)
}

export function create(data) {
  return api.post('/v3/spaces', {data})
}

export function fetchFeatures(space) {
  return api.get(`/v3/spaces/${space}/features-enabled`)
}

export function getFileList(uid) {
  return api.get('/v3/spaces/' + uid + '/file-list')
}

export function getMessengerConversationsParticipantsSuggestions(spaceUid, term) {
  return api.get('/v3/spaces/' + spaceUid + '/messenger/conversations/suggest-participants', {
    params: {
      term
    }
  })
}

export function generateSlug(name) {
  return api.post('/v3/spaces/slug/generate', {name})
}

import * as members from './members'
import * as invitations from './invitations'

export {
  members,
  invitations
}
