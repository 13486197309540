/*
 * <!--Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential-->
 */

import {FileList, Space} from '@/models'

export default {
  computed: {
    space() {
      return Space.get(this.$store.state.core.space)
    },
    userFileList(){
      return FileList.get(this.$auth.user.sub)
    },

    spaceFileList() {
      return FileList.get(this.space.fileList)
    }
  },

  async created(){
    if(this.userFileList){
      return
    }

    FileList.create(this.$auth.user.sub)

    this.loading = true

    if(!this.spaceFileList){
      let error = await Space.get(this.space.uid).fetchFileList()

      if(error){
        throw new Error('Error while fetching space files list: ' + error.toString())
      }
    }

  }
}
