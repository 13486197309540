/*
 * Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import {trim} from './string'

export function queryString(params) {
  return Object.keys(params).map(key => key + '=' + encodeURIComponent(params[key])).join('&')
}

export function currentHost() {
  return window.location.protocol +'//'+ window.location.host
}

export function currentURL() {
  return trim(currentHost() + window.location.pathname, '/')
}

