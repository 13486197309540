/*
 * <!--Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential-->
 */
import * as Files from '@/modules/files/api'
import upload from './files-upload'

export const PENDING_DOCUMENTS_STATUS = {
  pending: 'pending',
  processing: 'processing',
  done: 'done'
}

export default {
  namespaced: true,
  modules: {
    upload
  },
  state: {
    viewerState: false,
    viewer: {
      docUid: null,
      loaded: false
    }
  },
  getters: {
    get: (state) => async (uid) => {
      let document = state.items.find(document => uid === document.uid)

      if (!document) {
        document = await Files.get(uid).then(response => {
          return response.data
        })
      }

      return document
    }
  },

  mutations: {
        openViewer(state) {
      state.viewerState = true
    },

    closeViewer(state) {
      state.viewerState = false
    },

    setLoaded(state, loaded) {
      state.viewer.loaded = loaded
    },

    setViewerUid(state, uid) {
      state.viewer.docUid = uid
    }
  },

  actions: {
    openDocument({commit}, uid) {
      commit('openViewer')
      commit('setViewerUid', uid)
    },

    closeDocument({commit}) {
      commit('closeViewer')
    },

    getContent(context, uid) {
      return Files.getContent(uid)
        .then(response => {
          return [null, response.data]
        })
        .catch(error => {
          return [{status: error.response.status, message: error.response.statusText}, null]
        })
    }
  }
}
