/*
 * <!--Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential-->
 */

import api from '@/api/api'

export function list(conversationUid, page = 1, limit = 10) {
  return api.get(`/v2/messenger/conversations/${conversationUid}/messages`, {
    params: {
      page,
      limit
    }
  })
}

export function create(conversationUid, {message, attachment}) {
  return api.post(`/v2/messenger/conversations/${conversationUid}/messages`, {
    message_type: {
      message: message || null,
      attachment: attachment || null
    }
  })
}

/**
 * @param messages array of messages id
 */
export function receiveMessage(messageIds) {
  return api.put('/v2/messenger/messages/receive', {messages: messageIds})
}
