import NodeClient from 'node-client'
import config from '@/config'
import {getInstance as getAuthInstance} from '@/plugins/auth'

let instance

export function getInstance() {
  return instance
}

export default {
  install(Vue) {
    const auth = getAuthInstance()

    if (instance) return instance

    let firstAuthenticationAttempt = true

    instance = new NodeClient(config.urls.node, config.oauth.clientId)

    auth.$watch('authenticated', async authenticated => {
      if (authenticated) {
        instance.authenticate(await auth.getTokenSilently())
      }
    })

    auth.$watch('sessionExpired', async value => {
      if (value) {
        instance.disconnect()
      }
    })

    instance.on('inactive', async () => {
      if (!firstAuthenticationAttempt) {
        // eslint-disable-next-line no-console
        console.error('NodeClient - Authentication failed : The token is inactive, already tried to re-authenticate. Node client is shut off.')
        return
      }

      // eslint-disable-next-line no-console
      console.warn('NodeClient - Authentication failed : The token is inactive, trying to refresh the session')
      firstAuthenticationAttempt = false
      instance.authenticate(await auth.getTokenSilently())
    })

    instance.on('error', (error) => {
      // eslint-disable-next-line no-console
      console.error('NodeClient - Error : ' + error)
    })

    instance.on('authenticated', () => {
      firstAuthenticationAttempt = true
    })

    instance.on('disconnect', (reason) => {
      // eslint-disable-next-line no-console
      console.log('NodeClient - Disconnected : ' + reason)
    })

    instance.on('connect', async () => {
      if (auth.authenticated) {
        // eslint-disable-next-line no-console
        console.log('NodeClient - Attempt to connect : The session was already started')
        instance.authenticate(await auth.getTokenSilently())
      }
    })

    instance.connect()

    Vue.prototype.$node = instance
  }
}
