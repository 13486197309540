import { FeedList, Model, File, CommentsSection } from '@/models'
import * as Feeds from '@/modules/feeds/api'

export class Publication extends Model {
  static schemaName = 'publications'

  static schemaDefinition() {
    return {
      uid: String,
      creation_date: String,
      update_date: String,
      visibility: String,
      open_graph: Object,
      alert: Boolean,
      by: FeedList,
      on: FeedList,
      comments_section: CommentsSection,
      files: [File]
    }
  }

  isModified() {
    return this.creation_date !== this.update_date
  }

  async update(data) {
    Publication.save(await Feeds.updatePublication(this.uid, data))
  }
}
