import { pick } from '@/utils/object'

const publicationCommonProperties = ['uid', 'creation_date', 'update_date', 'visibility', 'message']
const publicationProperties = publicationCommonProperties.concat(['open_graph', 'alert', 'files'])

function feedListFromApi(feedList) {
  return {
    uid: feedList.feed_list,
    personal_feed: {uid: feedList.personal_feed},
    owner: feedList.owner
  }
}

function publicationItemFromApi(publication) {
  return {
    ...pick(publication, publicationProperties),
    by: feedListFromApi(publication.by),
    on: feedListFromApi(publication.on),
    comments_section: {
      uid: publication.comments_section,
      total: publication.total_comments,
      totalDirect: publication.total_direct_comments
    }
  }
}

export function feedItemFromApi(item) {
  switch (item.type) {
    case 'publication':
      return publicationItemFromApi(item)
    case 'publication_sharing':
      return publicationItemFromApi(item)
  }
  throw 'Unexpected feed item type'
}

export function feedRowFromApi(row) {
  let {item, pinned} = row
  let result = {
    type: item.type,
    identifier: item.on.feed_list + ':' + item.uid,
    uid: item.uid,
    pinned
  }

  switch (item.type) {
    case 'publication':
      result.publication = publicationItemFromApi(item)
      break
    case 'publication_sharing':
      result.publication = publicationItemFromApi(item)
      result.shared_publication = publicationItemFromApi(item.shared_publication)
      break
  }

  return result
}
