export default [
  {
    path: '/',
    name: 'home',
    component: () => import('../modules/spaces/views/SpaceSelector'),
    meta: {
      disableLayout: true
    }
  },
  {
    path: '/join/:token',
    name: 'join',
    component: () => import('../modules/spaces/views/SpaceJoin'),
    props: true,
    meta: {
      disableLayout: true,
      async: true
    }
  }
]
