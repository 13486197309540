/*
 * Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import api from '@/api/api'

const prefix = '/v2/professionals/me/'

export function getContacts(page = 1, limit = 10) {
  return api.get(`${prefix}contacts`, {
    params: {
      page, limit
    }
  })
}

export function remove(uid) {
  return api.delete(`${prefix}contacts/${uid}/invitation`, {
    params: {type: 'professional'}
  })
}

export function online() {
  return api.get(`${prefix}contacts/online`)
}

export function search(page = 1, limit = 10, order, term, spaceUid) {
  let filters = {mycontacts: true}
  if (spaceUid) {
    filters.space = spaceUid
  }
  if (term) {
    filters.search_term = term
  }
  return api.post('/v3/users/search', {page, limit, order, filters, projections: ['professional_address', 'professional_job', 'professional_skills', 'professional_specialties']})
}

export function getPendingRequests() {
  return api.get(`${prefix}contacts/invitations/pending`, {
    params: {
      type: 'professionals'
    }
  })
}

export function getSendingRequests() {
  return api.get(`${prefix}contacts/invitations/sent`, {
    params: {
      type: 'professionals'
    }
  })
}

export function handleRequest(uid, boolean) {
  return api.put(`${prefix}contacts/${uid}/invitation`, {type: 'professional', accept: boolean})
}

export function sendRequest(uid) {
  return api.post(prefix + 'contacts/' + uid + '/invitation', {type: 'professional'})
}

export function checkIsMyContact(uid) {
  return api.get(`${prefix}contacts/is-my-contact`, {params: {contact: uid}})
}
