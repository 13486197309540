import {empty} from '@/utils/variables'

export default {
  namespaced: true,
  state: {
    newPatients : []
  },
  mutations: {
    addPatient(state, patient) {
      let clearPatient = {
        full_name : patient.full_name,
        birth_date : patient.birth_date,
        picture : patient.picture,
        uid : patient.uid,
        phone: patient.phone,
        email: patient.email
      }
      if(!empty(patient.zipCode && !empty(patient.zipCode.name &&
        !empty(patient.city && !empty(patient.city.name))))) {
        clearPatient.zipCode = { name : patient.zipCode.name }
        clearPatient.city = { name : patient.city.name }
      }
      if (!state.newPatients.includes(clearPatient)) {
        if(state.newPatients.unshift(clearPatient) > 10) {
          state.newPatients.pop()
        }
      }
    }
  }
}
