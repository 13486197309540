import {Model} from '@/models'
import {FeedItem, Publication, Feed} from './index'
import * as Feeds from '@/modules/feeds/api'
import {FeedListOwner} from '@/modules/feeds/models/FeedListOwner'
import dayjs from 'dayjs'
import {removeItem} from '@/utils/array'
import Vue from 'vue'

export class FeedList extends Model {
  static schemaName = 'feed_lists'

  static schemaDefinition() {
    return {
      owner: FeedListOwner, // user, team, space, establishment
      items: [FeedItem],
      personal_feed: Feed
    }
  }

  constructor() {
    super()
    this.items = []
    this.endReached = false

    FeedItem.on('delete', (key) => {
      this.$mutate(feedList => {
        removeItem(feedList.items, key)
      })
    })
  }

  reset() {
    this.$mutate(feedList => {
      Vue.set(feedList, 'items', [])
      feedList.endReached = false
    })
  }

  async load(limit, filterSubscriptions = null) {
    let sortedItems = this.listSortedItems()
    let lastRow = sortedItems.length > 0 ? sortedItems[sortedItems.length - 1] : null
    let skip = lastRow ? sortedItems.filter(row => row.date === lastRow.date).length : 0
    let beforeDate = lastRow ? lastRow.date : null

    let items = await Feeds.listItems(this.uid, filterSubscriptions, beforeDate, skip, limit)
    FeedItem.save(items)
    this.$mutate((feedList) => {
      for (let item of items) {
        if (feedList.items.indexOf(item.identifier) === -1) {
          feedList.items.push(item.identifier)
        }
      }
      feedList.endReached = items.length < limit
    })

    return this.endReached
  }

  listSortedItems() {
    return this.items.map(item => FeedItem.get(item)).filter(Boolean)
      .sort((a, b) => {
        if (a.date < b.date) {
          return 1
        } else if (a.date > b.date) {
          return -1
        }
        return 0
      })
      .sort((a, b) => {
        if (a.pinned === b.pinned) {
          return 0
        } else if (a.pinned < b.pinned) {
          return 1
        }
        return -1
      })
  }

  async publish(targetFeedListUid, author, message, open_graph, visibility = 'private', files = [], alert = false) {
    let now = dayjs.utc().format('YYYY-MM-DD HH:mm:ss')
    let publication = new Publication()
    publication.alert = alert
    publication.author = author
    publication.message = message
    publication.open_graph = open_graph
    publication.visibility = visibility
    publication.creation_date = now
    publication.update_date = now
    publication.files = files

    this.$store().dispatch('feeds/publish', {feedListUid: this.uid, targetFeedListUid, publication})
  }

  addItem(item) {
    this.$mutate(feedList => {
      feedList.items.unshift(item.identifier)
    })
  }

  async delete(item) {
    await Feeds.deleteItem(this.uid, item.uid)
    item.$delete()
  }

  async pinItem(item) {
    // TODO trouver une solution pour l'update de modele polymorphique
    // Se servir du type de l'instance déjà existance ?
    // Remplacer item par {uid, schema} ?
    await Feeds.pinItem(this.uid, item.uid)
    FeedItem.save({identifier: item.identifier, type: item.type, pinned: true})
  }

  async unpinItem(item) {
    await Feeds.unpinItem(this.uid, item.uid)
    FeedItem.save({identifier: item.identifier, type: item.type, pinned: false})
  }

}
