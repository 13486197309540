<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <div>
    <template v-for="i in listSize">
      <v-skeleton-loader
        ref="skeleton"
        :key="'skel-'+i"
        :type="type"
        class="mx-auto dimming-skeleton"
      />
    </template>
  </div>
</template>

<script>
  export default {
    name: 'SkeletonList',
    props: {
      small: Boolean,
      type: {
        type: String,
        default: 'list-item-avatar-three-line'
      }
    },
    computed: {
      listSize() {
        return this.small ? 3 : 5
      }
    }
  }
</script>
