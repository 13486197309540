import api from '@/api/api'

export function find(uid, projections) {
  return api.get('/v3/teams/' + uid, {
    params: {
      projections
    }
  })
}

export function list(spaceUid, projections = null, filters = null, page = 1, limit = -1) {
  return api.get('/v3/users/me/teams', {
    params: {
      page,
      limit,
      projections,
      filters: {
        space: spaceUid,
        ...filters
      }
    }
  })
}

export function create(data, projections = null) {
  return api.post('/v3/teams', {data, projections})
}

export function update(uid, data, projections = null) {
  return api.patch('/v3/teams/' + uid, {data, projections})
}

export function getFeedList(spaceUid, teamUid) {
  return api.get(`/v3/spaces/${spaceUid}/teams/${teamUid}/feed-list`)
}

export function leave(teamUid) {
  return api.delete(`/v3/users/me/teams/${teamUid}`)
}

export function getFileList(uid) {
  return api.get('/v3/teams/' + uid + '/file-list')
}

import * as members from './members'
import * as invitations from './invitations'

export {
  members,
  invitations
}
