import { column, replaceItem } from '@/utils/array'

export function promiseMemoize(fn) {
  let cache = {}
  return function (...args) {
    let strX = JSON.stringify(args)
    return strX in cache ? cache[strX]
      : (cache[strX] = fn.apply(this, args).catch((x) => {
        delete cache[strX]
        throw x
      }))
  }
}

export function retryablePromises(callbacks, onError) {
  let promises = []
  for (let callback of callbacks) {
    promises.push({promise: callback(), callback})
  }

  function executor(resolve, reject) {
    Promise.allSettled(column(promises, 'promise')).then((results) => {
      let rejectedPromises = []
      let errors = []
      for (let i in results) {
        let reason
        if (results[i].status === 'rejected') {
          rejectedPromises.push(promises[i].promise)
          reason = results[i].reason
        }
        errors.push(reason)
      }

      if (rejectedPromises.length) {
        if (typeof onError !== 'function') {
          reject(...errors)
          return
        }

        Promise.resolve(onError(...errors))
          .then((retry) => {
            if (!retry) {
              reject(...errors)
              return
            }

            for (let i in promises) {
              let {promise, callback} = promises[i]
              if(rejectedPromises.includes(promise)) {
                replaceItem(promises, promises[i], {
                  promise: callback(),
                  callback
                })
              }
            }

            executor(resolve, reject)
          })
          .catch(() => reject(...errors))
      } else {
        resolve(column(results, 'value'))
      }
    })
  }

  return new Promise(executor)
}
