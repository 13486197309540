<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-dialog ref="settingsBox" v-model="$store.state.layout.settingsPanel" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn v-if="currentFrame === 'main'" icon dark @click="close">
          <v-icon>$close</v-icon>
        </v-btn>
        <v-btn v-else icon dark @click="swapFrame('main')">
          <v-icon>$back</v-icon>
        </v-btn>
        <v-toolbar-title>Mes paramètres</v-toolbar-title>
      </v-toolbar>
      <v-list v-if="currentFrame === 'main'" three-line subheader>
        <form-category title="Paramètres utilisateurs" subtitle="Modifier les paramètres de mon compte" class="pt-5 px-4" />
        <v-list-item @click="swapFrame('infos')">
          <v-list-item-icon class="align-self-center">
            <font-awesome-icon class="text--primary" size="lg" :icon="['fal', 'id-card']" />
          </v-list-item-icon>
          <v-list-item-content class="align-self-center">
            <v-list-item-title class="mb-2">Mes informations</v-list-item-title>
            <v-list-item-subtitle>
              Modifier mes informations personnelles, et relatives à mon activité
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="swapFrame('connexion')">
          <v-list-item-icon class="align-self-center">
            <font-awesome-icon class="text--primary" size="lg" :icon="['fal', 'key']" />
          </v-list-item-icon>
          <v-list-item-content class="align-self-center">
            <v-list-item-title class="mb-2">Mot de passe</v-list-item-title>
            <v-list-item-subtitle>
              Modifier mon mot de passe de connexion
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <user-informations v-if="currentFrame === 'infos'" @go-back="swapFrame('main')" />
      <user-connect v-if="currentFrame === 'connexion'" @go-back="swapFrame('main')" />

      <v-list v-if="currentFrame === 'main'" three-line subheader>
        <v-divider />
        <form-category title="Général" subtitle="Modifier mes préférences de navigation" class="pt-5 px-4" />
        <v-list-item v-feature-flipping="'WELCOME-PANEL'">
          <v-list-item-action class="align-self-center">
            <v-checkbox v-model="displayIntro" false-value="0"
                        true-value="1"
            />
          </v-list-item-action>
          <v-list-item-content class="align-self-center">
            <v-list-item-title class="mb-2">Panneau de bienvenue</v-list-item-title>
            <v-list-item-subtitle>
              Afficher le panneau de bienvenue au lancement de l'application
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-action class="align-self-center">
            <v-checkbox v-model="darkMode" false-value="0"
                        true-value="1"
            />
          </v-list-item-action>
          <v-list-item-content class="align-self-center">
            <v-list-item-title class="mb-2">Activer le mode sombre</v-list-item-title>
            <v-list-item-subtitle>Modifie les couleurs de l'interface afin de réduire la consommation d'énergie ainsi que la fatigue visuelle</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-dialog>
</template>
<script>
  import UserInformations from '../components/UserInformations'
  import UserConnect from '../components/UserConnect'
  import FormCategory from '@/modules/core/layout/FormCategory'

  export default {
    name: 'UserSettings',
    components: {UserConnect, UserInformations, FormCategory},
    data() {
      return {
        darkMode: this.$store.state.userSettings.darkMode,
        displayIntro: this.$store.state.userSettings.displayIntro,
        currentFrame: 'main',
        validFrames: ['main', 'infos', 'connexion'],
        itemsBreadcrumbs: []
      }
    },
    watch: {
      darkMode(value) {
        this.$vuetify.theme.dark = value !== '0'
        document.querySelector('meta[name="theme-color"]').setAttribute('content', this.$vuetify.theme.currentTheme.primary)
        this.$store.commit('userSettings/update', {param: 'darkMode', value: value})
      },
      displayIntro(value) {
        this.$store.commit('userSettings/update', {param: 'displayIntro', value: value})
      }
    },
    methods: {
      close() {
        this.$store.commit('layout/closeSettings')
      },
      swapFrame(type) {
        if(this.validFrames.includes(type)) {
          this.currentFrame = type
        }
      }
    }
  }
</script>
