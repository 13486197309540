/*
 * Copyright (C)  E-Synaps SAS - 2021 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
export const patientsChildrenRoutes = [
  {
    path: 'patient-team',
    name: 'patient-team',
    component: () => import('../network/components/patients/MedicalTeamList'),
    meta: {
      title: 'Mon équipe soignante'
    }
  }
]

export const patientsRoutes = [
  {
    path: '/patient/:uid',
    component: () => import('./views/PatientSection'),
    props: true,
    children: [
      {
        path: '',
        name: 'patient-profile',
        component: () => import('./components/PatientInformations.vue'),
        props: true,
        meta: {
          mobiletitle: 'Fiche patient'
        }
      },
      {
        path: 'timeline',
        name: 'patient-timeline',
        component: () => import('./components/PatientTimeline'),
        meta: {
          mobiletitle: 'Fiche patient / Timeline'
        }
      },
      {
        path: 'documents',
        name: 'patient-documents',
        component: () => import('./components/professionals/PatientDocument'),
        meta: {
          mobiletitle: 'Fiche patient'
        }
      }
    ]
  }
]

