<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->
<template>
  <span :title="localDate">{{ notRelative ? localDate:relativeTime }}</span>
</template>

<script>
  import {randomInt} from '@/utils/random'

  export default {
    name: 'BaseDate',
    props: {
      notRelative: Boolean,
      withoutPrefix: Boolean,
      format: {
        type: String,
        default: 'LLL'
      },
      date: String
    },
    data() {
      let dateObject = this.$dayjs.utc(this.date)
      return {
        dateObject,
        interval: null,
        relativeTime: dateObject.fromNow(this.withoutPrefix)
      }
    },
    computed: {
      localDate() {
        return this.dateObject.local().format(this.format)
      }
    },
    mounted() {
      setTimeout(() => {
        this.interval = setInterval(() => {
          this.relativeTime = this.dateObject.fromNow(this.withoutPrefix)
        }, 60000) // toutes les minutes
      }, randomInt(1000, 60000)) // Pour éviter un gros freeze, on lance le time aléatoirement dans la prochaine minute
    },
    destroyed() {
      if(this.interval) {
        clearInterval(this.interval)
        this.interval = null
      }
    }
  }
</script>
