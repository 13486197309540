<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <input
    ref="input"
    class="h-100 w-100 p-abs c-pointer"
    multiple
    type="file"
    name="file"
    :accept="mimeTypes"
    @change="$emit('upload', $event.target.files)"
  >
</template>

<script>
  import {mimeTypes} from '@/modules/files/helpers'

  export default {
    name: 'FileInput',
    data() {
      return {
        mimeTypes
      }
    },
    methods: {
      click() {
        this.$refs.input.click()
      }
    }
  }
</script>
