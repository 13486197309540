/*
 * Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import Vue from 'vue'
import VueRouter from 'vue-router'
import routerGuard from '@/router/router-guard'
import appRoutes from './app-routes'
import spaceRoutes from './space-routes'
import config from '@/config'
import store from '@/store'
import { retryablePromises } from '@/utils/promise'

Vue.use(VueRouter)

const slug = window.location.host.split('.')[0]

// In property meta of a route, you can add the child property requiredScopes
// requiredScopes can be an array of scopes required to access to the route. One match is sufficient to grant access.
const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({x: 0, y: 0})
      }, 300)
    })
  },
  routes: [
    ...(config.reservedSubdomains.includes(slug) ? appRoutes : spaceRoutes),
    {
      path: '/new-space',
      name: 'space_create',
      component: () => import('../modules/spaces/views/SpaceFormStepper'),
      meta: {
        requiredScopes: ['role_professionals'],
        disableLayout: true
      }
    },
    {
      path: '/welcome',
      name: 'welcome',
      component: () => import('../modules/homepage/views/Welcome'),
      props: true,
      meta: {
        requiredScopes: [],
        disableLayout: true,
        guestOnly: true
      }
    },
    {
      path: '*',
      component: () => import('../modules/core/layout/Error404'),
      meta: {
        disableLayout: true
      }
    }
  ]
})

// Permet de gérer un status loading pendant un changement de route lorsque la cible doit charger des données dans un hook beforeRouteEnter
router.beforeEach((to, from, next)=>{
  if(to.meta.async) {
    router.app.loading = true
  }
  next()
})

// Protège l'acces aux routes en fonction des scopes de l'user courant
// Redirige sur welcome si connecté mais non autorisé ou sur la page de connexion si non connecté
router.beforeEach(routerGuard(router, ['role_professionals', 'role_patients'], (to, from, next) => {
  if (to.name === 'welcome') {
    next()
  } else {
    next({
      name: 'welcome',
      params: {
        target: to.fullPath
      }
    })
  }
}))

// Charge un espace à partir de son slug
router.beforeEach(async (to,from,next)=>{
  const auth = router.app.$auth
  if(config.reservedSubdomains.includes(slug) || !auth.user) {
    next() // Pas de comportement spécifique
  }
  else {
    // Si je tente d'accèder à un espace
    try {
      await retryablePromises([
        () => store.dispatch('core/fetchSpace', slug)
      ], async (e)=>{
        if (e.response && (e.response.status === 403 || e.response.status === 404)) {
          // eslint-disable-next-line no-console
          console.warn(e)
          return false
        }
        // eslint-disable-next-line no-console
        console.error(e)
        return await router.app.$confirm('Une erreur est survenue', 'retry')
      })

      next()
    } catch (e) {
      // Si je ne peux pas y accéder, alors on ouvre la liste des espaces disponibles
      window.location.replace(config.urls.homepage)
    }
  }
})

// Met à jour le titre de la page à partir de l'attribut title de l'objet meta
router.afterEach(to => {
  // Set the page title
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)
  document.title = router.app.__proto__.$appName + (nearestWithTitle ? ' - ' + nearestWithTitle.meta.title : '')

  router.app.loading = false
})

export default router
