import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'


// Duotone Styles
import {
  faCheckCircle as fadCheckCircle,
  faTrash as fadTrash,
  faStream as fadStream,
  faMapMarkerAlt as fadMapMarkerAlt,
  faSearch as fadSearch,
  faBars as fadBars,
  faPlusHexagon as fadPlusHexagon,
  faQuestionSquare as fadQuestionSquare,
  faInfoSquare as fadInfoSquare,
  faExclamationSquare as fadExclamationSquare,
  faThumbsUp as fadThumbsUp,
  faEnvelopeOpen as fadEnvelopeOpen,
  faEnvelope as fadEnvelope,
  faExclamationTriangle as fadExclamationTriangle,
  faUser as fadUser,
  faComments as fadComments,
  faCommentAltDots as fadCommentAltDots,
  faThumbtack as fadThumbtack,
  faShare as fadShare,
  faPencil as fadPencil,
  faIdCardAlt as fadIdCardAlt,
  faFile as fadFile,
  faFilm as fadFilm,
  faImagePolaroid as fadImagePolaroid,
  faChevronDoubleRight as fadChevronDoubleRight,
  faFolderOpen as fadFolderOpen,
  faChartNetwork as fadChartNetwork,
  faPlusCircle as fadPlusCircle,
  faUserPlus as fadUserPlus,
  faBallot as fadBallot,
  faPlus as fadPlus,
  faUserMd as fadUserMd,
  faPhoneAlt as fadPhoneAlt,
  faAt as fadAt,
  faHospitalUser as fadHospitalUser,
  faHomeAlt as fadHomeAlt,
  faLayerGroup as fadLayerGroup,
  faUsers as fadUsers,
  faGlobeEurope as fadGlobeEurope,
  faAddressBook as fadAddressBook,
  faLockAlt as fadLockAlt,
  faUpload as fadUpload,
  faSpinnerThird as fadSpinnerThird,
  faExclamation as fadExclamation
} from '@fortawesome/pro-duotone-svg-icons'

// Light Styles
import {
  faWindowClose as falWindowClose,
  faGlobeEurope as falGlobeEurope,
  faIdCardAlt as falIdCardAlt,
  faLayerPlus as falLayerPlus,
  faCogs as falCogs,
  faCheckDouble as falCheckDouble,
  faTrash as falTrash,
  faUsersClass as falUsersClass,
  faSignOut as falSignOut,
  faPeopleArrows as falPeopleArrows,
  faArrowLeft as falArrowLeft,
  faBooksMedical as falBooksMedical,
  faStethoscope as falStethoscope,
  faPencil as falPencil,
  faEllipsisH as falEllipsisH,
  faCommentPlus as falCommentPlus,
  faUserPlus as falUserPlus,
  faLock as falLock,
  faInfoCircle as falInfoCircle,
  faMinus as falMinus,
  faPlus as falPlus,
  faHistory as falHistory,
  faMapMarkerAlt as falMapMarkerAlt,
  faUser as falUser,
  faFileSignature as falFileSignature,
  faUserMdChat as falUserMdChat,
  faUserEdit as falUserEdit,
  faUserTimes as falUserTimes,
  faAt as falAt,
  faPhone as falPhone,
  faBallot as falBallot,
  faAngleDown as falAngleDown,
  faAddressBook as falBAddressBook,
  faFilesMedical as falFilesMedical,
  faUsersMedical as falUsersMedical,
  faTools,
  faTimes as falTimes,
  faTimesCircle as falTimesCircle,
  faBars as falBars,
  faExchange,
  faSignOut,
  faGlobe as falGlobe,
  faAddressBook as falAddressBook,
  faLockAlt as falLockAlt,
  faChevronLeft as falChevronLeft,
  faChevronRight as falChevronRight,
  faCheck as falCheck,
  faCheckSquare,
  faSquare,
  faCircle,
  faDotCircle,
  faIdCard as falIdCard,
  faKey as falKey,
  faUsers as falUsers,
  faNewspaper as falNewsPaper,
  faPencilPaintbrush,
  faClipboardListCheck,
  faCommentAltLines,
  faFilePlus,
  faExclamationCircle,
  faEdit as falEdit,
  faSearch as falSearch,
  faCommentDots as falCommentDots,
  faBell as falBell,
  faExpandWide as falExpandWide,
  faHdd,
  faDownload as falDownload,
  faCalendarCheck as falCalendarCheck,
  faTags as falTags,
  faShareAll as falShareAll,
  faShare as falShare,
  faFileUser as falFileUser,
  faFileCertificate as falFileCertificate,
  faArrowUp as falArrowUp,
  faArrowDown as falArrowDown,
  faFolderOpen as falFolderOpen,
  faFolderPlus as falFolderPlus
} from '@fortawesome/pro-light-svg-icons'

// Regular Styles
import {
  faBars as farBars,
  faGlobe as farGlobe,
  faAddressBook as farAddressBook,
  faLockAlt as farLockAlt,
  faChevronLeft as farChevronLeft,
  faPaperclip as farPaperClip,
  faEllipsisV as farEllipsisV,
  faFilter as farFilter,
  faWindowMinimize as farWindowMinimize,
  faWindowMaximize as farWindowMaximize,
  faCheckCircle as farCheckCircle,
  faInfoCircle as farInfoCircle,
  faExclamationCircle as farExclamationCircle,
  faTimesCircle as farTimesCircle,
  faAt as farAt,
  faSortAmountUp, faSortAmountDown, faSmilePlus, faThumbtack
} from '@fortawesome/pro-regular-svg-icons'

// Solid Styles
import {
  faAngleDown, faCaretDown, faTimesCircle as fasTimesCircle, faCheck as fasCheck, faCheckCircle as fasCheckCircle,
  faCircle as fasCircle, faPaperPlane as fasPaperPlane, faTimes as fasTimes, faTh as fasTh
} from '@fortawesome/pro-solid-svg-icons'


Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.component('FontAwesomeLayers', FontAwesomeLayers)

library.add(
  fadThumbtack,fadPencil,fadShare,fadCommentAltDots,falCheckDouble,falTrash,falCogs,fadUser,fadExclamationTriangle,fadEnvelopeOpen,fadEnvelope,fadThumbsUp,falWindowClose,
  falPencil, falCommentPlus, falUserPlus, falLock, falMinus,falPlus,fadPlus,falHistory,fadUserMd, fadAt, fadPhoneAlt,fadHospitalUser, fadHomeAlt, fadLayerGroup,
  fadUsers, falCheck, falBallot, falBAddressBook,falInfoCircle,falEllipsisH,falArrowLeft,fadBallot,fadUserPlus,fadImagePolaroid,fadFilm,fadFile,falLayerPlus,
  faAngleDown, falAngleDown, falTimes, falTimesCircle, falBars, farBars, faExchange, faSignOut, faCommentAltLines,fadComments,fadExclamationSquare,fadBars,
  falGlobe, falAddressBook, falLockAlt, fadGlobeEurope, fadAddressBook, fadLockAlt, farGlobe, farAddressBook, farLockAlt,fadIdCardAlt,fadInfoSquare,fadSearch,
  faCaretDown, falChevronLeft, falChevronRight, farChevronLeft, faSquare, faCheckSquare, falIdCard, falKey, falUsers,fadChevronDoubleRight,fadPlusHexagon,
  falNewsPaper, faPencilPaintbrush, faClipboardListCheck, faCircle, faDotCircle, farPaperClip, fasTimesCircle, fadUpload,fadQuestionSquare,fadMapMarkerAlt,fadStream,
  faFilePlus, faExclamationCircle, falAt, falPhone, farEllipsisV, falUserTimes, falUserEdit, falUserMdChat, falFileSignature,falArrowUp,falArrowDown,
  falUser, falMapMarkerAlt, fasCheckCircle, fasCheck, fasCircle, falEdit, farFilter, falSearch, falFilesMedical,fadFolderOpen,falIdCardAlt,fadTrash,
  falUsersMedical, faTools, falCommentDots, falBell, falExpandWide, fasPaperPlane, fasTimes, fadSpinnerThird,fadChartNetwork,falGlobeEurope,
  farWindowMinimize, farWindowMaximize, faSortAmountUp, faSortAmountDown, faHdd, falCalendarCheck, falTags, falShareAll,falSignOut,
  falShare, falFileUser, falFileCertificate,falStethoscope, falBooksMedical, faSmilePlus, faThumbtack, fadExclamation, falPeopleArrows,fadCheckCircle,
  farCheckCircle, farTimesCircle, farInfoCircle, farExclamationCircle, fasTh, farAt, falDownload,fadPlusCircle, falUsersClass,falFolderOpen,falFolderPlus
)


const MSPACE_ICONSET = {
  expand: { component: FontAwesomeIcon, props: { icon: ['fal', 'angle-down']} }, // Nav submenu
  subgroup: { component: FontAwesomeIcon, props: { icon: ['fas', 'angle-down']} },
  dropdown: { component: FontAwesomeIcon, props: { icon: ['fal', 'angle-down']} },
  clear: { component: FontAwesomeIcon, props: { icon: ['fal', 'times']} },
  close: { component: FontAwesomeIcon, props: { icon: ['fal', 'times']} },
  delete: { component: FontAwesomeIcon, props: { icon: ['fal', 'times-circle']} },
  edit: { component: FontAwesomeIcon, props: { icon: ['fal', 'edit']} },
  menu: { component: FontAwesomeIcon, props: { icon: ['far', 'bars']} },
  back: { component: FontAwesomeIcon, props: { icon: ['far', 'chevron-left']} },
  prev: { component: FontAwesomeIcon, props: { icon: ['fal', 'chevron-left']} },
  next: { component: FontAwesomeIcon, props: { icon: ['fal', 'chevron-right']} },
  checkboxOn: { component: FontAwesomeIcon, props: { icon: ['fal', 'check-square']} },
  checkboxOff: { component: FontAwesomeIcon, props: { icon: ['fal', 'square']} },
  radioOn: { component: FontAwesomeIcon, props: { icon: ['fal', 'dot-circle']} },
  radioOff: { component: FontAwesomeIcon, props: { icon: ['fal', 'circle']} },
  comment: { component: FontAwesomeIcon, props: { icon: ['fal', 'comment-alt-lines']} },
  dots: { component: FontAwesomeIcon, props: { icon: ['far', 'ellipsis-v']} },
  filter: { component: FontAwesomeIcon, props: { icon: ['far', 'filter']} },
  pin: { component: FontAwesomeIcon, props: { icon: ['far', 'thumbtack']}},
  success: { component: FontAwesomeIcon, props: { icon: ['far', 'check-circle']}},
  info: { component: FontAwesomeIcon, props: { icon: ['far', 'info-circle']}},
  warning: { component: FontAwesomeIcon, props: { icon: ['far', 'exclamation-circle']}},
  error: { component: FontAwesomeIcon, props: { icon: ['far', 'times-circle']}}
}


export { MSPACE_ICONSET }
