import { Membership, Team} from '@/models'

export default function (user, can, cannot) {
  can('read', Team, team => {
    let membership = team && team.membership ? Membership.get(team.membership) : null
    return membership instanceof Membership && ['member', 'manager', 'owner'].indexOf(membership.elevation) !== -1
  })

  can('leave', Team, team => {
    let membership = team && team.membership ? Membership.get(team.membership) : null
    return membership instanceof Membership && ['member', 'manager'].indexOf(membership.elevation) !== -1
  })

  can(['invite', 'list_invitations'], Team, team => {
    let membership = team && team.membership ? Membership.get(team.membership) : null
    return membership instanceof Membership && ['manager', 'owner'].indexOf(membership.elevation) !== -1
  })

  can(['sign_consent', 'update', 'transfer_ownership', 'close', 'remove_member'], Team, team => {
    let membership = team && team.membership ? Membership.get(team.membership) : null
    return membership instanceof Membership && 'owner' === membership.elevation
  })

  // https://casl.js.org/v4/en/guide/intro#inverted-rules
  // When action and subject of direct and inverted rules are intersects, its order matters:
  // cannot declarations should follow after can, otherwise they will be overridden by can.
  // manage and all are special keywords in CASL. manage represents any action and all represents any subject
  cannot('manage', Team, {status: 'closed'})
}

