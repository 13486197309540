/*
 * Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import api from '@/api/api'
import { feedItemFromApi, feedRowFromApi } from '@/modules/feeds/api/converters'

export async function getModificationHistory(publicationUid) {
  return api.get('/v3/publications/' + publicationUid + '/modification-history')
}

/**
 * @param feedListUid
 * @param message
 * @param open_graph
 * @param visibility: public, contacts, private
 * @param alert
 * @param files
 */
export async function createPublication(feedListUid, message, open_graph, visibility = 'private', alert, files) {
  return feedRowFromApi((await api.post('/v3/feed-lists/' + feedListUid + '/publication', {
    publication: {
      message,
      open_graph,
      visibility,
      alert,
      files
    }
  })).data)
}

export async function updatePublication(publicationUid, data) {
  return feedItemFromApi((await api.put('/v3/publications/' + publicationUid, {
    data
  })).data)
}

export function updateItemVisibility(itemUid, visibility) {
  return api.put('/v3/feed-lists/items/' + itemUid + '/visibility', {
    visibility
  })
}

export function sharePublication(publicationUid, feedListUid, message = null) {
  return api.post(`/v3/publications/${publicationUid}/share/${feedListUid}`, {
    message
  })
}

export function reportPublication(/*publicationUid, reason, detailedReason*/) {

}

export async function listItems(feedListUid, filterSubscribedFeedsUid, beforeDate, skip, limit) {
  return (await api.post('/v3/feed-lists/' + feedListUid + '/items', {
    filters: {
      subscriptions: filterSubscribedFeedsUid
    },
    before_date: beforeDate,
    skip,
    limit
  })).data.filter(Boolean).map(feedRowFromApi) // Retirer les null
}

export function deleteItem(feedListUid, feedItemUid) {
  return api.delete(`/v3/feed-lists/${feedListUid}/items/${feedItemUid}`)
}

export function hideItem(feedListItemUid, feedItemUid) {
  return api.post('/v3/feed-lists/' + feedListItemUid + '/items/' + feedItemUid + '/hide')
}

export function pinItem(feedListUid, feedItemUid) {
  return api.post('/v3/feed-lists/' + feedListUid + '/items/' + feedItemUid + '/pin')
}

export function unpinItem(feedListUid, feedItemUid) {
  return api.post('/v3/feed-lists/' + feedListUid + '/items/' + feedItemUid + '/unpin')
}
