import {FeedItem, Publication} from './index'

export class FeedItemPublicationSharing extends FeedItem {
  static schemaDefinition() {
    return {
      ...super.schemaDefinition(),
      publication: Publication,
      shared_publication: Publication
    }
  }
}
