/*
 * Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import api from '@/api/api'

const commentsPath = '/v3/comments/'

function normalize({uid, author, message, deleted, creation_date, update_date, total_replies}) {
  return {
    uid,
    author,
    message,
    deleted,
    creationDate: creation_date,
    updateDate: update_date,
    total: total_replies
  }
}

export async function getSection(sectionUid) {
  let data = (await api.get('/v3/comments-sections/' + sectionUid)).data

  return {
    uid: data.uid,
    total: data.total_comments,
    totalDirect: data.total_direct_comments
  }
}

export async function list(commentsSectionUid, from, to = 'oldest', limit = 5) {
  let data = (await api.get('/v3/comments-sections/' + commentsSectionUid + '/comments', {
    params: {
      from,
      to,
      limit
    }
  })).data
  data.comments = data.comments.map(normalize)
  return data
}

export async function add(commentsSectionUid, message) {
  if (!window.navigator.onLine) {
    throw new Error('Offline')
  }

  return normalize((await api.post('/v3/comments-sections/' + commentsSectionUid + '/comments', {
    message
  })).data)
}

export async function listReplies(commentUid, from, to = 'newest', limit = 5) {
  let data = (await api.get(commentsPath + commentUid + '/comments', {
    params: {
      from,
      to,
      limit
    }
  })).data

  data.comments = data.comments.map(normalize)
  return data
}

export async function addReply(commentUid, message) {
  if (!window.navigator.onLine) {
    throw new Error('Offline')
  }

  return normalize((await api.post('/v3/comments/' + commentUid + '/comments', {
    message
  })).data)
}


export async function updateMessage(commentUid, message) {
  return normalize((await api.put('/v3/comments/' + commentUid, {
    message
  })).data)
}

export function remove(commentUid) {
  return api.delete(commentsPath + commentUid)
}
