<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-list-item class="px-1" dense two-line :dark="isAuthor" @click="openFile">
    <v-list-item-avatar tile size="30" class="mr-3 my-2">
      <file-mime-type-preview :mime-type="file.mime_type" :size="30" />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>{{ file.name }}</v-list-item-title>
      <v-list-item-subtitle>{{ file.size | prettyBytes }}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
  import FileMimeTypePreview from '@/modules/files/components/FileMimeTypePreview'

  export default {
    name: 'MessageAttachment',
    components: {FileMimeTypePreview},
    props: {
      file: {
        type: Object,
        required: true
      },
      isAuthor: Boolean
    },
    methods: {
      openFile() {
        this.$root.$emit('fileviewer:open', { uid: this.file.uid })
      }
    }
  }
</script>
