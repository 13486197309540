<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-dialog eager value="true" max-width="600" keydown.esc="choose(false)">
    <v-card class="dialog-warning-card">
      <v-card-text class="flex-row d-flex align-center pb-0 px-3">
        <font-awesome-icon class="success--text" size="4x" :icon="['fad', 'question-square']" />
        <v-card-title class="font-alt font-weight-light text-subtitle-1 message">{{ message }}</v-card-title>
      </v-card-text>

      <v-card-actions class="justify-end">
        <template v-if="type === 'retry'">
          <v-btn color="grey" dark x-small rounded @click="choose(false)">Annuler</v-btn>
          <v-btn color="success" x-small rounded @click="choose(true)">Réessayer</v-btn>
        </template>
        <template v-else-if="type === 'info'">
          <v-btn color="primary" x-small rounded @click="choose(null)">Fermer</v-btn>
        </template>
        <template v-else>
          <v-btn color="grey" x-small rounded dark @click="choose(false)">Annuler</v-btn>
          <v-btn color="success" x-small rounded @click="choose(true)">Valider</v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'Confirm',
    props: {
      message: String,
      type: {
        type: String,
        default: 'default'
      }
    },
    data() {
      return {
        value: false
      }
    },
    computed: {},
    methods: {
      choose(value) {
        this.value = value
        this.$destroy()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .dialog-warning-card {
    border-left: 5px solid #94af4c;
    .message {
      word-break: break-word;
    }
  }
</style>
