<template>
  <v-toolbar dense flat color="primary lighten-1" dark max-height="48">
    <messenger-conversation-avatar :participants="conversation.participants" :size="32" class="mr-2" />
    <v-toolbar-title class="subtitle-1">{{ title }}</v-toolbar-title>
    <v-spacer />
    <v-btn v-if="$vuetify.breakpoint.lgAndUp" small icon dark @click="resize">
      <font-awesome-icon :icon="['far', minimized ? 'window-maximize' : 'window-minimize']" />
    </v-btn>
    <v-btn small icon dark @click.stop="closeChat">
      <v-icon small>$close</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
  import MessengerConversationAvatar from '@/modules/messenger/components/MessengerConversationAvatar'

  export default {
    name: 'ConversationHeader',
    components: {MessengerConversationAvatar},
    props: {
      conversation: {
        type: Object,
        required: true
      },
      minimized: {
        type : Boolean
      }
    },
    computed: {
      title() {
        return this.conversation.getTitle() || this.$auth.user.full_name
      }
    },
    methods: {
      closeChat() {
        this.$store.commit('messenger/layout/closeChatWindow', this.conversation.uid)
      },
      resize() {
        this.$store.commit('messenger/layout/resizeChatWindow', this.conversation.uid)
      }
    }
  }
</script>
