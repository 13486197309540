import {normalize} from 'normalizr'
import Vue from 'vue'

export default (models) => {
  let state = {}
  for (let name in models) {
    if (!Object.prototype.hasOwnProperty.call(models, name)) {
      continue
    }
    state[name] = {}
  }
  return {
    namespaced: true,
    state,
    mutations: {
      update(state, {data, schema}) {
        let {entities} = normalize(data, schema)

        // Types d'entités
        Object.keys(entities).forEach((key) => {
          if (typeof state[key] === 'undefined') {
            // throw new Error('Expected model with name '+key)
            Vue.set(state, key, {})
          }

          let model = models[key]
          let realModel = model

          // Entités d'un type
          Object.keys(entities[key]).forEach((id) => {
            let entity = entities[key]
            if (typeof state[key][id] === 'undefined') {
              if (Object.prototype.hasOwnProperty.call(model, 'types')) {
                if (typeof model.types()[entity[id].type] === 'undefined') {
                  throw new Error(`Cannot find type "${entity[id].type}" for model ${model.name}. Available : ${Object.keys(model.types()).join(',')}`)
                }
                realModel = model.types()[entity[id].type]
              }

              Vue.set(state[key], id, new realModel())
            }

            // Propriétés d'une entité
            Object.keys(entity[id]).forEach(property => {
              let value = entity[id][property]
              if(value !== null) {
                // Vue.set(state[key][id], property, value
                Vue.set(state[key][id], property, value && typeof value === 'object' && typeof value.schema !== 'undefined' ? value.id : value)
              }
            })
          })
        })
      },
      delete(state, {schemaName, key}) {
        Vue.delete(state[schemaName], [key])
      },
      apply(state, {schemaName, callback, key}) {
        callback(key ? state[schemaName][key] : state[schemaName])
      }
    }
  }
}
