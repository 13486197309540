import {AbilityBuilder} from '@casl/ability'
import space from '../modules/spaces/abilities'
import feed from '../modules/feeds/abilities'
import team from '../modules/teams/abilities'
import comment from '../modules/comments/abilities'
import establishment from '../modules/establishments/abilities'

const abilities = [space, feed, team, comment, establishment]

/**
 *
 * https://casl.js.org/v4/en/guide/conditions-in-depth#mongo-db-and-its-query-language
 */
export function getRulesFor(user) {
  const {can, cannot, rules} = new AbilityBuilder()

  for (let ability of abilities) {
    ability(user, can, cannot)
  }

  return rules
}
