<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <div class="fill-height">
    <slot name="prepend-right" />
    <space-selector-desktop v-if="$vuetify.breakpoint.lgAndUp" class="space-selector-desktop" />
    <space-selector-mobile v-else />
  </div>
</template>

<script>
  import SpaceSelectorDesktop from '@/modules/spaces/views/SpaceSelectorDesktop'
  import SpaceSelectorMobile from '@/modules/spaces/views/SpaceSelectorMobile'

  export default {
    name: 'SpaceSelector',
    components: {SpaceSelectorMobile, SpaceSelectorDesktop}
  }
</script>
