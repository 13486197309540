import {Person} from '../index'
import {get} from '@/modules/user/api'

export class Patient extends Person {
  static type = 'user:patient'

  static async fetchOne(uid) {
    if(!Person.get(uid)) {
      Person.save((await get(uid, ['patient', 'patient_address'])).data)
    }

    return Person.get(uid)
  }
}
