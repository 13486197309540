/*
 * Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

export function slice(items, index, direction = 'after', limit = 10) {
  let start, end

  if (direction === 'after') {
    start = index + 1
    end = start + limit
  } else if (direction === 'before' && index > 0) {
    start = Math.max(index - limit, 0)
    end = index
  } else {
    return []
  }

  return items.slice(start, end)
}

export function removeItem(items, item) {
  let index
  while ((index = items.indexOf(item)) > -1) {
    items.splice(index, 1)
  }
  return items
}

export function replaceItem(items, oldItem, newItem) {
  let index
  while ((index = items.indexOf(oldItem)) > -1) {
    items.splice(index, 1, newItem)
  }
  return items
}

export function clone(array) {
  return [...array]
}

export function ksort(w) {
  let sArr = []
  let tArr = []
  let n = 0
  for (let i in w) {
    n++
    tArr[n] = i
  }
  tArr.sort()
  n = tArr.length
  for (let i = 0; i < n; i++) {
    sArr[tArr[i]] = w[tArr[i]]
  }
  return sArr
}

export function compare(a, b) {
  // if the other array is a falsy value, return
  if (!Array.isArray(a) || !Array.isArray(b))
    return a === b

  // compare lengths - can save a lot of time
  if (a.length !== b.length)
    return false

  for (let i = 0, l = a.length; i < l; i++) {
    // Check if we have nested arrays
    if (a[i] instanceof Array && b[i] instanceof Array) {
      // recurse into the nested arrays
      if (!compare(!a[i], b[i]))
        return false
    } else if (a[i] !== b[i]) {
      // Warning - two different object instances will never be equal: {x:20} != {x:20}
      return false
    }
  }
  return true
}

export function column(arr, column) {
  return arr.map(x => x ? x[column] : null)
}

// concat without duplicated values
export function merge(a, b) {
  return a.concat(b.filter(item => !a.includes(item)))
}

export function arrayContainsArray(superset, subset) {
  return subset.every((value) => superset.indexOf(value) >= 0)
}

export function concat(arrays) {
  return Array.prototype.concat(...arrays)
}
