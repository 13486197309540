import entities from './entities'
import {schema} from 'normalizr'

const modelSchemas = []

function createSchema(model, models) {
  if (Array.isArray(model)) {
    return [createSchema(model[0], models)]
  }

  if (typeof modelSchemas[model.name] !== 'undefined') {
    return modelSchemas[model.name]
  }

  let modelSchema
  let schemaDefinition = {}
  let modelSchemaDefinition

  if (Object.prototype.hasOwnProperty.call(model, 'types')) {
    modelSchema = new schema.Union({}, function (value/*, parent, key*/) {
      if (typeof value['type'] === 'undefined') {
        throw new Error(`Expected property "type" in data for model "${model.name}"`)
      }

      return value['type']
    })
    modelSchemaDefinition = model.types()
  } else {
    if (!model.schemaName) {
      return false
    }
    modelSchema = new schema.Entity(model.schemaName, {}, {idAttribute: model.primaryKey})
    modelSchemaDefinition = model.schemaDefinition()
  }

  modelSchemas[model.name] = modelSchema

  for (let key in modelSchemaDefinition) {
    if (!Object.prototype.hasOwnProperty.call(modelSchemaDefinition, key)) {
      continue
    }

    let schema = createSchema(modelSchemaDefinition[key], models)
    if(schema) {
      schemaDefinition[key] = schema
    }
  }

  modelSchema.define(schemaDefinition)

  return modelSchema
}

export default {
  create(models, namespace = 'entities') {
    return store => {
      let modelsByName = {}
      for (let model of models) {
        model._namespace = namespace
        model._store = store
        model._schema = createSchema(model, models)

        if (Object.prototype.hasOwnProperty.call(model, 'schemaName') && model.schemaName) {
          modelsByName[model.schemaName] = model
        }
      }

      store.registerModule(namespace, entities(modelsByName))
    }
  }
}
