<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <div class="message-wrapper" :style="isAuthor ? 'justify-content:flex-end;' : 'justify-content:flex-start;'">
    <div class="message">
      <div v-if="showAuthorAvatar" class="message-avatar mr-4">
        <user-avatar v-show="lastOfStreak" size="40" :user="message.author" />
      </div>
      <div class="message-text text-body-2" :class="defineDisplay">
        <template v-if="message.message">{{ message.message }}</template>
        <message-attachment v-if="message.attachment" :file="message.attachment" :is-author="isAuthor" />
      </div>
    </div>
  </div>
</template>

<script>
  import MessageAttachment from '@/modules/messenger/components/messages/MessageAttachment'
  import UserAvatar from '@/modules/core/layout/UserAvatar'

  export default {
    name: 'MessagesListItem',

    components: {UserAvatar, MessageAttachment},

    props: {
      message: {
        type: Object,
        required: true
      },
      firstOfStreak: {
        type: Boolean
      },
      lastOfStreak: {
        type: Boolean
      }
    },
    computed: {
      showAuthorAvatar() {
        return this.message.author && this.message.author.uid && !this.isAuthor
      },
      isAuthor(){
        return this.message.author && this.message.author.uid === this.$auth.user.sub
      },
      defineDisplay() {
        let classes = []
        classes.push(this.isAuthor ? 'mine' : 'his')
        if(this.firstOfStreak && this.lastOfStreak) {
          classes.push('is-solo')
        } else if(!this.firstOfStreak && !this.lastOfStreak) {
          classes.push('is-inner')
        } else if(this.firstOfStreak) {
          classes.push('is-first')
        } else if(this.lastOfStreak) {
          classes.push('is-last')
        }
        return classes.join(' ')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .message-wrapper{
    width: 100%;
    display: flex;

    .message{
      max-width: 80%;
      display: flex;
      flex-direction: row;
      padding-bottom: 1px;
      padding-top: 1px;

      .message-avatar{
        width: 28px;
        min-height: 28px;
        margin: 0 8px;
        display: flex;
        align-items: flex-end;
      }

      .message-text{
        max-width: 100%;
        border-radius: 18px;
        padding: 11px 12px;
        line-height: 1.35;
        background-color: #eee;
        word-break: break-word;
        color: #333;
        &.mine {
          background-color: var(--v-primary-base);
          color: white;
          &.is-first {
            border-bottom-right-radius: 2px;
          }
          &.is-last {
            border-top-right-radius: 2px;
          }
          &.is-inner {
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
          }
        }
        &.his {
          &.is-first {
            border-bottom-left-radius: 2px;
          }
          &.is-last {
            border-top-left-radius: 2px;
          }
          &.is-inner {
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
          }
        }
      }
    }
  }
</style>
