<template>
  <v-card v-if="chatWindow.open" :ref="'chat-'+conversationUid" :height="$vuetify.breakpoint.mdAndDown ? '100%': windowHeight"
          class="d-flex flex-column" :class="$vuetify.breakpoint.mdAndDown ? 'chat-window-mobile' : 'chat-window'"
          :style="$vuetify.breakpoint.mdAndDown ? '' : 'right:'+definePlacement+'px'"
  >
    <conversation-header :conversation="conversation" :minimized="chatWindow.minimized" />
    <messages-list ref="list" :conversation="conversation" />
    <message-input v-if="!chatWindow.minimized" :conversation="conversation" @new="message => $refs.list.addMessage(message)" />
  </v-card>
</template>
<script>
  import MessagesList from '@/modules/messenger/components/messages/MessagesList'
  import MessageInput from '@/modules/messenger/components/messages/MessagesInput'
  import ConversationHeader from '@/modules/messenger/components/windowed/ConversationHeader'
  import {mapState} from 'vuex'
  import {Conversation} from '@/modules/messenger/models'

  export default {
    name: 'MessengerFrame',
    components: {ConversationHeader, MessageInput, MessagesList},
    props: {
      conversationUid: String
    },
    computed: {
      ...mapState('messenger/layout', ['sidebar', 'currentlyDisplayed']),
      chatWindow() {
        return this.$store.getters['messenger/layout/get'](this.conversationUid)
      },
      windowHeight() {
        return this.chatWindow.minimized ? 48 : 550
      },
      conversation() {
        return Conversation.get(this.conversationUid)
      },
      definePlacement() {
        if(this.$vuetify.breakpoint.lgAndUp) {
          let windowIndex = this.currentlyDisplayed.findIndex(chat => chat === this.conversationUid)
          const sidebarWidth = this.sidebar ? 360 : 0
          const gutter = 20
          const frameWidth = 360
          return sidebarWidth + gutter + (windowIndex * (frameWidth + gutter))
        }
        return false
      }
    }
  }
</script>

<style lang="scss" scoped>
.chat-window-mobile {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 20;
  transition: all 0.15s;
}
.chat-window {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 360px;
  z-index: 20;
  transition: all 0.15s;
}
</style>
