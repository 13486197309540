import {getBySlug} from '@/modules/spaces/api'
import {Person, Space} from '@/models'
import * as Users from '@/modules/user/api'
import {ITEM_PROJECTIONS} from '@/models/Gang/Space'

export default {
  namespaced: true,
  state: {
    user: null,
    space: null
  },
  mutations: {
    setUser(state, uid) {
      state.user = uid
    },
    setSpace(state, uid) {
      state.space = uid
    }
  },
  actions: {
    async fetchUser({state, commit}, uid) {
      if(state.user === uid) {
        return
      }
      let {data} = await Users.getMe()
      Person.save(data)
      commit('setUser', data.uid)
    },
    async fetchSpace({state, commit}, slug) {
      if(state.space) {
        return
      }
      let {data} = await getBySlug(slug, ITEM_PROJECTIONS)
      Space.save(data)
      commit('setSpace', data.uid)
    }
  }
}
