import api from '@/api/api'

export function get(establishmentUid) {
  return api.get(`/v2/medunion/establishments/${establishmentUid}`)
}

export function create(data) {
  return api.post('/v2/medunion/establishments', {establishment: data})
}

export function getFeedList(spaceUid, establishmentUid) {
  return api.get(`/v3/spaces/${spaceUid}/establishments/${establishmentUid}/feed-list`)
}

export function isManager(establishmentUid) {
  return api.get('/v2/professionals/medunion/establishments/is-manager', {
    params: {
      establishment: establishmentUid
    }
  })
}

export async function listMembers(establishmentUid, page, limit, manager_only) {
  let {data} = await api.get(`/v2/medunion/establishments/${establishmentUid}/members`, {
    params: {
      page,
      limit,
      manager_only
    }
  })
  return {
    ...data,
    list: data.list.map(row => {
      return {
        ...row,
        uid: row.user.uid,
        establishment: establishmentUid
      }
    })
  }
}

export function removeMember(establishmentUid, professionalUid) {
  return api.delete('/v2/professionals/medunion/establishment/remove-professional', {
    params: {
      establishment: establishmentUid,
      professional: professionalUid
    }
  })
}

export function addMember(establishmentUid, professionalUid) {
  return api.post('/v2/professionals/medunion/establishment/add-professional', {
    establishment: establishmentUid,
    professional: professionalUid
  })
}

export function removeManager(establishmentUid, professionalUid) {
  return api.delete(`/v2/medunion/establishments/${establishmentUid}/manager/${professionalUid}`)
}

export function addManager(establishmentUid, professionalUid) {
  return api.put(`/v2/medunion/establishments/${establishmentUid}/manager/${professionalUid}`)
}
