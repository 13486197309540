<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <div class="mt-11">
    <form-category title="Nouveau thème" subtitle="Création de votre thème personnalisé" class="mt-4" />
    <v-row>
      <v-col cols="6">
        <v-form>
          <v-text-field v-model="model.name" label="Nom du thème" hide-details />
          <v-row class="mt-5">
            <v-col cols="6">
              <v-subheader class="v-subheader theme--light d-flex px-0">
                <div class="primary--text subtitle-2 mr-2">Mode clair </div>
                <v-btn v-if="isLight" x-small rounded color="primary white--text" class="text-uppercase">Activé</v-btn>
                <v-btn v-else outlined x-small rounded @click="TogglePreview">Activer la preview</v-btn>
              </v-subheader>
              <template v-for="(data, color) in colors">
                <v-list-item :key="color" class="pa-0">
                  <v-menu v-model="data.menu.light" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                      <div :style="swatchStyle(data.hex.light)" v-on="on" />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker v-model="data.hex.light" flat show-swatches hide-inputs mode="hexa" />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                  <div class="ml-2 body-2">{{ data.desc }}</div>
                </v-list-item>
              </template>
            </v-col>
            <v-col cols="6">
              <v-subheader class="v-subheader theme--light d-flex px-0">
                <div class="primary--text subtitle-2 mr-2">Mode sombre</div>
                <v-btn v-if="!isLight" x-small rounded color="primary white--text" class="text-uppercase">Activé</v-btn>
                <v-btn v-else x-small rounded outlined @click="TogglePreview">Activer la preview</v-btn>
              </v-subheader>
              <template v-for="(data, color) in colors">
                <v-list-item :key="color" class="pa-0">
                  <v-menu v-model="data.menu.dark" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                      <div :style="swatchStyle(data.hex.dark)" v-on="on" />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker v-model="data.hex.dark" flat show-swatches hide-inputs mode="hexa" />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                  <div class="ml-2 body-2">{{ data.desc }}</div>
                </v-list-item>
              </template>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col cols="6">
        <theme-preview :colors="colors" :is-light="isLight" />
      </v-col>
    </v-row>
    <div class="d-flex justify-center align-center mt-11">
      <v-btn color="success" small class="white--text" :loading="loadingSubmit" @click="submit">Sauvegarder</v-btn>
    </div>
  </div>
</template>

<script>
  import ThemePreview from './ThemePreview'
  import FormCategory from '@/modules/core/layout/FormCategory'
  import * as theme from '@/modules/spaces/api/themes'


  export default {
    name: 'ThemeForm',
    components: {ThemePreview, FormCategory},
    props: {
      spaceUid: String
    },
    data() {
      return {
        loadingSubmit: false,
        isLight: true,
        colors: {
          primary: {
            desc: 'Couleur principale',
            hex: {
              light: '#34465d',
              dark: '#5cb7da'
            },
            menu: {
              light: false,
              dark: false
            }
          },
          secondary: {
            desc: 'Couleur secondaire',
            hex: {
              light: '#3fbae4',
              dark: '#86d1ec'
            },
            menu: {
              light: false,
              dark: false
            }
          },
          garnish: {
            desc: 'Couleur complémentaire',
            hex: {
              light: '#86d1ec',
              dark: '#86d1ec'
            },
            menu: {
              light: false,
              dark: false
            }
          }
        },
        model: {
          name: '',
          data: {
            light: {
              primary: '',
              secondary: '',
              garnish: ''
            },
            dark: {
              primary: '',
              secondary: '',
              garnish: ''
            }
          }
        }
      }
    },
    methods: {
      async submit(){
        this.loadingSubmit = true
        let themeModel = {...this.model}
        themeModel.data.light.primary = this.colors.primary.hex.light
        themeModel.data.light.secondary = this.colors.secondary.hex.light
        themeModel.data.light.garnish = this.colors.garnish.hex.light
        themeModel.data.dark.primary = this.colors.primary.hex.dark
        themeModel.data.dark.secondary = this.colors.secondary.hex.dark
        themeModel.data.dark.garnish = this.colors.garnish.hex.dark
        try {
          let response = await theme.create(this.spaceUid, this.model)
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'success',
            title: 'Thème crée',
            subtitle: 'Thème crée avec succès.'
          })
          this.$emit('add-theme',  response.data)
        } catch (e) {
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue',
            subtitle: 'Une erreur est survenue veuillez réessayer.'
          })
        } finally {
          this.model.name = ''
          this.loadingSubmit = false
        }
      },
      swatchStyle(hex) {
        return {
          backgroundColor: hex,
          cursor: 'pointer',
          height: '30px',
          width: '30px',
          borderRadius: '50%',
          transition: 'border-radius 200ms ease-in-out'
        }
      },

      TogglePreview() {
        this.isLight = !this.isLight
      }
    }
  }
</script>

