import members from '@/modules/user/store/members'
import {Team, Invitation} from '@/models'
import * as Teams from '@/modules/teams/api'
import {column} from '@/utils/array'
import {COLLECTION_PROJECTIONS, ITEM_PROJECTIONS} from '@/models/Gang/Team'

export default {
  namespaced: true,
  modules: {
    members: members()
  },
  state: {
    list: {
      items: null,
      filters: {
        search: null
      }
    }
  },
  getters: {
    // getMemberships: state => uid => {
    //   return state.entities.memberships[uid]
    // },
    // getInvitations: state => uid => {
    //   return state.entities.invitations[uid]
    // },
    list: (state) => {
      return state.list.items.map(uid => Team.get(uid))
    }
  },
  mutations: {
    addListItem(state, itemUid) {
      if (Array.isArray(state.list.items)) {
        state.list.items.unshift(itemUid)
      }
    },
    setListItems(state, itemsUid) {
      state.list.items = itemsUid
    },
    addInvitations(state, {teamUid, invitations}) {
      for (let invitation of invitations) {
        Invitation.save(invitation)
        Team.get(teamUid).invitations.push(invitation.uid)
      }
    }
  },
  actions: {
    async findAll({state, commit, rootState}) {
      if (state.list.items === null) {
        commit('setListItems', [])
        let items = (await Teams.list(rootState.core.space, COLLECTION_PROJECTIONS)).data.list

        Team.save(items.map(item => {
          return {...item, projections: COLLECTION_PROJECTIONS}
        }))
        commit('setListItems', column(items, 'uid'))
      }
    },
    async create({commit, rootState}, data) {
      let team = (await Teams.create({
        ...data,
        space: rootState.core.space
      }, ITEM_PROJECTIONS)).data

      Team.save({...team, projections: ITEM_PROJECTIONS})
      commit('addListItem', team.uid)
      return team
    },
    async findOneMemberships(_, uid) { // TODO move to team model
      let team = Team.get(uid)
      if (!team || 'undefined' === typeof team.memberships) {
        let {data} = await Teams.members.list(uid)
        Team.save({
          uid,
          memberships: data.list
        })
      }
    },
    async findOneInvitations(_, uid) { // TODO move to team model
      let team = Team.get(uid)
      if (!team || 'undefined' === typeof team.invitations) {
        let {data: invitations} = await Teams.invitations.listPending(uid)
        Team.save({
          uid,
          invitations
        })
      }
    }
  }
  // getters: {
  //   listItemByUid(state) {
  //     return uid => state.list.items.find(item => item.uid === uid)
  //   }
  // },
  // mutations: {
  //   setListItems(state, items) {
  //     state.list.items = items
  //   },
  //   setListFiltersSearch(state, search) {
  //     state.list.filters.search = search
  //   },
  //
  //   setListTotal(state, total) {
  //     state.list.total = total
  //   },
  //
  //   addListItem(state, item) {
  //     state.list.items.unshift(item)
  //   },
  //
  //   replaceListItem(state, newItem) {
  //     let index = state.list.items.findIndex(item => item.uid === newItem.uid)
  //     if (index < 0) {
  //       return
  //     }
  //     Vue.set(state.list.items, index, newItem)
  //   },
  //
  //   setCurrent(state, item) {
  //     state.current = item
  //   },
  //
  //   updateItem(state, item) {
  //     if (state.current && state.current.uid === item.uid) {
  //       state.current = item
  //     }
  //
  //     let index = state.list.items.findIndex(listItem => listItem.uid === item.uid)
  //     if (index >= 0) {
  //       Vue.set(state.list.items, index, item)
  //     }
  //   },
  //
  //   addDocument(state, {team, document}) {
  //     team.documents.list.unshift(document)
  //     team.documents.total += 1
  //   },
  //
  //   setRights(state, {teamUid, rights}) {
  //     Vue.set(state.rights, teamUid, rights)
  //   }
  // },
  //
  // actions: {
  //   async find({state, commit}, uid) {
  //     if (state.current && state.current.uid === uid) {
  //       return state.current
  //     }
  //
  //     commit('setCurrent', null)
  //     let item = (await Teams.find(uid)).data
  //     item.isLoaded = true
  //     commit('setCurrent', item)
  //     commit('members/reset')
  //     return item
  //   },
  //
  //   list({commit}) {
  //     return Teams.list().then(({data}) => {
  //       commit('setListItems', data)
  //     })
  //   },
  //
  //   async insert({state, commit, dispatch}, patientFile) {
  //     let item = (await Teams.insert(patientFile)).data.team
  //
  //     if (state.list.filters.search === null || state.list.filters.search === '') {
  //       commit('addListItem', item)
  //     } else {
  //       await dispatch('search', state.list.filters.search)
  //     }
  //
  //     let invitations = []
  //     for (let contactToInvite of patientFile.invitContacts) {
  //       invitations.push(Teams.invitContact(contactToInvite, item.uid))
  //     }
  //     await Promise.race(invitations)
  //
  //     return item
  //   },
  //
  //   async update({state, commit, getters}, {uid, data}) {
  //     let item = (await Teams.update(uid, data)).data.team
  //     commit('updateItem', item)
  //     return item
  //   },
  //
  //   async closeTeam({commit}, patientFile) {
  //     await Teams.closeTeam(patientFile.uid)
  //     patientFile.status = 'closed'
  //     commit('updateItem', patientFile)
  //   },
  //
  //   async fetchMembers({state, commit}, {uid, limit = -1}) {
  //     if(state.members.items) {
  //       return
  //     }
  //     let {list, total} = (await Teams.members.list(uid, state.members.page, limit)).data
  //     commit('members/setItems', list)
  //     commit('members/setTotal', total)
  //     commit('members/incrementPage')
  //   },
  //
  //   /**
  //    * @deprecated
  //    */
  //   async fetchCurrentItemMembers({state, commit}) {
  //     let members = (await Teams.members.list(state.current.uid)).data
  //     commit('updateItem', {
  //       ...state.current,
  //       members
  //     })
  //   },
  //
  //   async fetchCurrentItemDocuments({state, commit, dispatch}) {
  //     let [err, data] = await dispatch('files/searchDocuments', {team: state.current.uid}, {root: true})
  //
  //     if (err) return [err, null]
  //
  //     let documents = {
  //       list: data.list,
  //       total: data.total
  //     }
  //
  //     commit('updateItem', {
  //       ...state.current,
  //       documents
  //     })
  //
  //     return [null, documents]
  //   },
  //
  //   addDocument({state, getters, commit}, {uid, document}) {
  //     let team = getters.get(uid)
  //     if (team && team.documents) {
  //       commit('addDocument', {team, document})
  //     }
  //   },
  //
  //   async checkInvitation({state, commit}, teamUid) {
  //     let invitation = await Teams.hasInvitation(teamUid)
  //     return invitation.data
  //   },
  //
  //   async accept({state, commit}, teamUid) {
  //     let accept = await Teams.accept(teamUid)
  //     return accept.data
  //   },
  //
  //   async decline({state, commit}, teamUid) {
  //     let decline = await Teams.decline(teamUid)
  //     return decline.data
  //   },
  //
  //   async addParticipants({state, commit}, data) {
  //     for (let contactToInvite of data.participants) {
  //       await Teams.invitContact(contactToInvite, data.teamUid).catch(() => {
  //       })
  //     }
  //   },
  //
  //   async fetchRights({state, commit}, teamUid) {
  //     if (typeof state.rights[teamUid] !== 'undefined') {
  //       return state.rights[teamUid]
  //     }
  //
  //     let rights = (await Teams.getRights(teamUid)).data
  //     commit('setRights', {teamUid, rights})
  //     return rights
  //   }
  // }
}
