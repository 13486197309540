<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <div :class="loading ? 'h-100' : 'h-100 d-flex align-center justify-center'">
    <div v-if="error" class="d-flex align-center flex-column h-100 justify-center">
      <div class="text-center mt-2">
        <div class="primary--text text-md-h4 text-h5 font-weight-thin font-alt">
          Le document n'a pas pu être affiché
        </div>
        <div class="op-60 primary--text font-alt text-md-subtitle-1 text-caption">
          Vous pouvez essayer de le télécharger
        </div>
        <v-btn color="primary" small @click="download">Télécharger le document</v-btn>
      </div>
    </div>
    <template v-else>
      <div v-if="loading" class="d-flex align-center flex-column h-100 justify-center">
        <page-loader class="pa-0 page-loader-document" />
        <div class="text-center mt-2">
          <div class="primary--text text-md-h4 text-h5 font-weight-thin font-alt">Chargement en cours.</div>
          <div class="op-60 primary--text font-alt text-md-subtitle-1 text-caption">
            Veuillez patienter votre document est en cours de chargement...
          </div>
        </div>
      </div>
      <div class="w-100 h-100" :class="loading ? 'hidden': null">
        <transition appear name="fade">
          <v-zoomer v-if="isImage" ref="wrapper" style="height: calc(100vh - 64px);" :min-scale="0.8"
                    :zooming-elastic="false"
          >
            <img alt="Mon document" :src="source" class="w-100 h-100" style="object-fit: contain;"
                 :style="[rotateStyleObject]" @load="onLoad" @error="error = true"
            >
          </v-zoomer>
          <video v-else-if="isVideo" class="w-100 h-100" controls @loadeddata="onLoad">
            <source :src="source" type="video/mp4" @error="error = true">
            Your browser does not support the video tag.
          </video>
          <object v-else ref="container" :data="source" :type="mimeType" class="pa-4 ws-pre-wrap"
                  style="object-fit: contain; width: 100%; height: 100%" @load="onLoad" @error="error = true"
          />
        </transition>
      </div>
    </template>
  </div>
</template>

<script>
  import PageLoader from '@/modules/core/layout/PageLoader'

  export default {
    name: 'FileViewerContent',
    components: {PageLoader},
    props: {
      fileUid: String,
      mimeType: {
        type: String,
        required: true
      },
      source: {
        type: String,
        required: true
      },
      isImage: Boolean,
      isVideo: Boolean,
      rotate: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        loading: true,
        error: false,
        visible: true
      }
    },
    computed: {
      rotateStyleObject() {
        if (this.rotate !== 0) {
          const value = `rotate(${this.rotate}deg)`
          return {
            webkitTransform: value,
            mozTransform: value,
            msTransform: value,
            oTransform: value,
            transform: value
          }
        }
        return {}
      }
    },
    methods: {
      onLoad() {
        if (this.$refs.wrapper) {
          this.$refs.wrapper.zoomOut()
        }
        this.loading = false
      },
      retry() {
        this.error = false
        this.visible = false
        this.$nextTick(() => {
          this.visible = true
        })
      },
      async download() {
        let accessToken = await this.$auth.getTokenSilently()
        window.open(`${process.env.VUE_APP_URL_API}/v3/files/${this.fileUid}/download?access_token=${accessToken}`, '_blank')
      }
    }
  }
</script>
<style lang="scss" scoped>
  .page-loader-document {
    height: auto !important;
  }

  .hidden {
    width: 0;
    height: 0;
    overflow: hidden;
  }

  video {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    background-color: #000;

    source {
      padding-bottom: 50px;
    }
  }
</style>
