<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-text-field v-model="term" placeholder="Rechercher" filled dense rounded clearable :loading="searching" />
</template>

<script>
  import {debounce} from 'throttle-debounce'
  import {empty} from 'esyn-utils/src/utils/variables'
  import {getMessengerConversationsParticipantsSuggestions} from '@/modules/spaces/api'

  export default {
    name: 'ConversationsSearchInput',
    data() {
      return {
        term: null,
        searching: false
      }
    },
    watch: {
      ['term']: debounce(500, async function (value) {
        if (empty(value)) {
          this.$emit('input', null)
          return
        }

        try {
          this.searching = true
          let {data} = await getMessengerConversationsParticipantsSuggestions(this.$store.state.core.space, value)

          this.$emit('input', data)
        } catch (e) {
          this.$handleApiError(e)
        } finally {
          this.searching = false
        }
      })
    },
    methods: {
      clear() {
        this.term = null
      }
    }
  }
</script>
