import {Model, Person, CommentsSection} from './index'
import * as Comments from '@/modules/comments/api'
import {removeItem} from '@/utils/array'

export class Comment extends Model {
  static schemaName = 'comments'

  static schemaDefinition() {
    return {
      section: CommentsSection,
      author: Person,
      comments: [Comment]
    }
  }

  constructor() {
    super()

    this.total = 0
    this.comments = []
  }

  async loadComments() {
    let last = this.comments[this.comments.length - 1]
    let date = typeof last !== 'undefined' ? Comment.get(last).creationDate : null
    let {comments: replies, total} = await Comments.listReplies(this.uid, date, 'oldest')

    Comment.save(replies.map(reply => {
      return {...reply, section: this.section}
    }))

    Comment.mutate(comments => {
      for (let reply of replies) {
        if (comments[this.uid].comments.indexOf(reply.uid) === -1) {
          comments[this.uid].comments.push(reply.uid)
        }
      }
    })

    return total - replies.length
  }

  async reply(message) {
    let reply = await Comments.addReply(this.uid, message)
    reply.section = this.section

    Comment.save(reply)
    CommentsSection.get(this.section).incrementTotal()
    Comment.mutate(comments => {
      ++comments[this.uid].total
      comments[this.uid].comments.unshift(reply.uid)
    })
  }

  async remove() {
    await Comments.remove(this.uid)

    Comment.mutate(comments => {
      comments[this.uid].message = null
      comments[this.uid].deleted = true
    })
  }

  removeReply(reply) {
    Comment.mutate(comments => {
      removeItem(comments[this.uid].comments, reply.uid)
      --comments[this.uid].total
    })

    CommentsSection.get(this.section).decrementTotal()

    if(this.total === 0 && this.deleted) {
        CommentsSection.get(this.section).removeComment(this)
    }
  }

  async update(message) {
    Comment.save(await Comments.updateMessage(this.uid, message))
  }
}
