import api from '@/api/api'

export function list() {
  return api.get('/v3/spaces-themes')
}
export function mylist(space) {
  return api.get(`/v3/spaces/${space}/themes`)
}
export function active(space, theme) {
  return api.post(`/v3/spaces/${space}/themes/${theme}`)
}

export function create(uid, data) {
  return api.post('/v3/spaces-themes', {
    space: uid,
    theme: data
  })
}

export function remove(theme) {
  return api.delete(`/v3/spaces-themes/${theme}`)
}

