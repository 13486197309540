import {patientsChildrenRoutes, patientsRoutes} from '@/modules/patients/routes'
import filesRoutes from '@/modules/files/routes'
import {professionnalsRoutes} from '@/modules/professionals/routes'
import messengerRoutes from '@/modules/messenger/routes'
import networkRoutes from '@/modules/network/routes'
import establishmentsRoutes from '@/modules/establishments/routes'
import {teamsProfilRoutes} from '@/modules/teams/routes'

export default [
  {
    path: '/',
    component: () => import('../modules/user/views/UserSection'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('../modules/user/components/UserFeed'),
        meta: {
          title: 'Mes publications',
          mobiletitle: 'Gérer mon compte'
        }
      },
      {
        path: '/timeline',
        name: 'my-timeline',
        component: () => import('../modules/patients/components/PatientTimeline'),
        meta: {
          mobiletitle: 'Gérer mon compte'
        }
      },
      // {
      //   path: '/notifications',
      //   name: 'my-notifications',
      //   component: () => import('../modules/notifications/views/NotificationsView'),
      //   meta: {
      //     mobiletitle: 'Gérer mon compte'
      //   }
      // },
      {
        path: 'invitations',
        name: 'space-invitations',
        component: () => import('../modules/spaces/views/SpaceInvitationsList'),
        meta: {
          mobiletitle: 'Gérer mon compte'
        }
      },
      ...patientsChildrenRoutes
    ]
  },
  ...patientsRoutes,
  ...professionnalsRoutes,

  /** parti recherche */
  {
    path: '/search',
    name: 'search',
    component: () => import('../modules/search/views/Search'),
    meta: {
      title: 'Recherche'
    }
  },

  /* partie Autres */
  {
    path: '/covid-form',
    name: 'covid-form',
    component: () => import('../modules/features/covidform/views/CovidForm'),
    meta: {
      title: 'Formulaire COVID',
      featureFlipping: {
        key: 'COVID-FORM'
      }
    }
  },
  {
    path: '/mediateam/:target',
    name: 'mediateam',
    component: () => import('../modules/features/mediateam/views/MediateamWrapper'),
    props: true,
    meta: {
      featureFlipping: {
        key: 'MEDIATEAM'
      }
    }
  },
  ...filesRoutes,
  ...messengerRoutes,
  ...networkRoutes,
  ...establishmentsRoutes,
  ...teamsProfilRoutes
]
