<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-menu v-model="notificationsDropdown" transition="scroll-y-transition" offset-y :close-on-content-click="false">
    <template #activator="{ on: onMenu }">
      <v-tooltip bottom>
        <template #activator="{ on: onTooltip }">
          <v-badge :content="unreadTotal" :value="unreadTotal" overlap color="white grey--text" top offset-y="20px"
                   offset-x="20px"
          >
            <v-btn icon depressed dark small class="mx-1 pa-5" v-on="{ ...onMenu, ...onTooltip }" @click="toggleNotificationsPanel">
              <font-awesome-icon :icon="['fal', 'bell']" size="2x" />
            </v-btn>
          </v-badge>
        </template>
        <span>Mes notifications</span>
      </v-tooltip>
    </template>
    <notifications-desktop />
  </v-menu>
</template>

<script>
  import NotificationsDesktop from '@/modules/notifications/components/NotificationsDropdown'
  import {mapActions, mapMutations, mapState} from 'vuex'
  import * as Notifications from '@/api/notifications'

  export default {
    name: 'Notifications',
    components: {NotificationsDesktop},
    data() {
      return {
        notificationsDropdown: false
      }
    },
    computed: {
      ...mapState('notifications', ['unreadTotal', 'panelOpen'])
    },
    watch: {
      notificationsDropdown(val) {
        if (!val && this.panelOpen) {
          this.$store.commit('notifications/setPanelOpen', false)
        }
      }
    },
    mounted() {
      this.fetchUnreadTotal()
      this.$node.socket.on('new_notification', (notification) => {
        if ('vibrate' in navigator) {
          navigator.vibrate(1000)
        }
        this.incrementUnreadTotal()
        this.prependItem(notification)

        if (this.panelOpen) {
          Notifications.marAsRead(notification.id)
        }
      })
    },
    methods: {
      ...mapMutations('notifications', ['incrementUnreadTotal', 'prependItem']),
      ...mapActions('notifications', ['fetchUnreadTotal']),
      toggleNotificationsPanel() {
        this.$store.commit('notifications/setPanelOpen', !this.panelOpen)
      }
    }
  }
</script>
