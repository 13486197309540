<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-img :alt="user.name" :src="picture" />
</template>

<script>
  import placeholder from '@/assets/img/profile/user-generic.png'
  import placeholderDark from '@/assets/img/profile/user-generic-dark.png'
  import config from '@/config'

  /**
   * Wrapper de l'avatar d'un utilisateur
   */
  export default {
    name: 'UserAvatarImg',
    props: {
      user: Object
    },
    data() {
      return {
        picture: this.$vuetify.theme.dark ? placeholderDark : placeholder // Image utilisée pendant le chargement ce l'avatar de l'utilisateur
      }
    },
    mounted() {
      // On récupère l'avatar de l'utilisateur avec l'API Image plutôt qu'xhr pour éviter de poluer la console
      let img = new Image()
      // Pour les utilisateurs retournés par l'api et venant d'elasticsearch, il n'y a pas de propriété picture. Il faut alors la construire avec l'uid
      let picture = this.user.picture || config.urls.auth + '/user/picture/' + this.user.uid

      img.src = picture + '?no-fallback'
      img.onload = () => {
        this.picture = picture + '?no-fallback'
      }
      img.onerror = () => {
        this.picture = this.$vuetify.theme.dark ? placeholderDark : placeholder // Image utilisée en cas d'erreur (l'utilisateur n'a pas d'avatar défini)
      }
    }
  }
</script>
