/*
 * Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

// https://github.com/championswimmer/vuex-persist
import VuexPersist from 'vuex-persist'

export default new VuexPersist({
  strictMode: process.env.NODE_ENV !== 'production',
  storage: localStorage,
  restoreState(key, storage) {
    if (window.navigator.onLine) {
      console.log('Navigator is online. Cache was not restored.')
      return null
    }

    console.log('Navigator is offline. Cache was restored from storage.')
    return JSON.parse(storage.getItem(key))
  },
  saveState(key, state, storage) {
    storage.setItem(key, JSON.stringify(state))
  },
  reducer: (state) => {
    return {
      auth: state.auth,
    }
  }
})
