/*
 * Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import {removeItem} from '@/utils/array'
import {randomIdentifier} from '@/utils/random'

export default {
  namespaced: true,
  state: {
    snackbars: [],
    searchResults: [],
    header: true,
    menuOpen: false,
    inMessenger: false,
    backButton: false,
    footer: true,
    footerButton: false,
    sideDrawer: false,
    searchActive: false,
    searchValue: '',
    callbackAddButton: null,
    callbackCloseButton: null,
    bottomBar: true,
    isMobile: false,
    settingsPanel: false,
    spaceSettingsPanel: false,
    spaceSelector: false,
    haveModal: false,
    confirmSnackbar: false,
    menuDrawerMobile: false
  },
  getters: {
    isSearchPending(state) {
      return state.searchResults.length > 0 || state.searchValue !== ''
    }
  },
  mutations: {
    openSnackbar(state, props) {
      props.id = randomIdentifier()
      state.snackbars.push(props)
    },
    removeSnackbar(state, snackbar) {
      removeItem(state.snackbars, snackbar)
    },
    setSearchActive(state, boolean) {
      state.searchActive = boolean
    },
    setCallbackAddButton(state, callback) {
      state.callbackAddButton = callback
    },
    setCallbackCloseButton(state, callback) {
      state.callbackCloseButton = callback
    },
    showDrawer(state) {
      state.sideDrawer = true
    },
    hideDrawer(state) {
      state.sideDrawer = false
    },
    enterMessenger(state) {
      state.inMessenger = true
    },
    leaveMessenger(state) {
      state.inMessenger = false
    },
    hideHeader(state) {
      state.header = false
    },
    showHeader(state) {
      state.header = true
    },
    displayBackButton(state) {
      state.backButton = true
    },
    hideBackButton(state) {
      state.backButton = false
    },
    addFooterButton(state) {
      state.footerButton = true
    },
    removeFooterButton(state) {
      state.footerButton = false
    },
    incrementNotificationsUnread(state) {
      ++state.notificationsUnread
    },
    resetNotificationsUnread(state) {
      state.notificationsUnread = 0
    },
    openMenu(state) {
      state.menuOpen = true
    },
    hideMenu(state) {
      state.menuOpen = false
    },
    hideBottomBar(state){
      state.bottomBar = false
    },
    displayBottomBar(state){
      state.bottomBar = true
    },
    setIsMobile(state, boolean){
      state.isMobile = boolean
    },
    openSettings(state) {
      state.settingsPanel = true
    },
    closeSettings(state) {
      state.settingsPanel = false
    },
    openSpaceSettings(state) {
      state.spaceSettingsPanel = true
    },
    closeSpaceSettings(state) {
      state.spaceSettingsPanel = false
    },
    toggleSpaceSelector(state) {
      state.spaceSelector = !state.spaceSelector
    },
    toggleModal(state, value) {
      state.haveModal = value
    },
    toggleConfirmSnackbar(state, value) {
      state.confirmSnackbar = value
    },
    setDrawerMobile(state, value) {
      state.menuDrawerMobile = value
    }
  },
  actions: {}
}
