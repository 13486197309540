/*
 * Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import api from './api'

export function getUnreadTotal() {
  return api.get('/v3/notifications/unread-total')
}

export function cget(page, limit) {
  return api.get('/v3/notifications', {params: {page, limit}})
}

export function marAsRead(notificationId) {
  return api.put('/v3/notifications/'+notificationId+'/mark-as-read')
}

export function removeAll() {
  return api.delete('/v3/notifications')
}

// @deprecated
export function getMediateam() {
  return api.get('/v3/notifications/mediateam')
}
