import {Model, Person} from '@/models'

export class Message extends Model{
  static schemaName = 'messages'
  static primaryKey = 'id'

  static schemaDefinition() {
    return {
      id: Number,
      author: Person,
      message: String,
      creation_date: String,
      attachment: Object,
      message_read_flag: Array
    }
  }

}
