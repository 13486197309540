/*
 * <!--Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential-->
 */

import api from '@/api/api'

export function list(page = 1, limit = 10) {
  return api.get('/v2/messenger/conversations', {
    params: {
      page,
      limit
    }
  })
}

export function getByParticipant(userUid) {
  return api.get(`/v2/messenger/conversations/participant/${userUid}`)
}

export function get(conversationUid) {
  return api.get(`/v2/messenger/conversations/${conversationUid}`)
}

export function create(model) {
  return api.post('/v2/messenger/conversations', {
    conversation_type: model
  })
}

export function addParticipants(conversationUid, participants) {
  return api.put(`/v2/messenger/conversations/${conversationUid}/add-participants`, {
    participants_type: {participants}
  })
}

export function removeParticipant(conversationUid, participant) {
  return api.put(`/v2/messenger/conversations/${conversationUid}/remove-participants`, {
    participants_type: {
      participants: [participant]
    }
  })
}

export function fetchFileList(conversationUid) {
  return api.get(`/v2/messenger/conversations/${conversationUid}/file-list`)
}

export function getUnreadTotal() {
  return api.get('/v2/messenger/conversations/unread-total')
}
