/*
 * Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { empty } from '@/utils/variables'
import { getInstance as getAuthInstance } from '@/plugins/auth'

async function executeHook(to, from, next, defaultRequiredScopes, accessDeniedHandler) {
  const auth = getAuthInstance()

  if (auth.sessionExpired) {
    auth.logout()
    return
  }

  let requiredScopes = defaultRequiredScopes

  if (to.meta && typeof to.meta['requiredScopes'] !== 'undefined') {
    requiredScopes = to.meta['requiredScopes']
  }

  if (!empty(requiredScopes)) {
    // Routes requiring connected user
    if (!auth.authenticated) {
      auth.loginWithRedirect({ appState: { targetUrl: to.fullPath } })
    } else if (requiredScopes.filter(scope => auth.scopes.includes(scope)).length === 0) {
      // Access denied
      // eslint-disable-next-line no-console
      console.warn('Access denied. Scopes required : ', requiredScopes, 'Scopes granted : ', auth.scopes)
      if (typeof accessDeniedHandler === 'function') {
        accessDeniedHandler(to, from, next)
      } else {
        next({path: '/'})
      }
    } else {
      next()
    }
  } else {
    next()
  }
}

// MUST NOT be asynchronous
export default (router, defaultRequiredScopes = [], accessDeniedHandler = null) => async (to, from, next) => {
  const auth = getAuthInstance()

  if (!auth.loading) {
    await executeHook(to, from, next, defaultRequiredScopes, accessDeniedHandler)
    return
  }

  auth.$watch('loading', async loading => {
    if (!loading && !auth.error) {
      await executeHook(to, from, next, defaultRequiredScopes, accessDeniedHandler)
    }
  })
}
