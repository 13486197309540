<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-container fluid>
    <v-form ref="form" @submit.prevent="submit">
      <v-list>
        <template v-for="(feature, index) in features">
          <v-list-item :key="index">
            <v-list-item-action class="align-self-center">
              <v-checkbox v-model="selectedFeatures" :value="feature.canonical" />
            </v-list-item-action>
            <v-list-item-content class="align-self-center">
              <v-list-item-title class="mb-2">{{ feature.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ feature.description }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <div class="my-3 text-center">
        <v-btn small @click="goBack">Retour</v-btn>
        <v-btn type="submit" small class="ml-2" color="primary">Enregistrer</v-btn>
      </div>
    </v-form>
  </v-container>
</template>

<script>
  import {features} from '@/mixins/features/features'
  import {setEnabledFeatures} from 'vue-feature-flipping'

  export default {
    name: 'FeaturesSettings',
    data() {
      return {
        features,
        selectedFeatures: []
      }
    },
    created() {
      this.selectedFeatures = this.$store.state.features.features
    },
    methods: {
      /**
       * Back to the previous menu item
       */
      goBack() {
        this.$emit('goBack')
      },

      /**
       * Submit the feature selection form and refresh the page
       * @returns {Promise<void>}
       */
      async submit(){
        setEnabledFeatures(this.selectedFeatures)
        this.$store.commit('features/setFeatures', this.selectedFeatures)
        localStorage.setItem('settings:features', JSON.stringify(this.selectedFeatures))
        this.$root.$emit('snackbar:open', {
          snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
          type: 'success',
          title: 'Fonctionnalités sauvegardées',
          subtitle: 'Fonctionnalités sauvegardées avec succès..'
        })
        setTimeout(() => {
          window.location.reload()
          return false
        }, 2000)
      }
    }
  }
</script>

