<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-input ref="input" v-model="value2" :error-messages="error ? 'Champ invalide' : null" v-bind="$attrs" class="phone-number-input" v-on="$listeners">
    <div class="w-100">
      <vue-tel-input :value="value || ''" placeholder="Téléphone" :default-country="countryMaroc ? 'MA' : 'FR'"
                     enabled-country-code disabled-fetching-country
                     :only-countries="countryMaroc ? ['MA'] : ['FR']" @input="change"
                     @validate="validate" @blur="error = !isValid && !!value"
      />
    </div>
  </v-input>
</template>

<script>
  import {VueTelInput} from 'vue-tel-input'
  import {empty} from '@/utils/variables'

  export default {
    name : 'PhoneInput',
    components: {
      VueTelInput
    },
    props: {
      value: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        isValid: true,
        error: false,
        countryMaroc: false
      }
    },
    created() {
      this.checkISMarocain()
    },
    mounted() {
      // TODO : FIX THAT SHIT
      this.value2 = this.value
    },
    methods: {
      checkISMarocain() {
        if(window.location.toString().includes('isticharadoc'))
        {
          this.countryMaroc = true
        }
      },
      returnValid() {
        return this.isValid
      },
      validate({isValid}) {
        if (!empty(this.value)) {
          this.isValid = isValid
        }
      },
      change(value, {number, isValid}) {
        this.error = false

        let numberValue = number.input
        if(number.international) {
          numberValue = number.international.replace(new RegExp(' ','g'),'')
        }
        this.isValid = isValid
        this.$emit('input', numberValue)
        this.$refs.input.$emit('change', numberValue)
      }
    }
  }
</script>

