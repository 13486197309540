<!--
  - Copyright (C)  E-Synaps SAS - 2021 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-container class="fill-height">
    <v-row class="flex-column align-center ma-0">
      <div v-if="$auth.error" class="mt-2 text-center">
        <div class="my-3">
          Une erreur est survenue
        </div>
        <div>
          <v-btn color="success" small @click="() => $auth.loginWithRedirect()">Réessayer</v-btn>
        </div>
      </div>
      <div v-else class="bg">
        <img alt="loading" src="@/assets/img/applogo/mspace-logo-outer.png">
        <div class="dot-wrapper">
          <div class="dot-flashing" :class="$vuetify.theme.dark ? 'clear' : ''" />
        </div>
      </div>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'PageLoader'
  }
</script>

<style lang="scss" scoped>
$dot-main-color : #34465d;
$dot-sub-color : #c9c9d2;
$circle-size : 130px;
$dot-size: 20px;

.bg {
  position: relative;
  img {
    width: $circle-size;
    height: $circle-size;
  }
}
.dot-wrapper {
  position: absolute;
  top: 55px;
  left: 80px;

  .dot-flashing {
    position: relative;
    width: $dot-size;
    height: $dot-size;
    border-radius: 50px;
    background-color: $dot-main-color;
    color: $dot-main-color;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: .5s;

    &:before, &:after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
      width: $dot-size;
      height: $dot-size;
      border-radius: 55px;
      background-color: $dot-main-color;
      color: $dot-main-color;
      animation: dotFlashing 1s infinite alternate;
    }

    &:before {
      left: -30px;
      animation-delay: 0s;
    }

    &:after {
      left: 30px;
      animation-delay: 1s;
    }
  }
}


@keyframes dotFlashing {
  0% {
    background-color: $dot-main-color;
  }
  50%,
  100% {
    background-color: $dot-sub-color;
  }
}
</style>
