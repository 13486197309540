/*
 * <!--Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential-->
 */

import {Model, Professional, Patient, FileType} from '@/models'
import {get} from '@/modules/files/api'
import {empty} from '@/utils/variables'

export class File extends Model {
  static schemaName = 'files'

  static model = {
    name: null,
    keywords: [],
    description: '',
    file_type: '',
    author: '',
    on: ''
  }

  static schemaDefinition() {
    return {
      file_type: FileType,
      uploader: Professional,
      author: Professional,
      patient: Patient
    }
  }

  static async fetchOne(uid) {
    let file = File.get(uid)
    if (!file || empty(file.uploader)) {
      let data = (await get(uid)).data
      File.save(data)
      file = File.get(uid)
    }
    return file
  }
}
