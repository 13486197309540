<template>
  <div class="py-5 elevation-x c-pointer space fill-height p-rel" :class="isActive ? 'white' : 'grey lighten-5'"
       @click.stop="redirect(space.slug)"
  >
    <div class="text-center text-subtitle-1 text-md-h6 font-alt primary--text">{{ space.name }}</div>
    <div class="text-center text-caption text-md-body-2 font-weight-light font-alt">{{ space.slug }}.medicalspace.fr</div>

    <v-chip v-if="isActive" x-small dark color="success lighten-1" class="active-space-chips">Actif</v-chip>
    <template v-if="invitation && invitation.status === 'pending'" class="d-flex justify-center">
      <div class="d-flex justify-center mt-2">
        <v-tooltip top>
          <!-- Le tooltip ne fonctionne pas, il est sous la carte-->
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon class="mr-2" v-bind="attrs" :loading="acceptInvitationLoading" :disabled="acceptInvitationLoading"
                   v-on="on" @click.stop="acceptInvitation"
            >
              <font-awesome-icon :icon="['fas', 'check']" size="1x" class="success--text" />
            </v-btn>
          </template>
          <span>Accepter l'invitation</span>
        </v-tooltip>

        <v-tooltip top>
          <!-- Le tooltip ne fonctionne pas, il est sous la carte-->
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" :loading="refuseInvitationLoading" :disabled="refuseInvitationLoading" v-on="on"
                   @click.stop="declineInvitation"
            >
              <font-awesome-icon :icon="['fas', 'times']" size="1x" class="red--text" />
            </v-btn>
          </template>
          <span>Décliner l'invitation</span>
        </v-tooltip>
      </div>
    </template>
  </div>
</template>

<script>
  import config from '@/config'
  import {Invitation, Space} from '@/models'
  import {redirect} from '@/services/space'

  export default {
    name: 'SpaceSelectorDialogItem',
    props: {
      uid: String
    },
    data() {
      return {
        acceptInvitationLoading: false,
        refuseInvitationLoading: false
      }
    },
    computed: {
      space() {
        return Space.get(this.uid)
      },
      invitation() {
        return Invitation.get(this.space.invitation)
      },
      isActive() {
        return this.$store.state.core.space && this.uid === this.$store.state.core.space
      }
    },
    methods: {
      redirect,
      redirectBackOffice(space) {
        window.location = `${config.urls.backOffice}?space=${space}`
      },

      async acceptInvitation() {
        try {
          this.acceptInvitationLoading = true
          await this.invitation.accept()
        } catch (e) {
          this.$handleApiError(e)
        } finally {
          this.acceptInvitationLoading = false
        }
      },
      async declineInvitation() {
        try {
          this.refuseInvitationLoading = true
          await this.invitation.decline()
          this.$emit('decline-invitation')
        } catch (e) {
          this.$handleApiError(e)
        } finally {
          this.refuseInvitationLoading = false
        }
      }
    }
  }
</script>
