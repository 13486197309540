export default function () {
  return {
    namespaced: true,
    state: {
      items: [],
      page: 0,
      total: 0
    },
    mutations:{
      reset(state) {
        state.items = []
        state.page = 0
        state.total = 0
      },
      setItems(state, items) {
        state.items = items
      },
      setPage(state, page) {
        state.page = page
      },
      setTotal(state, total) {
        state.total = total
      },
      incrementPage(state) {
        ++state.page
      }
    }
  }
}
