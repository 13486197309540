import api from '@/api/api'

export function list(feedListUid) {
  return api.get(`/v3/feed-list/${feedListUid}/subscriptions`)
}

export function add(feedListUid, feedUid) {
  return api.post(`/v3/feed-list/${feedListUid}/subscriptions/${feedUid}`)
}

export function remove(feedListUid, feedUid) {
  return api.delete(`/v3/feed-list/${feedListUid}/subscriptions/${feedUid}`)
}
