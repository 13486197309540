import api from '@/api/api'

export function find(uid) {
  return api.get('/v2/patients/' + uid)
}

export function list(filters = {}, {page = 1, limit = 10, order = {}} = {}) {
  return api.post('/v3/users/search', {page, limit, order, filters, projections: ['patient_address']})
}

export function create(data) {
  return api.post('/v2/patients', {
    patient: data,
    medunion: true //Set "medunion" to boolean cause if not, a plain password is asked(registration).
  })
}

export function update(uid, data) {
  return api.put('/v2/patients/' + uid, {
    patient: data
  })
}

export function remove(patientUid, professionalUid) {
  return api.delete(`/v2/patients/${patientUid}/professionals/${professionalUid}`)
}

export function add(patientUid, professionalUid) {
  return api.post(`/v2/patients/${patientUid}/professionals/${professionalUid}`)
}
