/*
 * <!--Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential-->
 */

import api from '../../api/api'

export function fetch(on, date, skip = 0, limit = 15) {
  if (!on) {
    throw new Error('Missing "on" param.')
  }

  return api.post('/v3/files/search', {request: {on, date, limit, offset: skip}})
}

export function get(uid) {
  return api.get('/v3/files/' + uid)
}

export function post(data) {
  return api.post('/v3/files', {file_meta_data: data})
}

export function edit(uid, data) {
  return api.put(`/v3/files/${uid}`, {data})
}

export function upload(url, file, onProgress) {
  const form = new FormData()
  form.append('file', file)

  return api.post(url, form, {
    onUploadProgress(e) {
      if(typeof onProgress ==='function') {
        onProgress(Math.round(e.loaded / e.total * 100))
      }
    }
  })
}

export function share(uid, users) {
  return api.post('/v3/files/' + uid + '/share', {users})
}

export function getContent (uid) {
  return api.get(`/v3/files/${uid}/view`)
}

export async function postAndUpload(file, meta = {}, onProgress = null) {
  let url = (await post(meta)).data.upload_url
  return (await upload(url, file, onProgress)).data
}
