/*
 * Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import fr from 'vuetify/es5/locale/fr'
import { MSPACE_ICONSET } from './fontawesome'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#34465d',
        secondary: '#65c5a5',
        accent: '#8c9eff',
        dark: '#333',
        error: '#b73636',
        success: '#94af4c',
        environment: '#b7c8d2',
        garnish: '#c9c9d2',
        headers: '#0f536a',
        actions: '#45aed1'
      },
      dark: {
        primary: '#6180a7',
        secondary: '#81E1C0',
        environment: '#3fbae4',
        garnish: '#86d1ec'
      }
    }
  },
  lang: {
    locales: { fr },
    current: 'fr'
  },
  icons: {
    iconfont: 'faSvg',
    values: MSPACE_ICONSET
  }
})
