/*
 * Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Auth0Client } from '@auth0/auth0-spa-js'
import qs from 'qs'
import Vue from 'vue'

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

let instance

export function getInstance() {
  return instance
}

function createInstance(options) {
  let onRedirectCallback = options.onRedirectCallback || DEFAULT_REDIRECT_CALLBACK
  let redirectUri = options.redirectUri || window.location.origin

  return new Vue({
    name: 'AuthPlugin',
    data() {
      return {
        authenticated: false,
        client: null,
        loading: true,
        user: null,
        scopes: null,
        error: false,
        sessionExpired: false,
        popupOpen: false
      }
    },
    computed: {
      granted() {
        return (requiredScopes) => {
          if (!Array.isArray(requiredScopes)) {
            requiredScopes = [requiredScopes]
          }

          for (let requiredScope of requiredScopes) {
            if (typeof requiredScope !== 'string') {
              continue
            }

            if (Array.isArray(this.scopes) && this.scopes.includes(requiredScope)) {
              return true
            }
          }
          return false
        }
      }
    },
    async created() {
      this.client = new Auth0Client({
        client_id: options.clientId,
        domain: options.domain,
        issuer: options.issuer,
        scope: options.scopes.join(' '),
        redirect_uri: redirectUri,
        useRefreshTokens: true
      })

      try {
        // If the user is returning to the app after authentication..
        if (window.location.search &&
          window.location.search.includes('code=') &&
          window.location.search.includes('state=')
        ) {
          // handle the redirect and retrieve tokens
          const {appState} = await this.client.handleRedirectCallback()
          this.error = null
          onRedirectCallback(appState)
        }
      } catch (e) {
        this.error = e
      } finally {
        await this._handleAuthResult()
        this.loading = false
      }
    },
    methods: {
      async _handleAuthResult() {
        let scope = await this.client.getGrantedScope()
        this.user = await this.client.getUser()
        this.authenticated = await this.client.isAuthenticated()
        this.scopes = scope ? scope.split(' ') : null
      },
      /** Authenticates the user using a popup window */
      async loginWithPopup(options, config) {
        this.popupOpen = true

        try {
          await this.client.loginWithPopup(options, config)
          await this._handleAuthResult()
        } catch (e) {
          this.error = e
          // eslint-disable-next-line
          console.error(e)
        } finally {
          this.popupOpen = false
        }
      },
      /** Authenticates the user using the redirect method */
      loginWithRedirect(o) {
        this.loading = true
        this.error = null
        o = {
          login_hint: options.loginHint,
          ...o
        }
        return this.client.loginWithRedirect(o)
      },
      /** Returns all the claims present in the ID token */
      getIdTokenClaims(o) {
        return this.client.getIdTokenClaims(o)
      },
      async getTokenSilently() {
        if(this.error || this.sessionExpired) {
          throw new Error('Call of getTokenSilently ignored. Auth is in error or expired state. Reset error and sessionExpired to false before calling this method')
        }

        try {
          return await this.client.getTokenSilently()
        } catch (error) {
          return await this.refreshToken()
        }
      },
      async refreshToken() {
        try {
          return await this.client.getTokenSilently({
            ignoreCache: true
          })
        } catch (error) {
          // eslint-disable-next-line no-console
          console.warn('Cannot refresh access token. Session is considered as expired.', error)
          this.sessionExpired = true
          throw error
        }
      },
      /** Check a user's SSO status from an application */
      checkSession(o) {
        return this.client.checkSession(o)
      },
      /** Logs the user out and removes their session on the authorization server */
      logout(o) {
        this.client.logout({
          ...o,
          localOnly: true
        })

        const url = `https://${options.domain}${options.logoutEndpoint}`
        const params = {
          client_id: options.clientId
        }

        if (options.postLogoutRedirectUri) {
          params.post_logout_redirect_uri = options.postLogoutRedirectUri
        }

        window.location.assign(`${url}?${qs.stringify(params)}`)
      }
    }
  })
}

export default {
  install(Vue, options) {
    if (instance) return instance
    instance = createInstance(options)
    Vue.prototype.$auth = instance
  }
}
