import {Model, Person} from '../index'
import * as Invitations from '@/modules/invitations/api'

export class Invitation extends Model {
  static schemaName = 'invitations'

  static eventTypes = [...super.eventTypes, 'accept']

  static schemaDefinition() {
    return {
      guest: Person
    }
  }

  async accept() {
    let {data} = await Invitations.accept(this.uid)
    Invitation.save(data.invitation)
    return data
  }

  async decline() {
    await Invitations.decline(this.uid)
    Invitation.delete(this.uid)
  }
}
