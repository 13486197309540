<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <div class="theme-preview">
    <v-app-bar id="tour-topbar" :color="colors.primary.hex[current]">
      <router-link to="/">
        <v-img max-width="130px" width="130px" src="@/assets/img/applogo/mspace-logo-light.png" position="center center" to="/" />
      </router-link>
      <v-tabs
        centered
        dark
        icons-and-text
        class="ms-topbar font-alt"
      >
        <v-tab exact link class="preview-tab">
          Accueil
          <font-awesome-icon :icon="['fad', 'home-alt']" size="3x" />
        </v-tab>
        <v-tab link exact class="preview-tab">
          Mon Réseau
          <font-awesome-icon :icon="['fad', 'chart-network']" size="3x" />
        </v-tab>
        <v-tab link exact class="preview-tab">
          Mes Documents
          <font-awesome-icon :icon="['fad', 'folder-open']" size="3x" />
        </v-tab>
      </v-tabs>
      <v-spacer />
    </v-app-bar>
    <v-card class="p-rel elevation-x py-2 b-rad-none" :dark="!isLight">
      <div class="waves-container" :dark="!isLight">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 50 1440 140">
            <path :fill="colors.primary.hex[current]" fill-opacity="1"
                  d="m-24,124.45313c0,0 237,119 642,32c405,-87 633,-58 826,-19c193,39 59,-54.90626 84,
                  -77.45313c25,-22.54687 -88,-266.54688 -88,-266c0,-0.54688 -1478,13.45312 -1478,14c0,
                  -0.54688 -13,268.45313 14,316.45313z"
            />
          </svg>
        </div>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 70 1440 140">
            <path :fill="colors.garnish.hex[current]" fill-opacity="1"
                  d="m-38,200.45313c0,0 260,26 595,-14c335,-40 592,-110 875,-75c283,35 71,
                  -28.90626 96,-51.45313c25,-22.54687 -88,-266.54688 -88,-266c0,
                  -0.54688 -1478,13.45312 -1478,14c0,-0.54688 -27,344.45313 0,392.45313z"
            />
          </svg>
        </div>
      </div>

      <v-card class="mt-6 mx-4" :dark="!isLight">
        <div class="pa-3 pb-0">
          <div class="text-h6 font-weight-regular lh-125 text-uppercase mb-0" :style="'color: '+colors.primary.hex[current]">Nouveau thème</div>
          <div class="text-caption font-alt mb-3" :style="'color: '+colors.secondary.hex[current]">Création de votre thème personnalisé</div>
        </div>
        <v-list three-line>
          <template v-for="(item, index) in items">
            <v-divider v-if="item.divider" :key="index" />

            <v-list-item v-else :key="item.title">
              <v-list-item-avatar size="50">
                <v-img :src="item.avatar" />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
                <v-list-item-subtitle>
                  <span class="text--darken-1 font-weight-medium" :style="'color: '+colors.primary.hex[current]">{{ item.job }}</span>
                  à {{ item.address }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="fs-80">
                  {{ item.specialties }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-card>
    </v-card>
  </div>
</template>

<script>
  let userImage = require('@/assets/img/profile/user-generic.png')
  export default {
    name: 'ThemePreview',
    components: {},
    props: {
      colors: {
        type: Object
      },
      isLight: Boolean
    },
    data() {
      return {
        items: [
          {
            avatar: userImage,
            title: 'Matt Breanon',
            job: 'Medecin',
            address: 'Gardanne',
            specialties: 'Cancérologie option biologie en cancérologie . Acupuncture'
          },
          {divider: true, inset: true},
          {
            avatar: userImage,
            title: 'Guillaume Paut',
            job: 'Medecin',
            address: 'Gardanne',
            specialties: 'Cancérologie option biologie en cancérologie . Acupuncture'
          },
          {divider: true, inset: true},
          {
            avatar: userImage,
            title: 'Jean-Baptiste Vercasson',
            job: 'Medecin',
            address: 'Gardanne',
            specialties: 'Cancérologie option biologie en cancérologie . Acupuncture'
          },
          {divider: true, inset: true},
          {
            avatar: userImage,
            title: 'Mireille Lebeau',
            job: 'Medecin',
            address: 'Gardanne',
            specialties: 'Cancérologie option biologie en cancérologie . Acupuncture'
          },
          {divider: true, inset: true},
          {
            avatar: userImage,
            title: 'Elodie Vincent',
            job: 'Medecin',
            address: 'Gardanne',
            specialties: 'Cancérologie option biologie en cancérologie . Acupuncture'
          }
        ]
      }
    },
    computed: {
      current() {
        return this.isLight ? 'light' : 'dark'
      }
    }
  }
</script>

<style lang="scss" scoped>
  .theme-preview {
    .waves-container {
      position: absolute;
      width: 100%;
      height: 140px;
      overflow: hidden;
      left: 0;
      top: 0;

      div {
        position: absolute;
        width: 100%;
      }
    }
    .ms-topbar {
      .preview-tab {
        font-size: 9px;
      }
    }
  }
</style>
