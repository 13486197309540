<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-dialog v-model="display" persistent width="300" :fullscreen="!loading">
    <v-card v-if="loading" color="primary" dark max-width="300">
      <v-card-text class="pt-2">
        Chargement de votre document...
        <v-progress-linear
          rounded
          indeterminate
          color="secondary"
          class="mt-2 mb-0"
        />
      </v-card-text>
    </v-card>

    <v-card v-else class="p-rel h-100">
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ file.name }}</v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn dark text @click="download">
            <font-awesome-icon :icon="['fal','download']" size="lg" fixed-width />
          </v-btn>
          <v-btn v-if="$vuetify.breakpoint.lgAndUp" dark text @click="edit">
            <font-awesome-icon :icon="['fal','edit']" size="lg" fixed-width />
          </v-btn>
          <v-btn v-else dark text @click="mobileFileInformations = true">
            <font-awesome-icon :icon="['fal','info-circle']" size="lg" fixed-width />
          </v-btn>
          <v-btn icon dark @click="close">
            <v-icon>$close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-row v-if="source && !error" no-gutters class="ma-0" :class="{filecontent: $vuetify.breakpoint.mdAndDown}">
        <v-col cols="12" lg="9" md="8">
          <file-viewer-content
            :file-uid="file.uid"
            :source="source"
            :mime-type="file.mime_type"
            :is-image="isImage"
            :is-video="isVideo"
            :rotate="deg"
          />
        </v-col>
        <v-col v-if="$vuetify.breakpoint.lgAndUp" class="sm-hide file-infos-sidebar" lg="3" md="4">
          <file-edit v-if="editDocument" :uid="file.uid" @child-editing="edit" />
          <file-viewer-details v-else-if="file" :file="file" />
        </v-col>
      </v-row>
      <v-dialog v-if="$vuetify.breakpoint.mdAndDown" v-model="mobileFileInformations" hide-overlay transition="dialog-bottom-transition" fullscreen>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Informations du document</v-toolbar-title>
          <v-spacer />
          <v-btn dark icon @click="edit">
            <font-awesome-icon :icon="['fal','edit']" size="lg" fixed-width />
          </v-btn>
          <v-btn icon dark @click="mobileFileInformations = false">
            <font-awesome-icon :icon="['fal','times']" size="lg" fixed-width />
          </v-btn>
        </v-toolbar>
        <file-edit v-if="editDocument" :uid="file.uid" @child-editing="edit" />
        <file-viewer-details v-else-if="file" :file="file" />
      </v-dialog>
      <div v-if="error" class="documents-viewer-error">
        <span class="message">La visionneuse ne supporte pas ce type de document.</span>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
  import FileViewerContent from './FileViewerContent'
  import {isImg, isVideo} from '@/modules/files/helpers'
  import {File} from '@/models'
  import FileViewerDetails from '@/modules/files/components/viewer/FileViewerDetails'
  import FileEdit from '@/modules/files/views/FileEdit'

  export default {
    name: 'FileViewer',
    components: {FileViewerDetails, FileViewerContent, FileEdit},
    data() {
      return {
        editDocument: false,
        display: false,
        uid: null,
        loading: true,
        source: null,
        isImage: false,
        isVideo: false,
        error: null,
        deg: 0,
        mobileFileInformations: null
      }
    },
    computed: {
      file() {
        return File.get(this.uid)
      },

      rotateStyleObject() {
        if (this.deg !== 0) {
          const value = `rotate(${this.deg}deg)`
          return {
            webkitTransform: value,
            mozTransform: value,
            msTransform: value,
            oTransform: value,
            transform: value
          }
        }

        return {}
      }
    },
    async created() {
      this.$root.$on('fileviewer:open', async ({uid, callback}) => {
        this.loading = true
        this.uid = uid
        this.display = true
        this.callback = callback
        await this.openDocument()
      })
    },
    methods: {
      async openDocument() {
        try {
          await File.fetchOne(this.uid)
          await this.getSource()
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e)
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue',
            subtitle: 'Le document n\'a pas pu être chargé.'
          })
          this.close()
          this.loading = false
        } finally {
          this.loading = false
        }
      },
      edit() {
        this.editDocument = !this.editDocument
        this.openDocument()
      },
      async getSource() {
        this.error = false
        this.isVideo = this.isImage = false

        if (isVideo(this.file.mime_type)) {
          this.isVideo = true
        } else {
          this.isImage = isImg(this.file.mime_type)
        }

        this.source = await this.getStreamUrl()
      },
      async download() {
        let accessToken = await this.$auth.getTokenSilently()
        window.open(`${process.env.VUE_APP_URL_API}/v3/files/${this.uid}/download?access_token=${accessToken}`, '_blank')
      },
      async getSourceUrl() {
        let accessToken = await this.$auth.getTokenSilently()
        return `${process.env.VUE_APP_URL_API}/v3/files/${this.uid}/view?access_token=${accessToken}`
      },
      async getStreamUrl() {
        let accessToken = await this.$auth.getTokenSilently()
        return `${process.env.VUE_APP_URL_API}/v3/files/${this.uid}/stream?access_token=${accessToken}`
      },
      rotate(direction) {
        'right' === direction ? this.deg += 90 : this.deg -= 90

        if (this.deg > 360) this.deg = 90
        if (this.deg < -360) this.deg = -90
      },
      close(value) {
        if (typeof this.callback === 'function') {
          this.callback(value)
        }
        this.callback = null
        this.display = false
      }
    }
  }
</script>

<style lang="scss" scoped>
.file-infos-sidebar {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  height: calc(100vh - 64px);
  overflow-y: auto;
}
.filecontent {
  height: calc(100% - 56px)
}
</style>
