/*
 * <!--Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential-->
 */

import {Model, File} from '@/models'
import * as Files from '@/modules/files/api'
import {UPLOADING_STATUS} from '@/modules/files/store/files-upload'

export class FileList extends Model {
  static schemaName = 'file_lists'

  static limit = 9

  static schemaDefinition() {
    return {
      items: [File]
    }
  }

  constructor() {
    super()
    this.items = []
    this.endReached = false
  }

  static create(user) {
    if (!FileList.get(user)) {
      FileList.save({items: [], uid: user})
    }
  }

  async fetch(on) {
    let date = null

    if (this.items.length) {
      date = File.get(this.items[this.items.length - 1]).date
    }

    let skip = date ? this.items.map(v => File.get(v)).filter(v => v.date === date).length : 0

    let {data} = await Files.fetch(on, date, skip, FileList.limit)

    FileList.mutate(fileLists => {
      for (let row of data) {
        File.save(row)
        fileLists[this.uid].items.push(row.uid)
      }
    })

    if (FileList.limit > data.length) {
      this.$mutate(fileList => {
        fileList.endReached = true
      })
    }
  }

  async upload(on) {
    const files = this.$store().getters['files/upload/getPending'](on)
    for (let item of files) {
      let url = await this._post(item.data)

      this.$store().commit('files/upload/setStatus', {id: item.clientData.id, status: UPLOADING_STATUS.processing})

      await Files.upload(url, item.file, progress => {
        this.$store().commit('files/upload/setUploadProgress', {id: item.clientData.id, progress})
      })
        .then(response => {
          this._mutate(response.data)
          this.$store().commit('files/upload/setStatus', {id: item.clientData.id, status: UPLOADING_STATUS.done})
          this.$store().commit('files/upload/setUid', {id: item.clientData.id, uid: response.data.uid})
          this.$emit('upload-success', response.data.uid)
        })
        .catch((e) => {
          this.$store().commit('files/upload/remove', item.clientData.id)
          throw e
      })
    }
  }

  async _post(data) {
    return await Files.post(data)
      .then(response => {
        return response.data.upload_url
      })
  }

  _mutate(data) {
    this.$mutate(fileList => {
      File.save({
        uid: data.uid,
        name: data.name,
        mime_type: data.mime_type,
        keywords: data.keywords,
        date: data.creation_date,
        type: data.type ? data.type.name : null,
        by: data.uploader.full_name
      })
      fileList.items.unshift(data.uid)
    })
  }
}
