import {FeedList, FileList, Gang, Invitation, Membership} from '@/models'
import * as Teams from '@/modules/teams/api'
import * as Invitations from '@/modules/invitations/api'
import Vue from 'vue'
import {arrayContainsArray, merge, removeItem} from '@/utils/array'

// Il est utile d'avoir une projection différente en fonction des infos nécéssaire
// Cependant ici, étant donné que la seule différence serait "total_guests" absent dans COLLECTION_PROJECTIONS,
// il est plus optimisé d'éviter une 2eme requête en l'ajoutant dans COLLECTION_PROJECTIONS jusqu'a ce qu'il y ait
// plus de différences
export const ITEM_PROJECTIONS = ['patient', 'user', 'total_members', 'total_guests']
export const COLLECTION_PROJECTIONS = ['patient', 'user', 'total_members', 'total_guests']

export class Team extends Gang {

  static schemaName = 'teams'

  static schemaDefinition() {
    return {
      ...super.schemaDefinition(),
      membership: Membership, // membership of the current user
      memberships: [Membership],
      invitation: Invitation, // invitation of the current user
      invitations: [Invitation]
    }
  }

  constructor() {
    super()
    this.projections = []
  }

  async fetchFeedList() {
    if (!this.feedList) {
      let feedList = (await Teams.getFeedList(this.$store().state.core.space, this.uid)).data

      FeedList.save({...feedList, owner: this.uid})
      Team.save({
        uid: this.uid,
        type: 'team',
        feedList
      })
    }

    return FeedList.get(this.feedList)
  }

  async fetchFileList() {
    if (!this.fileList) {
      let [error, fileList] = await Teams.getFileList(this.uid)
        .then(response => {
          return [null, response.data]
        })
        .catch(error => {
          return [error, null]
        })

      if (error) return error

      FileList.save(fileList)
      Team.save({
        uid: this.uid,
        fileList
      })
    }
  }

  async fetchMemberships() {
    if (typeof this.memberships === 'undefined') {
      let {data} = await Teams.members.list(this.uid)
      Team.save({
        uid: this.uid,
        type: 'team',
        memberships: data.list
      })
    }
    return this.memberships
  }

  static async fetchOne(uid) {
    let team = this.get(uid)
    if (!team || !arrayContainsArray(team.projections, ITEM_PROJECTIONS)) {
      let {data} = await Teams.find(uid, ITEM_PROJECTIONS)
      this.save({
        ...data,
        projections: team ? merge(ITEM_PROJECTIONS, team.projections) : ITEM_PROJECTIONS
      })
      team = this.get(uid)
    }
    return team
  }

  async acceptInvitation(invitationUid) {
    let {invitation, membership} = (await Invitations.accept(invitationUid)).data
    Invitation.save(invitation)
    Membership.save(membership)
    this.$mutate(team => {
      Vue.set(team, 'membership', membership)
      if (team.total_guests !== 'undefined') {
        --team.total_guests
      }
    })
  }

  removeInvitation(uid) {
    this.$mutate((team) => {
      removeItem(team.invitations, uid)
      if (team.total_guests !== 'undefined') {
        --team.total_guests
      }
    })
  }

  async inviteList(uids) {
    let invitations = (await Teams.invitations.inviteList(this.uid, uids)).data
    this.$mutate((team) => {
      if (Array.isArray(this.invitations)) {
        for (let invitation of invitations) {
          this.invitations.push(invitation.uid)
          Invitation.save(invitation)
        }
      }

      if (team.total_guests !== 'undefined') {
        Vue.set(team, 'total_guests', team.total_guests + invitations.length)
      }
    })
  }

  async update(data) {
    Team.save({
      ...(await Teams.update(this.uid, data, ITEM_PROJECTIONS)).data,
      projections: ITEM_PROJECTIONS
    })
  }

  /**
   * @param  {Membership} membershipTarget
   * @returns {Promise<void>}
   */
  async transferOwnership(membershipTarget) {
    await Teams.members.transferOwnership(this.uid, membershipTarget.user)
    Membership.get(this.membership).$mutate(membership => {
      membership.elevation = 'member'
    })
    membershipTarget.$mutate(membership => {
      membership.elevation = 'owner'
    })
  }

  async leave() {
    await Teams.leave(this.uid)
    this.$mutate(team => team.membership = null)
  }

  async removeMember(membership) {
    await Teams.members.remove(this.uid, membership.user)
    this.$mutate(team => {
      removeItem(team.memberships, membership.uid)
    })
    membership.$delete()
  }
}
