<template>
  <v-menu v-if="$auth.user" offset-y min-width="250px">
    <template v-slot:activator="{on}">
      <v-chip color="primary" text-color="white" v-on="on">
        <v-icon v-if="$vuetify.breakpoint.lgAndUp" small left class="ml-1">$menu</v-icon>
        <user-avatar size="24px" :user="$auth.user" class="ml-1" />
      </v-chip>
    </template>
    <v-list>
      <v-list-item-group>
        <v-list-item inactive two-line>
          <v-list-item-avatar>
            <user-avatar :user="$auth.user" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ $auth.user.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ $auth.user.job }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item @click="openSettings">
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'tools']" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Mes paramètres</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="openSpaceSelector">
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'exchange']" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Changer d'espace</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'sign-out']" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Se déconnecter</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
  import UserAvatar from '@/modules/core/layout/UserAvatar'

  export default {
    name: 'UserMenu',
    components: {UserAvatar},
    methods: {
      logout() {
        this.$auth.logout()
      },
      openSpaceSelector() {
        this.$store.commit('layout/toggleSpaceSelector')
      },
      openSettings() {
        this.$store.commit('layout/openSettings')
      }
    }
  }
</script>
