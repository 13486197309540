/*
 * Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'
import qs from 'qs'
import { setupCache } from 'axios-cache-adapter'
import config from '@/config'
import { getInstance as getAuthInstance } from '@/plugins/auth'

// https://github.com/RasCarlito/axios-cache-adapter
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
  ignoreCache: true
})

const instance = new axios.create({
  baseURL: config.urls.api,
  adapter: cache.adapter,
  paramsSerializer: params => {
    return qs.stringify(params)
  }
})

instance.interceptors.request.use(async (config) => {
  const auth = getAuthInstance()
  if (auth.authenticated) {
    config.headers.authorization = `Bearer ${await auth.getTokenSilently()}`
  }

  // Do something before request is sent
  return config
}, error => {
  // Do something with request error
  Promise.reject(error)
})

// Add a response interceptor
instance.interceptors.response.use(response => response,
  async error => {
    if (error.response && error.response.status === 401 && error.response.data.error === 'authentication_failure') {
      // eslint-disable-next-line no-console
      console.warn('API Access denied, retry')

      try {
        let newAccessToken = await getAuthInstance().refreshToken()
        error.config.headers.authorization = `Bearer ${newAccessToken}`
        return axios.request(error.config)
      }
      catch (_) {
        Promise.reject(error)
      }
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
  }
)

export default instance
