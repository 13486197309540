<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <div>
    <v-progress-linear v-show="uploading" color="primary" indeterminate class="uploading-progressbar" />
    <v-footer style="width: 100%;" color="grey lighten-2 px-2">
      <messages-uploading-files :conversation="conversation" />
      <div class="d-flex flex-row flex-grow-1">
        <v-textarea
          ref="messageInput"
          v-model="message"
          class="messenger-input"
          dense rounded filled flat hide-details solo
          width="100%"
          rows="1"
          placeholder="Message de chat"
          style="max-height: 118px; overflow-y: auto;width: 100%;"
          :disabled="posting"
          @keypress.enter="onEnterKeyPress"
        >
          <template v-slot:prepend-inner>
            <v-btn fab x-small depressed class="mr-2" @click="() => $refs.file.click()">
              <font-awesome-icon :icon="['far', 'paperclip']" size="lg" class="grey--text text--darken-2" />
            </v-btn>
          </template>
          <template v-slot:append>
            <v-btn v-if="!posting" fab x-small depressed :disabled="uploading" @click="onEnterKeyPress">
              <font-awesome-icon icon="paper-plane" size="2x" class="grey--text text--darken-2" :class="uploading ? 'op-15' : ''" />
            </v-btn>
            <v-btn v-else fab x-small depressed>
              <font-awesome-icon :icon="['fad', 'spinner-third']" spin size="2x" class="grey--text text--darken-2" />
            </v-btn>
          </template>
        </v-textarea>
        <file-input ref="file" hidden @upload="onFilesInput" />
      </div>
    </v-footer>
  </div>
</template>

<script>
  import FileUploadMixin from '@/modules/files/mixins/file-upload'
  import {UPLOADING_STATUS} from '@/modules/files/store/files-upload'
  import {empty} from '@/utils/variables'
  import MessagesUploadingFiles from '@/modules/messenger/components/messages/MessagesUploadingFiles'
  import FileInput from '@/modules/files/components/upload/FileInput'

  export default {
    name: 'MessagesInput',
    components: {FileInput, MessagesUploadingFiles},
    mixins: [FileUploadMixin],
    props: {
      conversation: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        message: '',
        posting: false,
        uploading: false
      }
    },

    computed: {
      files() {
        if (!this.conversation.file_list) return

        return this.$store.getters['files/upload/getByStatus'](UPLOADING_STATUS.done, this.conversation.file_list)
      }
    },

    methods: {
      onEnterKeyPress(e) {
        if (!e.shiftKey) {
          this.sendMessage()
        }
      },

      async sendMessage() {
        if (!this.message && !this.files) {
          return
        }

        this.posting = true

        if (this.files) {
          let params = {conversation: this.conversation}
          for (let file of this.files) {
            params.attachment = file.data.uid
            let message = await this.$store.dispatch('messenger/messages/post', params)
            this.$emit('new', message)
            this.$store.commit('files/upload/remove', file.clientData.id)
          }
        }

        if (!empty(this.message)) {
          let message = await this.$store.dispatch('messenger/messages/post', {
            conversation: this.conversation,
            message: this.message
          })
          this.$emit('new', message)
        }
        this.posting = false
        this.reset()
      },

      reset() {
        this.posting = false
        this.message = ''
        this.$nextTick(() => {
          this.$refs.messageInput.focus()
        })
      },

      async onFilesInput(files) {
        this.uploading = true
        for (let file of files) {
          this.$store.commit('files/upload/add', {
            data: {
              on: this.conversation.file_list
            }, file
          })
        }
        try {
          await this.userFileList.upload(this.conversation.file_list)
        } catch (e) {
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'error',
            title: 'Format du fichier invalide',
            subtitle: 'Le format du document n\'est pas valide'
          })
        } finally {
          this.uploading = false
        }
      }
    }
  }
</script>

<style lang="scss">
  .uploading-progressbar {
    z-index: 22;
  }
  .messenger-input {
    .v-input__slot {
      padding-left: 5px !important;
      padding-right: 5px !important;
    }

    .v-input__prepend-inner {
      margin-top: 4px !important;
    }

    .v-input__append-inner {
      margin-top: 4px !important;
    }
  }
</style>
