/*
 * Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

let messages = {}
let index = 0

function getFirstMessage() {
  return Object.values(messages)[0]
}

export default {
  install: function (Vue, {router}) {
    window.addEventListener('beforeunload', (e) => {
      let message = getFirstMessage()
      if (message) {
        e.preventDefault()
        e.returnValue = message

        return message
      }
    })

    Vue.prototype.$confirmLeave = function (newMessage) {
      messages[index] = newMessage
      return index++
    }

    Vue.prototype.$clearConfirmLeave = function (index) {
      delete messages[index]
    }

    router.beforeEach((to, from, next) => {
      let message = getFirstMessage()
      if (message) {
        if (confirm(message)) {
          message = null
          next()
        } else {
          next(false)
        }
      } else {
        next()
      }
    })
  }
}
