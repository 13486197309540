/*
 * Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import Vue from 'vue'
import Confirm from '@/modules/core/components/Confirm'

export default {
  install(vue, options) {
    vue.prototype.$confirm = (message, type) => {
      const container = document.querySelector('[data-app=true]') || document.body
      const Ctor = Vue.extend(Object.assign({ vuetify: options.vuetify }, Confirm))
      return new Promise((resolve) => {
        const instance = new Ctor({
          propsData: { message, type },
          destroyed: () => {
            container.removeChild(instance.$el)
            resolve(instance.value)
          }
        })
        container.appendChild(instance.$mount().$el)
      })
    }
  }
}
