<template>
  <v-snackbar v-model="snackbar" fixed right top max-height="500" color="white" :class="dialogType" class="snackbar-notifs">
    <div class="d-flex align-center">
      <font-awesome-icon class="mr-4" size="3x" :class="typeColor" :icon="['fad', typeIcon]" />
      <v-card-text class="d-flex flex-column pa-0">
        <v-card-title class="font-alt font-weight-light confirm-title pa-0 pb-3" :class="typeColor">{{ title }}</v-card-title>
        <v-card-subtitle class="font-alt font-weight-light text-caption pa-0 grey--text text--darken-2">{{ subtitle }}</v-card-subtitle>
      </v-card-text>
    </div>
  </v-snackbar>
</template>

<script>
  import {mapMutations} from 'vuex'
  export default {
    name: 'Snackbar',
    data() {
      return {
        snackbar: null,
        title: null,
        subtitle: null,
        type: null
      }
    },
    computed: {
      dialogType: function () {
        return {
          successdialog: this.type === 'success',
          errordialog: this.type === 'error',
          infodialog: this.type === 'info'
        }
      },
      typeColor() {
        let color
        switch (this.type) {
          case 'success':
            color = 'success--text'
            break
          case 'error':
            color = 'error--text text--ligthen-1'
            break
          case 'info':
            color = 'blue--text text--ligthen-3'
            break
        }
        return color
      },
      typeIcon() {
        let icon
        switch (this.type) {
          case 'success':
            icon = 'thumbs-up'
            break
          case 'error':
            icon = 'exclamation-square'
            break
          case 'info':
            icon = 'info-square'
            break
          default:
            icon = 'info-square'
            break
        }
        return icon
      }
    },
    created() {
      this.$root.$on('snackbar:open', async ({snackbar, type, title, subtitle}) => {
        this.snackbar = snackbar
        this.type = type
        this.title = title
        this.subtitle = subtitle
        this.toggleConfirmSnackbar(true)
      })
    },
    methods: {
      ...mapMutations('layout', ['toggleConfirmSnackbar'])
    }
  }
</script>
