<!--
  - Copyright (C)  E-Synaps SAS - 2021 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-app-bar id="tour-topbar" app color="primary">
    <router-link to="/">
      <v-img max-width="130px" width="130px" src="@/assets/img/applogo/mspace-logo-light.png" position="center center" to="/" />
    </router-link>
    <v-spacer />
    <messenger-toggle />
<!--    <notifications />-->
    <user-menu />
  </v-app-bar>
</template>

<script>
  import UserMenu from '@/modules/core/layout/header/UserMenu'
  import Notifications from '@/modules/core/layout/header/Notifications'
  import MessengerToggle from '@/modules/core/layout/header/MessengerToggle'
  export default {
    name: 'HeaderBarMobile',
    components: {MessengerToggle, Notifications, UserMenu}
  }
</script>
