/*
 * Copyright (C)  E-Synaps SAS - 2021 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

export default {
  namespaced: true,

  state: {
    sidebar: false,
    chatWindows: [],
    currentlyDisplayed: []
  },

  getters: {
    get: state => uid => {
      return state.chatWindows.find(v => uid === v.uid)
    }
  },

  mutations: {
    toggleSideBar(state) {
      state.sidebar = !state.sidebar
    },
    openChatWindow(state, uid) {
      if(!state.currentlyDisplayed.includes(uid)) {
        state.currentlyDisplayed.push(uid)
      }
      let chatWindow = state.chatWindows.find(chat => chat.uid === uid)
      if(!chatWindow) {
        state.chatWindows.push({
          uid,
          open: true,
          minimized: false,
          focused: true,
          unread: null
        })
      } else {
        chatWindow.open = true
        chatWindow.minimized = false // in case the window was left on screen but minimized
      }
    },
    closeChatWindow(state, uid) {
      let chatWindow = state.chatWindows.find(chat => chat.uid === uid)
      chatWindow.open = false
      state.currentlyDisplayed.splice(state.currentlyDisplayed.findIndex(chat => chat === uid), 1)
    },
    resizeChatWindow(state, uid) {
      let chatWindow = state.chatWindows.find(chat => chat.uid === uid)
      chatWindow.minimized = !chatWindow.minimized
    }
  }
}
