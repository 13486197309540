import api from '@/api/api'

export function list(teamUid) {
  return api.get(`/v3/teams/${teamUid}/members`, {params: {limit: -1}})
}

export function transferOwnership(teamUid, memberUid) {
  return api.post(`/v3/teams/${teamUid}/members/${memberUid}/transfer-ownership`)
}

export function remove(teamUid, memberUid) {
  return api.delete(`/v3/teams/${teamUid}/members/${memberUid}`)
}
