<template>
  <!--suppress HtmlUnknownTarget -->
  <v-img :width="size" :height="size" :src="src" class="overflow-hidden w-100" />
</template>

<script>
  import jpg from '@/assets/img/files/jpg.png'
  import doc from '@/assets/img/files/doc.png'
  import mp3 from '@/assets/img/files/mp3.png'
  import pdf from '@/assets/img/files/pdf.png'
  import txt from '@/assets/img/files/txt.png'
  import xls from '@/assets/img/files/xls.png'
  import ppt from '@/assets/img/files/ppt.png'

  export default {
    name: 'FileMimeTypePreview',
    props: {
      mimeType: String,
      size: {
        type: Number,
        required: false,
        default: 75
      }
    },
    computed: {
      src() {
        let asset
        if (!this.mimeType) {
          return doc
        }

        if (this.mimeType.match(/image/)) {
          asset = jpg
        } else if (this.mimeType.match(/video/) || 'application/mp4' === this.mimeType) {
          asset = doc
        } else if (this.mimeType.match(/audio/)) {
          asset = mp3
        } else {
          switch (this.mimeType) {
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            case 'application/vnd.oasis.opendocument.text':
            case 'application/msword':
              asset = doc
              break
            case 'application/pdf':
              asset = pdf
              break
            case 'text/html':
            case 'text/plain':
              asset = txt
              break
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            case 'application/vnd.oasis.opendocument.spreadsheet':
            case 'application/vnd.ms-excel':
              asset = xls
              break
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            case 'application/vnd.openxmlformats-officedocument.presentationml.template':
            case 'application/vnd.oasis.opendocument.presentation':
            case 'application/vnd.ms-powerpoint':
              asset = ppt
              break
            default:
              asset = doc
          }
        }

        return asset
      }
    }
  }
</script>
