<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-list-item :key="uid" :value="uid" @click="selectConversation">
    <v-list-item-icon class="mb-4">
      <messenger-conversation-avatar :size="40" :participants="conversation.participants" />
    </v-list-item-icon>

    <v-list-item-content>
      <v-list-item-title>
        {{ title }}
      </v-list-item-title>
      <v-list-item-subtitle v-if="lastMessage">
        {{ lastMessage.attachment ? lastMessage.attachment.name : lastMessage.message }}
      </v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action
      v-if="conversation.total_unread_messages"
      class="whap-messenger-newMessage rounded elevation-x"
    >
      <v-chip x-small class="ma-0" color="secondary" text-color="white">{{ conversation.total_unread_messages }}</v-chip>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
  import MessengerConversationAvatar from '@/modules/messenger/components/MessengerConversationAvatar'
  import {Conversation, Message} from '@/modules/messenger/models'

  export default {
    name: 'ConversationsListItem',

    components: {MessengerConversationAvatar},

    props: {
      uid: {
        required: true,
        type: String
      }
    },
    computed: {
      conversation() {
        return Conversation.get(this.uid)
      },
      lastMessage() {
        return Message.get(this.conversation.last_message)
      },
      title() {
        return this.conversation.getTitle() || this.$auth.user.full_name
      }
    },
    methods: {
      selectConversation() {
        this.$store.commit('messenger/layout/openChatWindow', this.uid)
      }
    }
  }
</script>
