export default [
  {
    path: '/network',
    component: () => import('./views/NetworkSection'),
    meta: {
      mobiletitle: 'Gérer mon réseau'
    },
    children: [
      {
        path: '',
        name: 'my-contacts',
        component: () => import('./views/contacts/ContactsList'),
        meta: {
          mobiletitle: 'Gérer mon réseau'
        }
      },
      {
        path: 'members',
        name: 'space-members',
        component: () => import('../spaces/views/SpaceMembers'),
        meta: {
          mobiletitle: 'Gérer mon réseau'
        }
      },
      {
        path: 'patients',
        name: 'my-patients',
        component: () => import('../patients/views/professionals/PatientsList'),
        meta: {
          mobiletitle: 'Gérer mon réseau'
        }
      },
      {
        path: 'teams',
        name: 'my-teams',
        component: () => import('../teams/views/TeamsList'),
        meta: {
          mobiletitle: 'Gérer mon réseau'
        }
      },
      {
        path: 'medical-team',
        name: 'my-medical-team',
        component: () => import('./components/patients/MedicalTeamList'),
        meta: {
          mobiletitle: ''
        }
      }
    ]
  }
]
