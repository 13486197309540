import {list as listMembers} from '@/modules/spaces/api/members'
import {Membership} from '@/models'
import {column, removeItem} from '@/utils/array'
import {empty} from '@/utils/variables'

function getDefaultState() {
  return {
    items: [],
    page: 0,
    total: 0,
    endReached: false
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    addItems(state, {page, total, items}) {
      state.items = state.items.concat(items)
      state.total = total
      state.page = page
    },
    setPage(state, value) {
      state.page = value
    },
    setTotal(state, total) {
      state.total = total
    },
    removeItem(state, uid) {
      removeItem(state.list.items, uid)
      --state.total
    },
    setEndReached(state, value) {
      state.endReached = value
    },
    resetState(state) {
      Object.assign(state, getDefaultState())
    }
  },
  actions: {
    async fetchNextPage({state, commit, rootState}) {
      if (state.endReached) {
        return
      }

      let page = state.page + 1
      let {total, list} = (await listMembers(rootState.core.space, page)).data
      if (empty(list)) {
        commit('setEndReached', true)
        return
      }

      Membership.save(list)
      commit('addItems', {page, total, items: column(list, 'uid')})

      if (state.items.length >= total) {
        commit('setEndReached', true)
      }
    }
  }
}
