import * as Feeds from '@/modules/feeds/api'
import {removeItem} from '@/utils/array'
import {Feed, FeedItem, FeedList, FeedListOwner} from '@/models'
import * as Subscriptions from '@/modules/feeds/api/subscriptions'
import Vue from 'vue'
import {objectFromArray} from '@/utils/object'
import * as Users from '@/modules/user/api'

// Placé dans un store au lieu d'un model car ce traitement (envoie des publications en attente) doit se faire
// même si aucune instance de FeedList existe
export default {
  namespaced: true,
  state: {
    pendingPublications: [],
    processing: false,
    subscriptions: null,
    userFeedList: null,
    fetchUserFeedListPromise: null
  },
  getters: {
    byFeedList(state) {
      return feedListUid => state.pendingPublications.filter(item => item.feedListUid === feedListUid)
    }
  },
  mutations: {
    setFetchUserFeedListPromise(state, promise) {
      state.fetchUserFeedListPromise = promise
    },
    addPendingPublication(state, item) {
      state.pendingPublications.push(item)
    },
    removePendingPublication(state, item) {
      removeItem(state.pendingPublications, item)
    },
    setProcessing(state, value) {
      state.processing = value
    },
    setSubscriptions(state, value) {
      state.subscriptions = value
    },
    removeSubscription(state, feedUid) {
      if (state.subscriptions !== null) {
        Vue.delete(state.subscriptions, feedUid)
        if(state.userFeedList) {
          FeedList.get(state.userFeedList).reset()
        }
      }
    },
    addSubscription(state, feed) {
      if (state.subscriptions !== null) {
        Vue.set(state.subscriptions, feed.uid, feed)
        if(state.userFeedList) {
          FeedList.get(state.userFeedList).reset()
        }
      }
    },
    setUserFeedList(state, list) {
      state.userFeedList = list
    }
  },
  actions: {
    async fetchUserFeedList({rootState, state, commit}) {
      if(state.userFeedList) {
        return state.userFeedList
      }

      // Test de mise en pratique de "store promise in Vuex!"
      // https://tkacz.pro/use-vuex-to-avoid-multiple-requests-from-different-components/
      if(state.fetchUserFeedListPromise) {
        return state.fetchUserFeedListPromise
      }

      let promise = new Promise((resolve, reject) => {
        Users.getFeedList(rootState.core.space, this.$app.$auth.user.sub).then(({data}) => {
          FeedList.save(data)
          commit('setUserFeedList', data.uid)
          resolve(data.uid)
        }).catch(reject)
      })
      commit('setFetchUserFeedListPromise', promise)
      return promise
    },
    async fetchSubscriptions({state, commit, dispatch}) {
      if (state.subscriptions === null) {
        commit('setSubscriptions', {})

        let userFeedListUid =  await dispatch('fetchUserFeedList')
        let subscriptions = (await Subscriptions.list(userFeedListUid)).data
        commit('setSubscriptions', objectFromArray(subscriptions, 'uid'))
      }
      return state.subscriptions
    },
    async subscribe({state, commit}, list) {
      if(!state.userFeedList) {
        throw new Error('User feed list is undefined, you must call fetchUserFeedList before trying to subscribe')
      }

      let feed = Feed.get(list.personal_feed)
      await Subscriptions.add(state.userFeedList, feed.uid)

      feed.$mutate(feed => Vue.set(feed, 'subscribed', true))
      commit('addSubscription', {
        ...feed,
        owner: FeedListOwner.get(list.owner)
      })
    },
    async unsubscribe({state, commit}, list) {
      if(!state.userFeedList) {
        throw new Error('User feed list is undefined, you must call fetchUserFeedList before trying to unsubscribe')
      }

      let feed = Feed.get(list.personal_feed)
      await Subscriptions.remove(state.userFeedList, feed.uid)

      feed.$mutate(feed => Vue.set(feed, 'subscribed', false))
      commit('removeSubscription', feed.uid)
    },
    publish({commit, dispatch}, {feedListUid, targetFeedListUid, publication}) {
      commit('addPendingPublication', {feedListUid, targetFeedListUid, publication})
      if (!window.navigator.onLine) {
        commit('layout/openSnackbar', {
          text: 'Votre message sera publié lorsque la connexion internet sera rétablie',
          color: 'warning'
        }, {root: true})
      } else {
        dispatch('processPendingPublications')
      }
    },
    async processPendingPublications({state, commit, dispatch}, initialPendingPublications = 0) {
      if (!window.navigator.onLine || state.processing || state.pendingPublications.length === 0) {
        return
      }

      commit('setProcessing', true)
      let pendingPublication = state.pendingPublications[0]
      let {feedListUid, targetFeedListUid, publication} = pendingPublication
      // let medias = column(publicationData.documents, 'uid')

      let result = await Feeds.createPublication(
        targetFeedListUid,
        publication.message,
        publication.open_graph,
        publication.visibility,
        publication.alert,
        publication.files
      )

      commit('setProcessing', false)
      commit('removePendingPublication', pendingPublication)

      FeedItem.save(result)
      FeedList.get(feedListUid).addItem(result)
      if(feedListUid !== targetFeedListUid) {
        FeedList.get(targetFeedListUid).addItem(result)
      }

      if (state.pendingPublications.length === 0) {
        if (initialPendingPublications) {
          let text
          if (initialPendingPublications === 1) {
            text = 'Votre message en attente a été publié avec succés'
          } else {
            text = 'Vos ' + initialPendingPublications + ' messages en attente ont été publiés avec succés'
          }
          commit('layout/openSnackbar', {
            text,
            color: 'success',
            timeout: 10000
          }, {root: true})
        }
      } else {
        dispatch('processPendingPublications', initialPendingPublications)
      }
    }
  }
}
