<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <div>
    <span v-for="(chunk, index) in splitNotification" :key="index">
      <span v-if="chunk.text">{{ chunk.text }}</span>
      <span v-else class="primary--text font-weight-medium" @click="getAction(chunk.type, chunk.identifier)">
        {{ chunk.content }}
      </span>
    </span>
  </div>
</template>

<script>
  export default {
    name: 'NotificationParser',
    props: {
      notification: {
        type: String
      }
    },
    data() {
      return {
        splitNotification : []
      }
    },
    created() {
      /*
            Example of a notification :
            [[user/iVH36HoYB0lkaOjpuyRo]M. Raphael BARRÉ]
            a accepté de rejoindre le groupe     [[group/f1CxKUDCvaZPVBES8P0I]test notification v3]
        */

      // Shitty but.. it works, strangely
      let tempNotif = this.notification.replace(/\[\[(.*)\/(.*)](.*)]/g, (match, type, identifier, content) => {
        return '||'+type+'//'+identifier+'//' + content + '||'
      })
      const rawNotification = tempNotif.split('||')
      let processedNotification = []

      for(let segments of rawNotification) {
        if(segments === '') {
          continue
        }
        let tempArray = segments.split('//')

        if(tempArray.length > 1) {
          let entity = {
            type: tempArray[0],
            identifier: tempArray[1],
            content: tempArray[2]
          }
          processedNotification.push(entity)
        } else {
          processedNotification.push({text: tempArray[0].trim()})
        }

      }
      this.splitNotification = processedNotification
    },
    methods: {
      getAction(type, identifier)
      {
        switch (type) {
          case 'user':
            this.$router.push({name: 'professional-profile', params: {uid: identifier}})
            break
          case 'patient':
            this.$router.push({name: 'patient-profile', params: {uid: identifier}})
            break
          default:
            return
        }
        this.$root.$emit('notifications:close')
      }
    }
  }
</script>
