import * as Establishments from '@/modules/establishments/api'
import Vue from 'vue'
import {EstablishmentMembership, Space} from '@/models'
import {column} from '@/utils/array'

export default {
  namespaced: true,
  state: {
    memberships: {
      limit: 10,
      total: 0,
      items: {}
    }
  },
  mutations: {
    setTotal(state, total) {
      state.memberships.total = total
    },
    setMemberships(state, {page, items}) {
      Vue.set(state.memberships.items, page, items)
    }
  },
  actions: {
    async fetchMembers({state, commit, rootState}, page = 1) {
      let establishment = Space.get(rootState.core.space).establishment
      if (!establishment) {
        throw 'Current space does not have an establishment'
      }

      let {total, list} = await Establishments.listMembers(establishment, page, state.memberships.limit)

      EstablishmentMembership.save(list)
      commit('setMemberships', {page, items: column(list, 'uid')})
      commit('setTotal', total)
    }
  }
}

