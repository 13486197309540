/*
 * Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

/* eslint-disable no-console */

export function localStorageSpace() {
  let data = ''

  console.log('Current local storage: ')

  for (let key in window.localStorage) {

    // eslint-disable-next-line no-prototype-builtins
    if (window.localStorage.hasOwnProperty(key)) {
      data += window.localStorage[key]
      console.log(key + ' = ' + ((window.localStorage[key].length * 16) / (8 * 1024)).toFixed(2) + ' KB')
    }

  }

  console.log(data ? '\n' + 'Total space used: ' + ((data.length * 16) / (8 * 1024)).toFixed(2) + ' KB' : 'Empty (0 KB)')
  console.log(data ? 'Approx. space remaining: ' + (5120 - ((data.length * 16) / (8 * 1024)).toFixed(2)) + ' KB' : '5 MB')
}

export function setBuildDate(buildDate) {
  if (!buildDate) {
    console.log('No build date. Local storage not modified')
  } else if (buildDate !== window.localStorage.getItem('build_date')) {
    console.warn('Local storage build date mismatch. It has just been cleaned')
    window.localStorage.clear()
    window.localStorage.setItem('build_date', buildDate)
  } else {
    console.log('Local storage build date match')
  }
}
