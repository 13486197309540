import {Establishment, FeedList, Gang, Invitation, Membership, FileList} from '@/models'
import * as Spaces from '@/modules/spaces/api'
import {promiseMemoize} from '@/utils/promise'

export const ITEM_PROJECTIONS = ['user', 'establishment', 'total_members', 'total_guests', 'theme']
export const COLLECTION_PROJECTIONS = ['user', 'establishment']

export class Space extends Gang {
  static schemaName = 'spaces'

  static schemaDefinition() {
    return {
      ...super.schemaDefinition(),
      establishment: Establishment,
      membership: Membership, // membership of the current user
      memberships: [Membership],
      invitation: Invitation, // invitation of the current user
      invitations: [Invitation]
    }
  }

  constructor() {
    super()
    this.fetchFeedList = promiseMemoize(this.fetchFeedList)
    this.fetchFileList = promiseMemoize(this.fetchFileList)
  }

  async fetchFeedList() {
    if (!this.feedList) {
      let feedList = (await Spaces.getFeedList(this.uid)).data

      FeedList.save({...feedList, owner: this})
      Space.save({
        uid: this.uid,
        feedList
      })
    }

    return FeedList.get(this.feedList)
  }

  async fetchFileList() {
    if (!this.fileList) {
      let [error, fileList] = await Spaces.getFileList(this.uid)
        .then(response => {
          return [null, response.data]
        })
        .catch(error => {
          return [error, null]
        })

      if (error) return error

      FileList.save(fileList)
      Space.save({
        uid: this.uid,
        fileList
      })
    }
  }

  async loadFeatures() {
    if (!Array.isArray(this.features)) {
      let features = (await Spaces.fetchFeatures(this.uid)).data
      Space.save({
        uid: this.uid,
        features
      })
    }
    return this.features
  }

  static deleteByEstablishment(establishmentUid) {
    for (let spaceUid of Object.keys(this.state()[this.schemaName])) {
      let space = Space.get(spaceUid)
      if (space.establishment === establishmentUid) {
        space.$delete()
      }
    }
  }
}
