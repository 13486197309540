import {FeedItem, Publication} from './index'

export class FeedItemPublication extends FeedItem {
  static schemaDefinition() {
    return {
      ...super.schemaDefinition(),
      publication: Publication
    }
  }
}
