<template>
  <v-bottom-navigation class="bottom-navigation-container" color="primary" grow fixed app>
    <v-btn active-class="navigation-active" exact link x-small to="/" value="home">
      <span class="font-weight-light font-alt text-caption">Accueil</span>
      <font-awesome-icon :icon="['fad', 'home-alt']" size="2x" />
    </v-btn>
    <v-btn active-class="navigation-active" exact link x-small to="/network" value="network">
      <span class="pt-1 font-weight-light font-alt text-caption">Réseau</span>
      <font-awesome-icon :icon="['fad', 'chart-network']" size="2x" />
    </v-btn>
    <v-btn active-class="navigation-active" exact link x-small to="/files" value="files">
      <span class="pt-1 font-weight-light font-alt text-caption">Documents</span>
      <font-awesome-icon :icon="['fad', 'folder-open']" size="2x" />
    </v-btn>
    <v-btn active-class="navigation-active" exact link x-small to="/search" value="search">
      <span class="pt-1 font-weight-light font-alt text-caption">Rechercher</span>
      <font-awesome-icon :icon="['fad', 'search']" size="2x" />
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
  export default {
    name: 'BottomNavigationMobile',
    data() {
      return {
        value: ''
      }
    }
  }
</script>
