import {Model, Establishment, Space, Team, FeedList, FileList} from '@/models'

export class Gang extends Model {
  static schemaDefinition() {
    return {
      feedList: FeedList,
      fileList: FileList
    }
  }

  static types() {
    return {
      establishment: Establishment,
      space: Space,
      team: Team
    }
  }
}
