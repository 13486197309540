<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-list>
    <v-list-item-group color="primary" @change="value => $emit('select', value)">
      <messenger-contacts-list-item v-for="item in items" :key="item.uid" :item="item" :value="item.uid" />
    </v-list-item-group>
  </v-list>
</template>

<script>
  import MessengerContactsListItem from '@/modules/messenger/components/contacts/MessengerContactsListItem'

  export default {
    name: 'MessengerContactsList',
    components: {MessengerContactsListItem},
    props: {
      items: {
        type: Array,
        required: true
      }
    }
  }
</script>
