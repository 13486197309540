<!--
  - Copyright (C)  E-Synaps SAS - 2021 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <div v-if="!$store.state.layout.isMobile" class="w-desktop-breadcrumb w-100">
    <div class="waves-container p-abs w-100 abs-l-0 abs-t-0">
      <div class="p-abs w-100">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 50 1440 140">
          <path :fill="$vuetify.theme.currentTheme.primary" fill-opacity="1"
                d="m-24,124.45313c0,0 237,119 642,32c405,-87 633,-58 826,-19c193,39 59,-54.90626 84,
                -77.45313c25,-22.54687 -88,-266.54688 -88,-266c0,-0.54688 -1478,13.45312 -1478,14c0,
                -0.54688 -13,268.45313 14,316.45313z"
          />
        </svg>
      </div>
      <div class="p-abs w-100">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 70 1440 140">
          <path :fill="$vuetify.theme.currentTheme.garnish" fill-opacity="1"
                d="m-38,200.45313c0,0 260,26 595,-14c335,-40 592,-110 875,-75c283,35 71,-28.90626 96,
                -51.45313c25,-22.54687 -88,-266.54688 -88,-266c0,-0.54688 -1478,13.45312 -1478,14c0,
                -0.54688 -27,344.45313 0,392.45313z"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PageBackground'
  }
</script>
