import {
  Establishment,
  FeedList,
  Membership,
  Person,
  Publication,
  Space,
  Team,
  FeedListOwner
} from '@/models'

/**
 *
 * @param user
 * @param can
 */
export default function (user, can) {

  // if(on && on.type === 'team' && on.status !== 'active') {
  //   return build()
  // }
  if (!user) {
    return
  }

  // Je peux partager toutes les publications sauf si elle contient un fichier et que je ne suis pas l'auteur
  can('share', Publication, publication => {
    let owner = FeedListOwner.get(FeedList.get(publication.on).owner)
    if(publication.files.length && owner.uid !== user.sub) {
      return false
    }
    return owner instanceof Person
  })

  // Je peux éditer toutes mes publications
  can(['update', 'remove'], Publication, publication => {
    let by = publication.by ? FeedList.get(publication.by) : null
    return by && by.owner === user.sub
  })

  // Publié sur son mur
  can(['pin', 'unpin', 'publish', 'remove'], FeedList, list => {
    let owner = list.owner ? FeedListOwner.get(list.owner) : null
    return owner instanceof Person && owner.uid === user.sub
  })

  // Publié sur le mur d'un établissement
  can(['pin', 'unpin', 'publish', 'remove', 'manage_alert'], FeedList, list => {
    let owner = list.owner ? FeedListOwner.get(list.owner) : null
    let membership = owner && owner.membership ? Membership.get(owner.membership) : null

    return membership instanceof Membership && owner instanceof Establishment && membership.elevation === 'owner'
  })

  // Gérer le mur d'un espace
  can(['pin', 'unpin', 'publish', 'remove', 'manage_alert'], FeedList, list => {
    let owner = list.owner ? FeedListOwner.get(list.owner) : null
    let membership = owner && owner.membership ? Membership.get(owner.membership) : null

    return membership instanceof Membership && owner instanceof Space && ['manager', 'owner'].includes(membership.elevation)
  })

  // Publié sur le mur d'une team
  can('publish', FeedList, list => {
    let owner = list.owner ? FeedListOwner.get(list.owner) : null
    let membership = owner && owner.membership ? Membership.get(owner.membership) : null

    return membership instanceof Membership && owner instanceof Team && owner.status === 'active' && ['member', 'manager', 'owner'].includes(membership.elevation)
  })

  // Pin/unpin sur le mur d'une team
  can(['pin', 'unpin', 'remove', 'manage_alert'], FeedList, list => {
    let owner = list.owner ? FeedListOwner.get(list.owner) : null
    let membership = owner && owner.membership ? Membership.get(owner.membership) : null

    return membership instanceof Membership && owner instanceof Team &&
      ['manager', 'owner'].indexOf(membership.elevation) > -1
  })

  // Publié sur le mur d'un etablissement
  can(['publish', 'pin', 'unpin', 'remove', 'manage_alert'], FeedList, list => {
    let owner = list.owner ? FeedListOwner.get(list.owner) : null
    return owner instanceof Establishment && Array.isArray(owner.managers) && owner.managers.indexOf(user.sub) >= 0
  })
}
