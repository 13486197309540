<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <!--Définit le parent comme scroll target sinon l'offset de déclenchement n'est pas calculé correctement :
 la hauteur de l'infinite scroll ne doit pas être limité car elle doit être supérieur à son scroll target
 Les classes permettant de définit le container sont : .scroll,.scroll-y,.overflow-auto  -->
  <div ref="container" class="overflow-auto pa-2" :class="$vuetify.breakpoint.mdAndDown ? 'message-container-mobile' : 'message-container'">
    <q-infinite-scroll
      v-if="!loading"
      reverse
      :debounce="500"
      :offset="500"
      @load="onLoad"
    >
      <!--Avoir le loader ici permet d'éviter à la scrollbar de bouger quand l'infinite scroll se lance/se termine-->
      <template #loading>
        <v-skeleton-loader type="list-item-two-line,list-item-two-line,list-item-two-line,list-item-two-line" />
        <v-skeleton-loader type="list-item-two-line,list-item-two-line,list-item-two-line,list-item-two-line" />
      </template>

      <!--Ne retourner que les messages, sinon le loader se place en dessous -->
      <div class="d-flex flex-column-reverse">
        <template v-for="(item, index) in items">
          <messages-list-item
            :key="item.id"
            class="messenger-message"
            :message="item"
            :first-of-streak="isFirstOfStreak(index)"
            :last-of-streak="isLastOfStreak(index)"
          />
          <small v-if="isOld(index)" :key="'date-'+index" class="text-center d-block op-40 my-3">
            {{ smartDateDisplay(item.creation_date) }}
          </small>
        </template>
      </div>
    </q-infinite-scroll>
    <div v-else class="chat-content overflow-y-auto">
      <v-skeleton-loader type="list-item-two-line,list-item-two-line,list-item-two-line,list-item-two-line" />
      <v-skeleton-loader type="list-item-two-line,list-item-two-line,list-item-two-line,list-item-two-line" />
    </div>
  </div>
</template>

<script>
  import MessagesListItem from '@/modules/messenger/components/messages/MessagesListItem'
  import { QInfiniteScroll } from 'quasar'
  import { Conversation } from '@/modules/messenger/models'
  import Messenger from '@/modules/messenger/api'
  import { emitMessagesRead } from '@/modules/messenger/websockets'
  import { receiveMessage } from '@/modules/messenger/api/messages'
  import { column } from '@/utils/array'

  const INTERVAL_BETWEEN_TWO_DATES = 1440 // 4 hours
  const LIMIT = 10

  export default {
    name: 'MessagesList',
    components: {MessagesListItem, QInfiniteScroll},
    props: {
      conversation: {
        type: Conversation,
        required: true
      }
    },
    data() {
      return {
        loading: true,
        items: [],
        total: null,
        endReached: false
      }
    },
    computed: {
      isOld() {
        return index => {
          let previousMessage = this.items[index - 1]
          let date1 = Date.parse(this.items[index].creation_date)
          if (!previousMessage) {
            return true
          }
          let date2 = Date.parse(this.items[index - 1].creation_date)
          return date2 - date1 > INTERVAL_BETWEEN_TWO_DATES * 1000 // in ms
        }
      },
      isLastOfStreak() {
        return index => {
          if (this.items[index - 1]) {
            return this.items[index].author && this.items[index - 1].author && this.items[index].author.uid !== this.items[index - 1].author.uid || this.isOld(index - 1)
          }
          return true
        }
      },
      isFirstOfStreak() {
        return index => {
          if (this.items[index + 1]) {
            return this.items[index].author && this.items[index + 1].author && this.items[index].author.uid !== this.items[index + 1].author.uid || this.isOld(index)
          }
          return true
        }
      }
    },
    async created() {
      await this.conversation.fetchFileList()
      this.$node.socket.on('message', this.onNewMessage)
      this.loading = false
    },
    beforeDestroy() {
      this.$node.socket.off('message', this.onNewMessage)
    },
    methods: {
      async onNewMessage(message) {
        if (message.conv_uid === this.conversation.uid) {
          this.addMessage(message)

          //If the current user is not the author : send to the api then emit on node that the message is read

          let isAuthor = message.author && message.author.uid === this.$auth.user.sub
          if (!isAuthor) {
            let {data} = await receiveMessage([message.id])
            emitMessagesRead(data)
          }
        }
      },
      addMessage(message) {
        if (column(this.items, 'id').includes(message.id)) {
          return
        }
        this.items.unshift(message)

        this.$nextTick(() => {
          this.$refs.container.scrollTo(0, this.$refs.container.scrollHeight) // scroll to bottom
        })
      },
      async onLoad(index, done) {
        let {data} = await Messenger.messages.list(this.conversation.uid, index, LIMIT)
        this.items = this.items.concat(data.list)
        let readMessagesTotal = data.list.filter(item => item.unread).length
        if (readMessagesTotal) {
          this.$store.commit('messenger/setTotalUnreadMessages', this.$store.state.messenger.totalUnreadMessages - readMessagesTotal)
          this.conversation.$mutate(conversation => conversation.total_unread_messages -= readMessagesTotal)
        }
        done(data.list.length < LIMIT)
      },

      smartDateDisplay(date) {
        let parsedDate = this.$dayjs.utc(date).local()
        if (parsedDate.isToday()) {
          return parsedDate.format('HH:mm')
        }

        let now = this.$dayjs()

        if (parsedDate.year() !== now.year()) {
          return parsedDate.format('ddd D MMM YYYY HH:mm')
        }
        if (parsedDate.month() !== now.month()) {
          return parsedDate.format('ddd D MMM HH:mm')
        }
        if (parsedDate.week() !== now.week()) {
          return parsedDate.format('ddd D MMM HH:mm')
        }
        return parsedDate.format('dddd HH:mm')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .messenger-message {
    &:first-of-type {
      animation: slideInOnce 0.2s ease;
    }
  }

  .message-container {
    min-height: 450px;
  }
  .message-container-mobile {
    min-height: calc(100% - 160px);
  }

  @keyframes slideInOnce {
    0% {
      transform: translateY(30px);
    }
    100% {
      transform: translateY(0px);
    }
  }
</style>
