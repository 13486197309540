/*
 * Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */


/**
 * How to use
 *
 * copy pasta || read code below
 *
 * //your code
 * ...
 * //import searchBar mixin
 * import searchBarMixin from "@/mixins/searchBar"
 *
 * export default {
 *   ...
 *   //use mixin
 *   mixins: [..., searchBarMixin],
 *   ...
 *   watch: {
 *     ...
 *     //watch terms property
 *     terms(val){
 *       //handle null value
 *       if(null === val){
 *         **do something cool**
 *       }
 *       //set the loading state to true
 *       this.setSearchBarLoading(true)
 *       **call your research here**
 *       //set the loader back to false
 *       this.setSearchBarLoading(false)
 *     }
 *   }
 * }
 *
 */
export default {

  namespaced: true,

  state: {
    terms: null,
    loading: false
  },

  getters: {

  },

  mutations: {
    setTerms(state, val){
      val = val || null
      state.terms = val
    },

    setLoading(state, loading){
      loading = loading || false
      state.loading = loading
    }
  },

  actions: {
    reset({commit}){
      commit('setTerms')
      commit('setLoading')
    }
  }

}
