/*
 * Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

export default {
    namespaced: true,
    state: {
        thread: []
    },
    getters: {},
    mutations: {},
    actions: {},
}
