import {Model} from '@/models'
import {FeedItemPublication, FeedItemPublicationSharing} from './index'

export class FeedItem extends Model {
  static schemaName = 'feed_items'
  static primaryKey = 'identifier'

  static schemaDefinition() {
    return {
      pinned: Boolean
    }
  }

  static types() {
    return {
      publication: FeedItemPublication,
      publication_sharing: FeedItemPublicationSharing
    }
  }
}
