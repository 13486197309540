/*
 * Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

const defaultFeatures = ['HELP-PANEL']

export const features = [
  {
    name: 'Covid form',
    canonical: 'COVID-FORM',
    description: 'Formulaire covid spécifique Mipih'
  },
  {
    name: 'Adresser un patient via Medimail',
    canonical: 'MEDIMAIL-PATIENT-REFERAL',
    description: 'Permet d\'adresser un patient à un professionnel de santé via Medimail, spécifique Mipih'
  },
  {
    name: 'Recherche en index',
    canonical: 'MEDILIEN-HOME-SEARCH',
    description: 'Définit la recherche comme page d\'index'
  },
  {
    name: 'Recherche Médecins uniquement',
    canonical: 'MEDILIEN-MD-SEARCH',
    description: 'Reduit la possibilité de recherche exclusivement aux médecins, spécifique Mipih'
  },
  {
    name: 'Implémentation Mediateam',
    canonical: 'MEDIATEAM',
    description: 'Ajoute les fonctionnalités de gestion de patient et de guichet d\'orientation de Mediateam'
  },
  {
    name: 'Management des espaces (debug)',
    canonical: 'SPACE-MANAGEMENT',
    description: 'Permet le management des espaces pour le developpement'
  },
  {
    name: 'Panneau d\'acceuil',
    canonical: 'WELCOME-PANEL',
    description: 'Affiche un panneau de bienvenue au lancement de l\'application'
  },
  {
    name: 'Panneau d\'aide',
    canonical: 'HELP-PANEL',
    description: 'Affiche un panneau d\'aide sur la topbar'
  },
  {
    name: 'Signature du consentement',
    canonical: 'CONSENT-SIGNATURE',
    description: 'Affiche le bouton permettant la signature d\'un consentement dans un groupe orienté patient'
  }
]

export function retrieveStoredFeatures() {
  let featuresList =  JSON.parse(localStorage.getItem('settings:features'))
  featuresList = featuresList === null ? defaultFeatures :  featuresList
  return featuresList
}
