/*
 * Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
export function randomInt(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

/**
 * Copy an array, shuffle and return it
 * @param a
 * @returns {*}
 */
export function shuffleArray(a) {
  if (Array.isArray(a)) {
    throw new Error('Parameter array must be an array')
  }

  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]]
  }
  return a
}

/**
 * Return a number between min and max of random items of array with or without duplicated items
 * @param array
 * @param min
 * @param max
 * @param duplicate
 * @returns {*}
 */
export function randomItemsFromArray(array, min, max, duplicate = true) {
  if (!Array.isArray(array)) {
    throw new Error('Parameter array must be an array')
  }

  if (!Number.isInteger(min)) {
    throw new Error('Parameter min must be an integer')
  }

  let size = min
  if (Number.isInteger(max) && max > min) {
    size = randomInt(min, max)
  }

  if (!duplicate) {
    return shuffleArray(Array.from(array)).slice(0, size)
  }

  let items = []
  for (let i = 0; i < size; ++i) {
    items.push(array[randomInt(0, array.length - 1)])
  }

  return items
}

export function randomIdentifier() {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
}

export function generateToken(n) {
  let chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  let token = ''
  for (let i = 0; i < n; i++) {
    token += chars[Math.floor(Math.random() * chars.length)]
  }
  return token
}
