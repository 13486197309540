export default {
  bind(el, binding) {
    el.addEventListener('click', (e) => {
      e.stopPropagation()
      window.location = binding.value
    })
  },
  unbind(el) {
    el.removeEventListener('click')
  }
}
