import {Establishment} from '@/models'

export default function (user, can) {
  if (!user) {
    return
  }

  can(['update_members', 'update_managers'], Establishment, establishment => {
    return establishment.managers && establishment.managers.indexOf(user.sub) >= 0
  })

  can('leave', Establishment, establishment => {
    if(establishment.managers) {
      // Il existe un manager qui n'est pas l'user courant
      for (let manager of establishment.managers) {
        if (user.sub !== manager) {
          return true
        }
      }
    }
    return false
  })
}
