<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-avatar item :size="size" v-bind="$attrs">
    <user-avatar-img :user="user" />
    <div v-if="showOnlineStatus" class="online-status" :class="'online-status--' + (onlineStatus ? 'online' : 'offline')" />
  </v-avatar>
</template>

<script>
  import UserAvatarImg from '@/modules/core/layout/UserAvatarImg'

  /**
   * Wrapper de l'avatar d'un utilisateur
   */
  export default {
    name: 'UserAvatar',
    components: {UserAvatarImg},
    props: {
      user: Object,
      size: {
        type: [Number, String],
        value: 32
      },
      showOnlineStatus: Boolean
    },
    computed: {
      onlineStatus() {
        return false // todo
      }
    }
  }
</script>

<style lang="scss" scoped>
  .online-status {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 25%;
    width: 25%;
    border-radius: 100%;
    border: 2px solid #fff;
  }

  .online-status--online {
    background-color: #00af5d;
  }

  .online-status--offline {
    background-color: #af0f00;
  }
</style>
