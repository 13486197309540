/*
 * <!--Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential-->
 */
import {deepCopy} from '@/utils/object'
import {File} from '@/models'
import FileListMixin from '@/modules/files/mixins/file-list'

export default {
  mixins: [FileListMixin],

  data(){
    return {
      model: deepCopy(File.model)
    }
  }
}