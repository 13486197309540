/*
 * <!--Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential-->
 */

import Vue from 'vue'

export const UPLOADING_STATUS = {
  pending: 'pending',
  processing: 'processing',
  done: 'done'
}

export default {
  namespaced: true,

  state: {
    lastId: -1,
    items: []
  },

  getters: {
    cget: state => on => {
      return state.items.filter(v => on === v.data.on)
    },

    getPending: state => on => {
      return state.items.filter(v => on === v.data.on && UPLOADING_STATUS.pending === v.clientData.status)
    },

    getByIds: state => ids => {
      return state.items.filter(v => ids.indexOf(v.clientData.id) !== -1)
    },

    getByStatus: state => (status, on) => {
      return state.items.filter(v => on === v.data.on && UPLOADING_STATUS[status] === v.clientData.status)
    }
  },

  mutations: {
    add(state, item){
      item.clientData = {
        id: ++state.lastId,
        progress: 0,
        status: UPLOADING_STATUS.pending
      }

      state.items.push(item)
      return item
    },

    setStatus(state, {id, status}){
      const item = state.items.find(v => v.clientData.id === id)

      if(!item){
        throw new Error('Pending item not found.')
      }

      item.clientData.status = status
    },

    setUid(state, {id, uid}){
      const item = state.items.find(v => v.clientData.id === id)

      if(!item){
        throw new Error('Pending item not found.')
      }

      item.data.uid = uid
    },

    setUploadProgress(state, {id, progress}){
      const item = state.items.find(v => id === v.clientData.id)

      item.clientData.progress = progress
    },

    remove(state, id) {
      let index = state.items.findIndex(val => id === val.clientData.id)

      if (-1 !== index) {
        Vue.delete(state.items, index)
        return
      }

      throw new Error('Pending item not found.')
    }
  }
}
