/*
 * Copyright (C)  E-Synaps SAS - 2019 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import { Ability, detectSubjectType } from '@casl/ability'

let APPNAME = 'Medical Space'

/** Import base CSS **/
import '@/styles/app.scss'
/** Import Vue and its compnents **/
import Vue from 'vue'
import VueWorker from 'vue-worker'
import VueZoomer from 'vue-zoomer'
import VuePageTransition from 'vue-page-transition'
import ConfirmLeave from '@/plugins/confirm-leave'
import config from '@/config'
import node from '@/plugins/node'
import auth from '@/plugins/auth'
import VOffline from 'v-offline'

import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'

import vuetify from '@/plugins/vuetify'
import '@/plugins/dayjs'
import App from './App'
import PublicationViewver from '@/modules/feeds/components/FeedPublicationViewer'
import location from '@/directives/location'
import store from './store'
import router from './router/router'
import './registerServiceWorker'
import { setBuildDate } from '@/utils/storage'
import VueTour from 'vue-tour'
import './filters/byteConverter'
import FeatureFlipping from 'vue-feature-flipping'
import '@/plugins/google'
import { abilitiesPlugin } from '@casl/vue'
import { getRulesFor } from '@/services/ability'

import 'vue-tour/dist/vue-tour.css'
import { Model, Space } from '@/models'
import { rules } from '@/services/rules'
import { init as messengerWebsockets } from '@/modules/messenger/websockets'

setBuildDate(process.env.BUILD_DATE)

Vue.use(VuetifyDialog, {
  context: {
    vuetify,
    store,
    router,
    confirm: {
      actions: {
        false: 'Non',
        true: {
          text: 'Oui',
          color: 'primary'
        }
      },
      icon: false, // to disable icon just put false
      width: 500
    },
    warning: {},
    error: {},
    prompt: {},
    info: {}
  }
})
Vue.use(VueWorker)
Vue.use(VueZoomer)
Vue.use(FeatureFlipping)
Vue.use(VuePageTransition)
Vue.use(ConfirmLeave, { router })
Vue.use(VueTour)
Vue.use(auth, {
  ...config.oauth,
  onRedirectCallback: async appState => {
    try {
      await router.replace({ path: appState && appState.targetUrl ? appState.targetUrl : window.location.pathname })
    } catch (e) {
      // Ignore it
    }
  }
})

Vue.use(node)

Vue.use(abilitiesPlugin, new Ability(getRulesFor(), {
  detectSubjectType: subject => {
    if (subject) {
      if (typeof subject === 'function' && Object.prototype.isPrototypeOf.call(Model, subject) && subject.schemaName) {
        // Support can('x', Model) where Model is a class
        return subject.schemaName
      } else if (typeof subject === 'object' && subject instanceof Model && subject.constructor.schemaName) {
        // Support can('x', model) where model is an instance
        return subject.constructor.schemaName
      }
    }

    return detectSubjectType(subject)
  }
}))

Vue.component('VOffline', VOffline)
Vue.component('PublicationViewver', PublicationViewver)

Vue.directive('location', location)
Vue.directive('focus', focus)

Vue.filter('pluralize', (word, amount) => (amount > 1 || amount === 0) ? `${word}s` : word)

Vue.prototype.$appName = APPNAME
Vue.prototype.$rules = rules

Vue.prototype.$handleApiError = (error => {
  // eslint-disable-next-line no-console
  console.error(error)
  let text = 'Une erreur est survenue'
  if (typeof error.response !== 'undefined') {
    text = error.response.data.detail || error.response.data.title
  }
  store.commit('layout/openSnackbar', {
    text,
    color: 'error'
  })
})

/**
 * Generating main frame SPA view
 */
const app = new Vue({
  el: '#app',
  name: 'Root',
  components: { App },
  data() {
    return {
      loading: true
    }
  },
  created() {
    messengerWebsockets(this.$node.socket)
  },
  render: (h) => h(App),
  router,
  vuetify,
  iconfont: 'faSvg',
  store
})

store.$app = app

store.watch(() => store.state.core.space, (spaceUid) => {
  if (spaceUid && Space.get(spaceUid) && Space.get(spaceUid).theme.data) {
    let theme = Space.get(spaceUid).theme.data

    Object.keys(theme.dark).forEach(i => {
      app.$vuetify.theme.themes.dark[i] = theme.dark[i]
    })

    Object.keys(theme.light).forEach(i => {
      app.$vuetify.theme.themes.light[i] = theme.light[i]
    })
  }
})

export default app
