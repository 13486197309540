/*
 * Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

export function deepCopy(object) {
  return JSON.parse(JSON.stringify(object))
}

export function shallowCopy(object) {
  return {...object}
}

export function pick(object, array) {
  let result = {}
  for (let key of array) {
    if (typeof object[key] !== 'undefined') {
      result[key] = object[key]
    }
  }
  return result
}

/**
 * Call it with and empty formData and your object
 *
 * @param formData
 * @param data
 * @param _key
 */
export function objectToFormData(formData, data, _key) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      objectToFormData(formData, data[key], _key ? `${_key}[${key}]` : key)
    })
  } else {
    const value = data == null ? '' : data

    formData.append(_key, value)
  }
}

export function getLastIndex (obj) {
  return Object.keys(obj)[Object.keys(obj).length - 1]
}

export function objectFromArray (array, key) {
  const initialValue = {}
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item
    }
  }, initialValue)
}

