<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-navigation-drawer class="messenger-convo-bar is-scroll-target" clipped fixed right :stateless="$vuetify.breakpoint.mdAndDown"
                       :class="$vuetify.breakpoint.mdAndDown ? 'messenger-mobile' : 'messenger-desktop'"
                       :width="$vuetify.breakpoint.mdAndDown ? '100%' : '360'"
                       :value="$store.state.messenger.layout.sidebar"
  >
    <div class="d-flex align-center pr-4" :class="$vuetify.breakpoint.mdAndDown ? 'primary white--text' : ''">
      <v-card-title class="font-alt">Messagerie</v-card-title>
      <v-spacer />
      <v-btn v-if="$vuetify.breakpoint.mdAndDown" small icon dark @click="$store.commit('messenger/layout/toggleSideBar')">
        <v-icon>$close</v-icon>
      </v-btn>
    </div>
    <v-subheader>Trouver une discussion</v-subheader>
    <conversations-search-input ref="searchInput" class="px-2" @input="contacts = $event" />
    <v-divider class="my-1" />
    <v-subheader>Mes conversations</v-subheader>
    <messenger-contacts-list v-if="contacts" :items="contacts" @select="onContactSelect" />

    <q-infinite-scroll
      ref="infiniteScroll"
      :offset="600"
      scroll-target=".v-navigation-drawer__content"
      @load="onLoad"
    >
      <template #loading>
        <v-skeleton-loader v-for="i in 8" :key="'idx-msg-'+i" type="list-item-avatar-two-line" />
      </template>

      <v-list two-line nav>
        <v-list-item-group color="primary">
          <conversations-list-item v-for="uid in conversationsUid" :key="uid" :uid="uid" />
        </v-list-item-group>
      </v-list>
    </q-infinite-scroll>
    <empty-list v-if="!contacts && Array.isArray(conversationsUid) && !conversationsUid.length" title="Aucun résultat"
                subtitle="Ajouter des contacts pour démarrer une discussion"
    />
  </v-navigation-drawer>
</template>

<script>
  import EmptyList from '@/modules/core/layout/EmptyList'
  import ConversationsSearchInput from '@/modules/messenger/components/conversations/ConversationsSearchInput'
  import { list as listConversations, getByParticipant } from '@/modules/messenger/api/conversations'
  import { Conversation } from '@/modules/messenger/models'
  import MessengerContactsList from '@/modules/messenger/components/contacts/MessengerContactsList'
  import ConversationsListItem from '@/modules/messenger/components/conversations/ConversationsListItem'
  import { column } from '@/utils/array'
  import { QInfiniteScroll } from 'quasar'

  export default {
    name: 'MessengerSideBar',
    components: {
      ConversationsListItem,
      MessengerContactsList,
      EmptyList,
      ConversationsSearchInput,
      QInfiniteScroll
    },
    props: {
      itemsPerPage: {
        type: Number,
        default: 10
      }
    },
    data() {
      return {
        contacts: null,
        conversationsUid: null
      }
    },
    methods: {
      async onLoad(index, done) {
        try {
          let data = (await listConversations(index, this.itemsPerPage)).data
          Conversation.save(data.list)
          if(this.conversationsUid === null) {
            this.conversationsUid = []
          }
          this.conversationsUid = this.conversationsUid.concat(column(data.list, 'uid'))
          this.total = data.total
          done(this.conversationsUid.length >= this.total)
        }
        catch (e) {
          done(!await this.$confirm('Une erreur est survenue', 'retry'))
          throw e
        }
      },
      async onContactSelect(uid) {
        let {data} = await getByParticipant(uid)
        Conversation.save(data)
        this.$store.commit('messenger/layout/openChatWindow', data.uid)

        this.contacts = null
        this.$refs.searchInput.clear()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .messenger-convo-bar.messenger-desktop {
    box-shadow: 3px 5px 5px -3px rgba(0, 0, 0, 0.09), 5px 8px 10px 1px rgba(0, 0, 0, 0.04), 2px 3px 14px 2px rgba(0, 0, 0, 0.04) !important;
    padding-top: 64px;
  }
  .messenger-mobile {
    z-index: 12 !important;
  }
</style>
