<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-fade-transition hide-on-leave>
    <v-form v-if="!loading" ref="form" class="pa-3">
      <v-container fluid>
        <form-category title="Informations générales" subtitle="Mon identité" class="mt-1 mb-4" />
        <v-select v-model="user.title"
                  label="Civilité"
                  placeholder="Séléctionnez une civilité"
                  :items="availableTitles"
        />

        <v-text-field
          v-model="user.first_name"
          label="Prénom"
          :rules="[rules.required,rules.minLength(2), rules.maxLength(28)]"
        />

        <v-text-field
          v-model="user.last_name"
          label="Nom de famille"
          :rules="[rules.required, rules.minLength(2), rules.maxLength(28)]"
        />

        <v-text-field
          v-model="user.maiden_name"
          label="Nom d'usage"
          :rules="[rules.minLength(2), rules.maxLength(28)]"
        />

        <v-select v-model="user.gender" label="Genre" :items="availableGenders" />
        <form-category title="Informations de contact"
                       subtitle="Mon e-mail et mes numéros de téléphone" class="mt-4 mb-4"
        />
        <v-text-field
          v-model="user.email"
          label="Adresse e-mail"
          :rules="[rules.required, rules.email]"
        />
        <phone-input v-model="user.phone" label="Numéro de téléphone" required />
        <phone-input v-model="user.mobile" label="Numéro de mobile" required />
      </v-container>

      <div class="my-3 text-center">
        <v-btn small :disabled="updating" @click="goBack">Retour</v-btn>
        <v-btn small class="ml-2" color="primary" :loading="updating" @click="submit">Enregistrer</v-btn>
      </div>
    </v-form>
    <v-container v-else fluid>
      <v-skeleton-loader class="mt-4" type="article, list-item-two-line, list-item-two-line, list-item-two-line" />
      <v-skeleton-loader class="mt-6" type="article, list-item-two-line, list-item-two-line" />
    </v-container>
  </v-fade-transition>
</template>

<script>
  import PhoneInput from '@/modules/core/components/PhoneInput'
  import {rules} from '@/services/rules'
  import FormCategory from '@/modules/core/layout/FormCategory'
  import {update} from '@/modules/user/api'
  import {Person} from '@/models'

  export default {
    name: 'UserInformations',
    components: {FormCategory, PhoneInput},
    data() {
      return {
        rules,
        user: null,
        loading: false,
        updating: false,
        availableTitles: ['', 'Dr.', 'Pr.', 'M.', 'Mme.'],
        availableGenders: [
          {text: 'Homme', value: 'male'},
          {text: 'Femme', value: 'female'}
        ]
      }
    },
    async created() {
      try {
        this.loading = true
        await this.$store.dispatch('core/fetchUser', this.$auth.user.sub)
        let fetchedUser = Person.get(this.$auth.user.sub)

        this.user = {
          first_name: fetchedUser.first_name,
          last_name: fetchedUser.last_name,
          maiden_name: fetchedUser.maiden_name,
          gender: fetchedUser.gender,
          title: fetchedUser.title,
          email: fetchedUser.email,
          phone: fetchedUser.phone,
          mobile: fetchedUser.mobile
        }
      } catch (e) {
        this.$handleApiError(e)
      } finally {
        this.loading = false
      }
    },
    methods: {
      goBack() {
        this.$emit('go-back')
      },
      async submit() {
        if (!this.$refs.form.validate()) {
          return
        }
        this.updating = true
        try {
          let {data} = await update(this.user)
          Person.save(data)
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'success',
            title: 'Informations enregistrées',
            subtitle: 'Informations enregistrées avec succès.'
          })

          if (data.email !== this.$auth.user.email) {
            this.$auth.user.email = data.email
          }
          this.$auth.user.name = data.full_name
          localStorage.setItem('user', JSON.stringify(this.$auth.user))
        } catch (e) {
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue',
            subtitle: 'Une erreur est survenue veuillez réessayer.'
          })
        } finally {
          this.updating = false
        }
      }
    }
  }
</script>
