import {Comment, CommentsSection} from '@/models'

export default function (user, can) {
  if (!user) {
    return
  }

  can('comment', CommentsSection)
  can('update', Comment, {
    author: user.sub,
    deleted: false
  })
  can('delete', Comment, {
    author: user.sub,
    deleted: false
  })
}
