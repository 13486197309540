/*
 * Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import * as Notifications from '@/api/notifications'

export default {
  namespaced: true,
  state: {
    limit: 10,
    unreadTotal: 0,
    items: [],
    panelOpen: false
  },
  getters: {},
  mutations: {
    setUnreadTotal(state, value) {
      state.unreadTotal = value
    },
    incrementUnreadTotal(state) {
      ++state.unreadTotal
    },
    reset(state) {
      state.unreadTotal = 0
      state.items = []
    },
    prependItem(state, item) {
      if (!state.items.find(current => current.id === item.id)) {
        state.items.unshift(item)
      }
    },
    prependItems(state, items) {
      for (let item of items) {
        if (!state.items.find(current => current.id === item.id)) {
          state.items.unshift(item)
        }
      }
    },
    appendItems(state, items) {
      for (let item of items) {
        if (!state.items.find(current => current.id === item.id)) {
          state.items.push(item)
        }
      }
    },
    setItemsRead(state) {
      for (let item of state.items) {
        if (!item.read) {
          item.read = true
          --state.unreadTotal
        }
      }
    },
    setPanelOpen(state, value) {
      state.panelOpen = value
    }
  },
  actions: {
    async fetchUnreadTotal({commit}) {
      let total = (await Notifications.getUnreadTotal()).data
      commit('setUnreadTotal', total)
      return total
    },
    async loadNextPage({state, commit}) {
      let page = Math.floor(state.items.length / state.limit) + 1
      let items = (await Notifications.cget(page, state.limit)).data

      commit('appendItems', items)

      return items.length < state.limit
    },
    async removeAll({commit}) {
      await Notifications.removeAll()
      commit('reset')
    }
  }
}
