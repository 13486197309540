<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-list-item v-bind="$attrs" v-on="$listeners">
    <v-list-item-avatar>
      <user-avatar-img :user="item" />
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title>
        {{ item.full_name }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ item.last_message ? item.last_message.message : '' }}
      </v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action v-if="item.total_unread_messages" class="whap-messenger-newMessage rounded elevation-x">
      <v-chip x-small class="ma-0" color="secondary" text-color="white">{{ item.total_unread_messages }}</v-chip>
    </v-list-item-action>
  </v-list-item>
</template>

<script>

  import UserAvatarImg from '@/modules/core/layout/UserAvatarImg'
  export default {
    name: 'MessengerContactsListItem',
    components: {UserAvatarImg},
    props: {
      item: {
        required: true,
        type: Object
      }
    }
  }
</script>
