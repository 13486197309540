import {EstablishmentMembership, FeedList, Gang} from '@/models'
import * as Establishments from '@/modules/establishments/api'
import {removeItem} from '@/utils/array'
import config from '@/config'

export class Establishment extends Gang {
  static schemaName = 'establishments'

  static schemaDefinition() {
    return {
      ...super.schemaDefinition(),
      membership: EstablishmentMembership, // current user membership
      managers: [EstablishmentMembership]
    }
  }

  static async fetchOne(uid) {
    let establishment = this.get(uid)

    if (!establishment || 'undefined' === typeof establishment.membership) {
      let {data} = await Establishments.get(uid)
      if(data.membership && data.membership.user) {
        data.membership.uid  = data.membership.user.uid
      }
      this.save(data)
      establishment = this.get(uid)
    }
    return establishment
  }

  async fetchManagers() {
    if ('undefined' === typeof this.managers) {
      Establishment.save({uid: this.uid, managers: (await Establishments.listMembers(this.uid, 1, -1, true)).list})
    }
    return this.managers
  }

  async fetchFeedList() {
    if (!this.feedList) {
      let feedList = (await Establishments.getFeedList(this.$store().state.core.space, this.uid)).data

      FeedList.save({...feedList, owner: this.uid})
      Establishment.save({
        uid: this.uid,
        type: 'establishment',
        feedList
      })
    }

    return FeedList.get(this.feedList)
  }

  async leave() {
    if (typeof this.membership === 'undefined') {
      throw new Error('Establishment must be fetch before user can leave')
    }

    await Establishments.removeMember(this.uid, this.membership)
    window.location = config.urls.homepage
  }

  async invite(userUid) {
    let {data} = await Establishments.addMember(this.uid, userUid)
    EstablishmentMembership.save({
      uid: userUid,
      establishment: this.uid,
      ...data
    })
    this.$mutate(establishment => {
      establishment.memberships.push(userUid)
    })
  }

  async remove(userUid) {
    await Establishments.removeMember(this.uid, userUid)
    this.$mutate(establishment => {
      removeItem(establishment.memberships, userUid)
    })
    EstablishmentMembership.delete(userUid)
  }
}
