<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-select
    label="Type"
    :items="items"
    :value="value"
    @change="onChange"
  />
</template>

<script>
  export default {
    name: 'FileTypeSelectField',
    props: {
      value: {
        type: String,
        default: null
      }
    },

    data() {
      return {
        items: ['inconnu', 'conclusion', 'imagerie', 'biologie']
      }
    },
    methods: {
      onChange(val) {
        this.$emit('input', val)
      }
    }
  }
</script>
