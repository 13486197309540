<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-card id="c-notification-view" class="elevation-x">
    <v-list-item dense>
      <v-list-item-title class="subtitle-2 op-80">
        Mes notifications
      </v-list-item-title>
      <v-list-item-action class="my-1 ml-1">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn color="red lighten-2" x-small class="delete-all" :loading="removingAll" v-on="on" @click="tryRemoveAll">
              <font-awesome-icon size="lg" class="white--text" :icon="['fal', 'trash']" />
            </v-btn>
          </template>
          <span>Tout supprimer</span>
        </v-tooltip>
      </v-list-item-action>
    </v-list-item>
    <v-divider />
    <notifications-list :active="panelOpen" />
  </v-card>
</template>

<script>
  import {mapActions, mapState} from 'vuex'
  import NotificationsList from './NotificationsList'

  export default {
    name: 'NotificationsDropdown',
    components: {NotificationsList},
    data() {
      return {
        removingAll: false,
        isMobile: false
      }
    },
    computed: {
      ...mapState('notifications', ['panelOpen', 'unreadTotal'])
    },
    methods: {
      ...mapActions('notifications', ['fetchUnreadTotal', 'removeAll']),

      async tryRemoveAll() {
        if (!await this.$confirm('Êtes vous sûr de vouloir supprimer toutes vos notifications ?')) return

        this.removingAll = true

        try {
          await this.removeAll()

          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'success',
            title: 'Notifications supprimées',
            subtitle: 'Les notifications ont été supprimées avec succès.'
          })

        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e)
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'error',
            title: 'Notifications supprimées',
            subtitle: 'Les notifications n\'ont pas pu être été supprimées.'
          })
        }

        this.removingAll = false
      }
    }
  }
</script>
<style lang="scss" scoped>
    #c-notification-view {
        width: 400px;
    }
</style>

