import {Person, Model, Establishment} from '../index'
import * as Establishments from '@/modules/establishments/api'
import {removeItem} from '@/utils/array'

export class EstablishmentMembership extends Model {
  static schemaName = 'establishment_memberships'

  static schemaDefinition() {
    return {
      establishment: Establishment,
      user: Person
    }
  }

  async setManager(value = true) {
    if (value) {
      await Establishments.addManager(this.establishment, this.user)
    } else {
      await Establishments.removeManager(this.establishment, this.user)
    }

    this.$mutate(m => {
      m.elevation = value ? 'manager' : 'member'
    })

    Establishment.get(this.establishment).$mutate(e => {
      if (value) {
        e.managers.push(this.uid)
      } else {
        removeItem(e.managers, this.uid)
      }
    })
  }
}
