<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <div>
    <div class="text-h6 font-weight-regular lh-125 text-uppercase mb-0 primary--text">{{ title }}</div>
    <div v-if="subtitle" class="text-caption font-alt secondary--text mb-3">{{ subtitle }}</div>
  </div>
</template>

<script>
  export default {
    name: 'FormCategory',
    props: {
      title: String,
      subtitle: String
    }
  }
</script>
