<template>
  <v-dialog v-if="modal" :content-class="confirmDialog ? 'confirm-dialog' : ''" :fullscreen="$vuetify.breakpoint.mdAndDown"
            :value="true" persistent transition="dialog-bottom-transition" max-width="600" scrollable
  >
    <component :is="modal" v-bind="props" :confirm="confirmDialog" :title="title" :subtitle="subtitle" :class="$vuetify.theme.dark ? 'dark-dialog' :'light-dialog'" @close="close" />
  </v-dialog>
</template>

<script>
  import {mapMutations} from 'vuex'

  export default {
    name: 'ModalsContainer',
    data() {
      return {
        modal: null,
        props: null,
        title: null,
        subtitle: null,
        callback: null,
        confirmDialog: {
          type: String
        }
      }
    },
    created() {
      this.$root.$on('modal:open', async ({modal, props, callback, confirmDialog, title, subtitle}) => {
        this.modal = modal
        this.props = props
        this.callback = callback
        this.confirmDialog = confirmDialog
        this.title = title
        this.subtitle = subtitle
        this.toggleModal(true)
      })
    },
    methods: {
      ...mapMutations('layout', ['toggleModal']),
      async close(value) {
        if (typeof this.callback === 'function') {
          await Promise.resolve(this.callback(value))
        }
        this.modal = null
        this.props = null
        this.callback = null
        this.toggleModal(false)
      }
    }
  }
</script>
