<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-list-item ref="item" three-line class="notification" :class="{alert, unread: !notification.read}"
               @click="trigger(notification)"
  >
    <v-list-item-avatar tile size="32">
      <font-awesome-icon fixed-width size="1x" :icon="['fad', icon]" class="grey--text text--darken-1" />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title class="overline op-60" v-text="title" />
      <v-list-item-subtitle style="white-space: initial">
        <notification-parser :notification="notification.content" />
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        <base-date class="caption op-60" :date="notification.sending_date" />
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
  import NotificationParser from './NotificationParser'
  import BaseDate from '../../core/components/BaseDate'

  export default {
    name: 'NotificationsListItem',
    components: {BaseDate, NotificationParser},
    props: {
      notification: Object
    },
    data() {
      return {}
    },
    computed: {
      title() {
        let title = ''
        switch (this.notification.type) {
          case 'user':
            title = 'Général'
            break
          case 'patient':
            title = 'Patient'
            break
          case 'team':
            title = 'Groupe'
            break
          case 'document':
            title = 'Document'
            break
        }
        return title
      },
      alert() {
        return this.notification.importance === 'high'
      },
      icon() {
        if (this.alert) {
          return 'exclamation-triangle'
        }
        return this.notification.read ? 'envelope-open' : 'envelope'
      }
    },
    methods: {
      trigger(notification) {
        if (notification.isAlert) {
          this.$store.commit('overlay/open', {
            component: 'PublicationViewver',
            props: notification.data
          })

        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .notification {
    &.unread {
      i {
        background-color: #34afe4;
        color: #ffffff;
      }
    }

    &.alert {
      background-color: #fdd3db;
      color: #e81444;
      border-color: #f7b1b1;

      i {
        background-color: #f5394c;
      }
    }
  }
</style>
