import {FileList, Model, Person} from '@/models'
import {Message} from '@/modules/messenger/models'
import {formatParticipantsName} from '@/modules/messenger/utils'
import {promiseMemoize} from '@/utils/promise'
import {fetchFileList} from '@/modules/messenger/api/conversations'

export class Conversation extends Model {
  static schemaName = 'conversations'

  static schemaDefinition() {
    return {
      uid: String,
      title: String,
      total_unread_messages: Number,
      is_group: Boolean,
      last_message: Message,
      file_list: FileList,
      participants: [Person]
    }
  }

  constructor() {
    super()
    this.fetchFileList = promiseMemoize(this.fetchFileList)
  }

  getTitle() {
    return this.is_group && this.title ? this.title : formatParticipantsName(this.participants.map(uid => Person.get(uid)))
  }

  async fetchFileList() {
    if (!this.feedList) {
      Conversation.save({
        uid: this.uid,
        file_list: (await fetchFileList(this.uid)).data
      })
    }

    return FileList.get(this.file_list)
  }

}
