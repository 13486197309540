<template>
  <div ref="msgcontainer" class="messenger-frame">
    <messenger-side-bar />
    <messenger-frame v-for="(chatWindow, index) in chatWindows"
                     :key="index"
                     :conversation-uid="chatWindow.uid"
    />
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import MessengerSideBar from '@/modules/messenger/views/MessengerSideBar'
  import MessengerFrame from '@/modules/messenger/components/windowed/MessengerFrame'

  export default {
    name: 'MessengerContainer',
    components: {MessengerFrame, MessengerSideBar},
    computed: {
      ...mapState('messenger/layout', ['chatWindows'])
    }
  }
</script>
