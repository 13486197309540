<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-container fluid>
    <v-banner two-line>
      <v-avatar slot="icon" color="warning" size="40">
        <font-awesome-icon class="white--text" size="2x" :icon="['fal', 'exclamation-circle']" />
      </v-avatar>
      Si vous mettez à jour votre mot de passe, vous serez automatiquement déconnecté(e) de l'application.
      Vous devrez ainsi vous reconnecter avec vos nouveaux identitifants
    </v-banner>
    <v-form ref="form" lazy-validation class="pa-3 px-md-6">
      <form-category title="Changement de mot de passe"
                     subtitle="Modifier votre mot de passe actuel" class="mt-4 mb-4"
      />
      <v-text-field
        v-model="password.current"
        :append-icon="showOriginalPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
        :type="showOriginalPassword ? 'text' : 'password'"
        hint="Mot de passe incorrect"
        label="Mon mot de passe actuel"
        :rules="[rules.required]"
        @click:append="showOriginalPassword = !showOriginalPassword"
      />

      <v-text-field
        v-model="password.new"
        :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
        :type="showPassword ? 'text' : 'password'"
        :rules="[rules.required, rulePasswordNotSame]"
        label="Mon nouveau mot de passe"
        hint="Doit contenir au moins 8 caractères dont 1 chiffre, 1 minuscule et 1 majuscule, sans caractères spéciaux"
        persistent-hint
        @click:append="showPassword = !showPassword"
      />

      <v-text-field
        v-model="password.confirm_new"
        class="mt-5"
        :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
        :type="showPassword ? 'text' : 'password'"
        :rules="[rules.required, rulePasswordSameConfirm]"
        label="Confirmer mon nouveau mot de passe"
        persistent-hint
        @click:append="showPassword = !showPassword"
      />

      <div class="mt-9 text-center">
        <v-btn small :disabled="updating" @click="goBack">Retour</v-btn>
        <v-btn small class="ml-2" color="primary" :loading="updating" @click="submit">Enregistrer</v-btn>
      </div>
    </v-form>
  </v-container>
</template>

<script>
  import {rules} from '@/services/rules'
  import FormCategory from '@/modules/core/layout/FormCategory'
  import * as User from '@/modules/user/api'

  export default {
    name: 'UserConnect',
    components: {FormCategory},
    data() {
      return {
        rules,
        password: {
          confirm_new: '',
          current: '',
          new: ''
        },
        showOriginalPassword: false,
        showPassword: false,
        loading: false,
        updating: false,
        rulePasswordNotSame: v => v !== this.password.current || 'L\'ancien et le nouveau mot de passe doivent être différents',
        rulePasswordSameConfirm: v => v === this.password.new || 'Les mots de passe ne correspondent pas'
      }
    },
    methods: {
      goBack() {
        this.$emit('go-back')
      },
      async submit() {
        if (!this.$refs.form.validate()) {
          return
        }
        this.updating = true
        try {
          await User.updatePassword(this.password)
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'success',
            title: 'Mot de passe modifié',
            subtitle: 'Mot de passe modifié avec succès.'
          })
        }
        catch (e) {
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'error',
            title: 'Mot de passe incorrect',
            subtitle: 'Le mot de passe saisi est incorrect.'
          })
        }
        this.updating = false
      }
    }
  }
</script>
