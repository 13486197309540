/*
 * Copyright (C)  E-Synaps SAS - 2021 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
export const professionnalsRoutes = [
  {
    path: '/professional/:uid',
    component: () => import('./views/ProfessionalSection'),
    props: true,
    children: [
      {
        path: '',
        name: 'professional-profile',
        component: () => import('./components/ProfessionalFeed.vue'),
        props: true,
        meta: {
          mobiletitle: 'Fiche professionnel'
        }
      },
      {
        path: 'informations',
        name: 'professional-informations',
        component: () => import('./components/ProfessionalInformations.vue'),
        props: true,
        meta: {
          mobiletitle: 'Fiche professionnel'
        }
      }
    ]
  }
]

