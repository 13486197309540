/*
 * <!--Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential-->
 */

import messagesList from '@/modules/messenger/store/messages-list'
import messages from '@/modules/messenger/store/messages'
import layout from '@/modules/messenger/store/layout'
import { getUnreadTotal } from '@/modules/messenger/api/conversations'

export default {
  namespaced: true,
  state: {
    totalUnreadMessages: 0
  },
  mutations: {
    setTotalUnreadMessages(state, value) {
      state.totalUnreadMessages = value
    }
  },
  actions: {
    async fetchTotalUnreadMessages({commit}) {
      commit('setTotalUnreadMessages', (await getUnreadTotal()).data)
    }
  },
  modules: {
    messagesList,
    messages,
    layout
  }
}
