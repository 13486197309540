<!--
  - Copyright (C)  E-Synaps SAS - 2021 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-list two-line class="pa-4">
    <form-category title="Informations générales" subtitle="Informations générales du document" />
    <v-list-item>
      <v-list-item-icon>
        <font-awesome-icon :icon="['fal', 'file-signature']" size="lg" class="op-40" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ file.name }}</v-list-item-title>
        <v-list-item-subtitle>Nom du document</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon>
        <font-awesome-icon :icon="['fal', 'hdd']" size="lg" class="op-40" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ size }}</v-list-item-title>
        <v-list-item-subtitle>Taille</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon>
        <font-awesome-icon :icon="['fal', 'file-certificate']" size="lg" class="op-40" />
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>{{ file.extension }}</v-list-item-title>
        <v-list-item-subtitle>Format</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon>
        <font-awesome-icon :icon="['fal', 'calendar-check']" size="lg" class="op-40" />
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>{{ creationDate }}</v-list-item-title>
        <v-list-item-subtitle>Date de création</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon>
        <font-awesome-icon :icon="['fal', 'info-circle']" size="lg" class="op-40" />
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>{{ fileType ? fileType.name : 'Non renseigné' }}</v-list-item-title>
        <v-list-item-subtitle>Type</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <template v-if="hasSharingInformation">
      <v-divider inset class="mb-4" />
      <form-category title="Informations de partage" subtitle="Informations de partage du document" />
      <v-list-item v-if="file.patient">
        <v-list-item-icon>
          <font-awesome-icon :icon="['fal', 'file-user']" size="lg" class="op-40" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ file.patient.name }}</v-list-item-title>
          <v-list-item-subtitle>Propriétaire</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="sharedBy">
        <v-list-item-icon>
          <font-awesome-icon :icon="['fal', 'share']" size="lg" class="op-40" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ sharedBy.name }}</v-list-item-title>
          <v-list-item-subtitle>Partagé par</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-for="target in file.shared_to" :key="target.uid">
        <v-list-item-icon>
          <font-awesome-icon :icon="['fal', 'share-all']" size="lg" class="op-40" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ target.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-divider inset class="mb-4" />
    <form-category title="Informations complémentaires" subtitle="Autres informations liées au document" />
    <v-list-item>
      <v-list-item-icon>
        <font-awesome-icon :icon="['fal', 'tags']" size="lg" class="op-40" />
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-subtitle v-if="file.keywords">
          <template v-for="(tag, index) in file.keywords">
            <v-chip :key="index" small class="mr-1" v-text="tag" />
          </template>
        </v-list-item-subtitle>
        <v-list-item-title v-else class="font-italic">Pas de mot clés définis</v-list-item-title>
        <v-list-item-subtitle>Mots clés</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon>
        <font-awesome-icon :icon="['fal', 'comment-dots']" size="lg" class="op-40" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-if="file.description">{{ file.description }}</v-list-item-title>
        <v-list-item-title v-else class="font-italic">Pas de description définie</v-list-item-title>
        <v-list-item-subtitle>Description</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
  import FormCategory from '@/modules/core/layout/FormCategory'
  import {getSize} from '@/modules/files/helpers'
  import {fromNonRetardToRetardFormat} from '@/utils/date'
  import {empty} from '@/utils/variables'
  import { FileType, Person } from '@/models'

  export default {
    name: 'FileViewerDetails',
    components: {FormCategory},
    props: {
      file: {
        type: Object,
        required: true
      }
    },
    computed: {
      sharedBy() {
        let uid = this.file.author ? this.file.author : this.file.uploader
        return uid && uid !== this.$auth.user.sub ? Person.get(uid) : null
      },
      size() {
        return getSize(this.file.size)
      },
      creationDate() {
        return fromNonRetardToRetardFormat(this.file.creation_date)
      },
      hasSharingInformation() {
        return !empty(this.file.patient) || this.sharedBy || !empty(this.file.shared_to)
      },
      fileType() {
        return FileType.get(this.file.file_type)
      }
    }
  }
</script>
