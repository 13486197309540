/*
 * <!--Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential-->
 */
import * as messages from '@/modules/messenger/api/messages'
import Vue from 'vue'
import {sendMessage} from '@/modules/messenger/websockets'
import {column} from '@/utils/array'

export default {
  namespaced: true,

  state: {
    items: []
  },

  getters: {
    get: state => id => {
      return state.items.find(v => id === v.id) || null
    }
  },

  mutations: {
    add(state, message) {
      state.items.push(message)
    },

    update(state, message) {
      const index = state.items.find(v => v.id === message.id)

      if (-1 !== index) {
        Vue.set(state.items, index, message)
      }
    }
  },

  actions: {
    async post(context, {conversation, message, attachment}) {
      let {data} = await messages.create(conversation.uid, {message, attachment})
      sendMessage({
        conversation: {
          uid: conversation.uid,
          participants: column(conversation.participants, 'id')
        }, message: data
      })
      return data
    },

    /**
     *
     * @param Context
     * @param messages array of messages id
     */
    receiveMessage(Context, messages) {
      return messages.receiveMessage(messages)
    }
  }
}
