/*
 * Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

/**
 * @param date
 * @param format
 * datetime - return DD/MM/YYYY HH:mm
 * time - return HH:mm
 * anything else - return DD/MM/YYYY
 * @returns {string}
 */
export const fromNonRetardToRetardFormat = (date, format) => {
  format = format || 'DD/MM/YYYY'
  if('datetime' === format) {format = 'DD/MM/YYYY HH:mm'}
  if('time' === format) format = 'HH:mm'

  return window.dayjs(date).format(format)
}

/**
 * Return true is the date is today, false if not
 * @param date
 * @return boolean
 */
export const isToday = date => {
  const today = window.dayjs()
  date = window.dayjs(date)

  return today.date() === date.date() && today.month() === date.month() && today.year() === date.year()
}

