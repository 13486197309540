import {Person} from '../index'

export const ITEM_PROJECTIONS = ['professional_address', 'professional_job', 'professional_skills', 'professional_specialties']

export class Professional extends Person {
  static type = 'user:professional'

  static async fetchOne(uid) {
    return super.fetchOne(uid, ITEM_PROJECTIONS)
  }
}
