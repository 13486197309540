import {currentHost, currentURL} from './utils/uri'

const host = currentHost()

export default {
  env: process.env.ENV,
  host,
  reservedSubdomains: [
    '',
    'www',
    'app',
    process.env.VUE_APP_DOMAIN
  ],
  urls: {
    self: currentURL(),
    homepage: process.env.VUE_APP_URL_HOMEPAGE,
    auth: process.env.VUE_APP_URL_AUTH,
    api: process.env.VUE_APP_URL_API,
    node: process.env.VUE_APP_URL_NODE,
    backOffice: process.env.VUE_APP_URL_MSPACE_BACKOFFICE
  },
  oauth: {
    clientId: process.env.VUE_APP_OAUTH_CLIENT_ID,
    domain: process.env.VUE_APP_OAUTH_DOMAIN,
    issuer: process.env.VUE_APP_URL_AUTH,
    scopes: ['openid', 'email', 'profile', 'role_professionals'/*, 'role_patients'*/],
    loginHint: 'professional',
    postLogoutRedirectUri: host,
    logoutEndpoint: '/oidc/logout',
    accessDeniedEndpoint: '/security/user/access-denied'
  },
  googleMapsApiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY
}
