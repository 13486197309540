/*
 * Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import * as Patients from '@/modules/patients/api'
import Vue from 'vue'

export const _formatApiReturn = patients => {
  //convert from es return to something a bit more "standard" (still some camelCase instead of snake_case)
  return patients.map(patient => {
    return {...patient._source,
      uid: patient._id,
      full_name: patient._source.fullName,
      birth_date: patient._source.birthDate
    }
  })
}

export default {

  namespaced: true,

  state: {
    search: null,
    items: [],
    total: 0,
    page: 0
  },

  getters: {

    get: (state, getters) => async uid => {

      let patient = null

      if (getters.isLoaded) {
        patient = state.items.find(item => uid === item.uid)
      }

      if (!patient || !patient.first_name) {
        try {
          patient = (await Patients.find(uid)).data
        } catch {
          patient = null
        }
      }

      return patient
    },

    isLoaded(state) {
      return !!state.page && state.items.length >= state.total
    }

  },

  mutations: {

    reset(state) {
      state.items = []
      state.total = 0
      state.page = 0
    },

    setPatients(state, patients) {
      state.items = state.items.concat(patients)
    },

    setTotal(state, total) {
      state.total = total
    },

    setSearch(state, value) {
      state.search = value
    },

    replacePatient(state, payload) {
      let index = state.items.findIndex(item => item.uid === payload.uid)
      if (index >= 0) {
        Vue.set(state.items, index, payload)
      }
    },

    setPage(state, page) {
      state.page = page
    }
  },

  actions: {
    async fetchPatients({state, commit, getters}) {
      if (getters.isLoaded) {
        return
      }

      let page = state.page + 1

      await Patients.list({mypatients: true, search_term: state.search}, {page: page, limit:10, order: {'lastName': 'ASC'}}).then(response => {
        commit('setPatients', response.data.list)
        commit('setTotal', response.data.total)
        commit('setPage', page)
      })
    },

    async searchPatients(_, {filters, page, limit, order} = {}) {

      let response = await Patients.list(filters, {page, limit, order})

      return response.data.list

    },

    async insert(_, data = {}) {
      let [err, patient] = await Patients.create(data)
        .then((response => {
          return [null, response.data]
        }))
        .catch(error => {
          return [error.response, null]
        })

      return [err, patient]

    },

    async update({commit}, {uid, data = {}}) {

      let [err, patient] = await Patients.update(uid, data)
        .then((response => {
          commit('replacePatient', response.data)
          return [null, response.data]
        }))
        .catch(error => {
          return [error.response, null]
        })

      return [err, patient]

    }

  }

}
