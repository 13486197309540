/*
 * <!--Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential-->
 */

export const formatParticipantsName = participants => {
  if(!participants.length) return null

  let names = participants.map(val => val.full_name)

  let text = names.slice(0, 3).join(', ')

  if (participants.length > 3) {
    text += ', ' + (participants.length - 3) + ' autres personnes'
  }

  return text
}
