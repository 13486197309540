import api from '@/api/api'

export function listPending(teamUid) {
  return api.get(`/v3/teams/${teamUid}/invitations`, {
    cache: {
      ignoreCache: true
    }
  })
}

export function inviteList(teamUid, invitedUidList) {
  return api.post(`/v3/teams/${teamUid}/invitations`, {
    users: invitedUidList
  })
}

export function listInvitable(teamUid, projections) {
  return api.get(`/v3/teams/${teamUid}/invitable`, {
    params: {
      projections
    },
    cache: {
      ignoreCache: true
    }
  })
}

export * from '@/modules/invitations/api'
