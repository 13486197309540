import api from '@/api/api'

export function accept(invitationUid) {
  return api.post('/v3/invitations/' + invitationUid + '/accept')
}

export function decline(invitationUid) {
  return api.post('/v3/invitations/' + invitationUid + '/decline')
}

export function cancel(invitationUid) {
  return api.post('/v3/invitations/' + invitationUid + '/cancel')
}

export function acceptLink(token) {
  return api.post('/v3/invitations/links/' + token+'/accept')
}

export function declineLink(token) {
  return api.post('/v3/invitations/links/' + token+'/decline')
}

export function getLink(token) {
  return api.get(`/v3/invitations/links/${token}`)
}

