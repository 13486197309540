import api from '@/api/api'

export function list(spaceUid, page, limit = 10) {
  return api.get('/v3/spaces/' + spaceUid + '/members', {
    params: {
      page,
      limit
    }
  })
}

export function add(spaceUid, userUid) {
  return api.post(`/v3/spaces/${spaceUid}/members/${userUid}`)
}

/**
 * @param spaceUid
 * @param userUid
 * @param elevation "member" or "manager"
 */
export function updateElevation(spaceUid, userUid, elevation) {
  return api.put(`/v3/spaces/${spaceUid}/members/${userUid}/elevation`, {
    elevation
  })
}

export function transferOwnership(spaceUid, userUid) {
  return api.post(`/v3/spaces/${spaceUid}/members/${userUid}/transfer-ownership`)
}

export function remove(spaceUid, userUid) {
  return api.delete(`/v3/spaces/${spaceUid}/members/${userUid}`)
}
