/*
 * Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import Vue from 'vue'
const defaultState = {
  darkMode: '0',
  displayIntro: '1'
}
let storageState = JSON.parse(localStorage.getItem('settings:application'))


export default {
  namespaced: true,
  state: !storageState ? defaultState : storageState,
  mutations: {
    update(state, obj) {
      Vue.set(state, obj.param, obj.value)
      try {
        localStorage.setItem('settings:application', JSON.stringify(state))
      } catch (e) {
        console.log('Error while saving application settings')
      }
    }
  }
}
