<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-dialog :value="spaceSelector" fullscreen transition="fade-transition">
    <space-selector @close="closeSpaceSelector">
      <template #prepend-right>
        <div class="space-selector-actions">
          <v-btn v-if="$store.state.core.space" depressed fab :small="$vuetify.breakpoint.lgAndUp" x-small
                 class="white elevation-1 mr-2" @click="closeSpaceSelector"
          >
            <v-icon class="primary--text">$close</v-icon>
          </v-btn>

          <v-btn fab :small="$vuetify.breakpoint.lgAndUp" x-small class="white elevation-1 p-rel" @click="logout">
            <font-awesome-icon :icon="['fal', 'sign-out']" size="lg" class="red--text text--darken-1" />
          </v-btn>
        </div>
      </template>
    </space-selector>
  </v-dialog>
</template>

<script>
  import {mapState} from 'vuex'
  import SpaceSelector from '@/modules/spaces/views/SpaceSelector'
  export default {
    name: 'SpaceSelectorDialog',
    components: {SpaceSelector},
    computed: {
      ...mapState('layout', ['spaceSelector'])
    },
    methods: {
      closeSpaceSelector() {
        this.$store.commit('layout/toggleSpaceSelector')
      },
      logout() {
        this.$auth.logout()
      }
    }
  }
</script>
