import api from '@/api/api'

export function getMe(projections = null) {
  return api.get('/v3/users/me', {
    params: projections
  })
}

export function get(uid, projections = null) {
  return api.get(`/v3/users/${uid}`, {
    params: {
      projections
    }
  })
}

// export function updatePassword(data) {
//   return api.post('/v2/users/me/set-password', {plain_password: data})
// }

export function updatePassword(data) {
  return api.put('/v3/users/me/password', data)
}

export function update(data) {
  return api.put('/v2/users/me', {user_data: data})
}

export function getFeedList(spaceUid, userUid) {
  return api.get(`/v3/spaces/${spaceUid}/users/${userUid}/feed-list`)
}

export function search(page = 1, limit = 10, filters, order, projections) {
  return api.post('/v3/users/search', {
    page,
    limit,
    filters,
    order,
    projections
  })
}

